<template>
  <div class="is-mb-3">
    <button
      type="button"
      class="button is-rounded is-mb-3"
      @click.prevent="onClick"
    >
      テンプレートを読み込む
    </button>
    <BInput
      :id="id"
      v-model="inputValue"
      :name="name"
      type="textarea"
      rows="24"
    />
  </div>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    template: {
      type: String,
      required: true
    }
  },
  data () {
    return { inputValue: this.value }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    }
  },
  methods: {
    onClick () {
      this.inputValue = this.template
    }
  }
}
</script>

<style scoped lang="sass">
</style>
