<template>
  <div class="header">
    <div class="header-row">
      <div class="writer">
        <a :href="writerShowPath">
          <FaceIcon
            class="is-small"
            :src="avatarPath"
          />
        </a>
        <div
          v-if="hasTitle"
          class="writer-description has-title"
        > 
          <a
            :href="writerShowPath"
            v-text="fullName"
          />
          <strong v-text="title" />
          <DateTimeOrTime :value="createdAt" />
        </div>
        <div
          v-else
          class="writer-description"
        >
          <a :href="writerShowPath">
            <strong v-text="fullName" />
          </a>
          <DateTimeOrTime :value="createdAt" />
        </div>
        <FriendGauge
          v-if="showFriendshipLevel"
          :friend-level="friendshipLevel"
          class="is-small"
        />
      </div>
      <BaseOptionButton>
        <slot />
      </BaseOptionButton>
    </div>
    <div
      v-if="hasLabel"
      class="header-row"
    >
      <span
        class="tag label"
        :class="{ 'is-emergency': isEmergencyLabel }"
        v-text="label.text"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { writerShowPath } from 'lib/paths'
import DateTimeOrTime from 'components/DateTimeOrTime'

const EMERGENCY_LABLES = ['suspicious', 'disaster']

export default {
  components: { DateTimeOrTime },
  props: {
    id: {
      type: Number,
      default: null
    },
    writerType: {
      type: String,
      required: true
    },
    avatarPath: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    useNickname: {
      type: Boolean,
      required: true
    },
    createdAt: {
      type: String,
      required: true
    },
    friendshipLevel: {
      type: Number,
      default: null
    },
    label: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    showFriendshipLevel () {
      if (!this.friendshipLevel) return false
      if (this.currentUser.id === this.id) return false
      if (this.useNickname) return false

      return true
    },
    isEmergencyLabel () {
      return EMERGENCY_LABLES.indexOf(this.label.value) !== -1
    },
    writerShowPath () {
      // eslint-disable-next-line no-script-url
      if (this.useNickname) return 'javascript:void(0)'

      return writerShowPath({ type: this.writerType, id: this.id })
    },
    hasLabel () {
      return this.label && this.label.value
    },
    hasTitle () {
      return this.title
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.header-row
  display: flex
  justify-content: space-between
  margin-top: 4px
  &:first-child
    margin-top: 0
  .writer
    display: flex
    align-items: center
    .face-icon
      min-width: 37px
    .writer-description
      margin-left: 8px
      > *
        display: block
      time
        font-size: 10px
    .has-title
      a
        font-size: 0.75rem
    .friend-gauge
      margin-left: 8px
      min-width: 90px
  .label
    background-color: #ffecca
    color: #3a1010
    font-weight: 400
    &.is-emergency
      background-color: #af0000
      color: #fff
</style>
