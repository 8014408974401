<template>
  <a
    class="organization-card"
    :href="showPath"
  >
    <div class="box is-mb-3">
      <div class="media">
        <div class="media-left">
          <img
            :src="imagePath"
            class="media__thumbnail"
          >
        </div>
        <div class="media-content">
          <div
            class="name"
            v-text="name"
          />
          <div
            v-if="recentCount > 0"
            class="recent-count"
          >
            この1週間で<span v-text="recentCount" />件の新着
          </div>
          <div
            class="address"
            v-text="address"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            class="content description"
            v-html="description"
          />
        </div>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    recentCount: {
      type: Number,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    showPath: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.organization-card
  .box
    padding: 10px
    color: #333
    .name
      font-size: 18px
    .recent-count
      font-size: 12px
      color: #d0021b
    .address
      font-size: 10px
      color: #666
    .description
      font-size: 11px
</style>
