<template>
  <div>
    <BaseRadioInput
      v-bind="inputProps"
      :on-change="onChange"
      class="is-mb-0"
    />
    <BMessage
      v-if="alertMessage"
      type="is-warning"
      class="is-my-2"
    >
      <p v-text="alertMessage" />
    </BMessage>
  </div>
</template>

<script>
const ALERT_MESSAGES = {
  open: '招待制からオープンに変更すると、これまで住まいコミュニティ内で行われていた掲示板のやり取りなどを、参加者以外も閲覧できるようになります。',
  invitation: 'オープンから招待制に変更すると、住まいコミュニティ内のやり取りを、参加者でないユーザーが閲覧できなくなります。'
}

export default {
  props: {
    inputProps: {
      type: Object,
      required: true
    },
    showAlertOnChange: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isChanged: false,
      value: this.inputProps.value
    }
  },
  computed: {
    alertMessage () {
      if (this.showAlertOnChange && this.isChanged) {
        return ALERT_MESSAGES[this.value]
      }
      return null
    }
  },
  methods: {
    onChange (value) {
      this.isChanged = true
      this.value = value
    }
  }
}
</script>

<style scoped lang="sass">
</style>
