<template>
  <div>
    <slot
      :onChangeAddress="onChangeAddress"
      :lat="lat"
      :lng="lng"
    />
  </div>
</template>

<script>
import { fullAddress2Latlng } from 'lib/auto_input_latlng'

export default {
  props: {
    initialLat: {
      type: String,
      default: ''
    },
    initialLng: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lat: this.initialLat,
      lng: this.initialLng
    }
  },
  methods: {
    async onChangeAddress (e) {
      const address = e.target.value
      const latlng = await fullAddress2Latlng(address)
      if (latlng) {
        this.lat = latlng.lat()
        this.lng = latlng.lng()
      }
    }
  }
}
</script>
