<template>
  <BasePanelBlock>
    <FaceIcon
      :src="logoImageUrl"
      class="user-icon"
    />
    <a
      href="#"
      class="panel-block-content"
      @click.prevent="onClick"
      v-text="fullName"
    />
    <a
      class="button is-primary is-rounded"
      :href="url"
    >
      企業HPへのリンク
    </a>
    <Portal to="modal">
      <BModal :active.sync="isModalActive">
        <CorporateUserModalContent v-bind="$props" />
      </BModal>
    </Portal>
  </BasePanelBlock>
</template>

<script>
import CorporateUserModalContent from 'components/CorporateUserModalContent'
import FaceIcon from 'components/FaceIcon.vue'

export default {
  components: { CorporateUserModalContent, FaceIcon },
  props: {
    fullName: {
      type: String,
      required: true
    },
    coverStyle: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    },
    introductionTitle: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    },
    logoImageUrl: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  methods: {
    onClick () {
      this.isModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
</style>
