<template>
  <div
    :class="cardClass"
  >
    <div class="searchresultzero-inner">
      <slot name="h2" />
      <slot name="h3" />
      <slot name="contents" />
      <div class="section-button">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
const BACKGROUND_IMAGE_COUNT = 4

export default {
  props: {
    backgroundRandom: {
      type: Boolean,
      default: true
    },
    backgroundSetting: {
      type: Number,
      default: 1
    }
  },
  computed: {
    cardClass () {
      return `searchresultzero is-bg${this.backgroundImageType}`
    },
    backgroundImageType () {
      return this.backgroundRandom
        ? Math.floor(Math.random() * BACKGROUND_IMAGE_COUNT) + 1
        : this.backgroundSetting
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

// 背景画像の高さ
$bgImageHeight: 500px

.searchresultzero
  width: 100%
  min-height: $bgImageHeight
  background-size: cover
  background-position: bottom center
  background-repeat: no-repeat
  &.is-bg1
    background-image: url(../images/search-result-zero/bg-cyan.svg)
  &.is-bg2
    background-image: url(../images/search-result-zero/bg-green.svg)
  &.is-bg3
    background-image: url(../images/search-result-zero/bg-orange.svg)
  &.is-bg4
    background-image: url(../images/search-result-zero/bg-purple.svg)
  +app-mobile
    padding: 60px 10px
    min-height: 100vw
  .searchresultzero-inner
    width: 100%
    max-width: 600px
    margin-left: auto
    margin-right: auto
    padding: 86px 34px
    +app-mobile
      padding: 60px 10px
  h2
    font-size: 26px
    line-height: 1.4
    font-weight: bold
    text-align: center
    margin-bottom: 20px
    +app-mobile
      font-size: 18px
  h3
    font-size: 18px
    line-height: 1.4
    font-weight: bold
    text-align: center
    margin-bottom: 20px
    +app-mobile
      font-size: 16px
  p
    font-size: 14px
    line-height: 1.4
    margin-bottom: 20px
  .section-button
    text-align: center
    >*:first-child
      margin-top: 30px
</style>
