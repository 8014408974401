<template>
  <div :class="{'is-single-column': !showSideMenu}">
    <header class="app-header">
      <div class="inner">
        <div class="logo">
          <a href="/home">
            <div  v-if="isMyCommuDomain" class="my-commu">
              <img
                src="../images/header-logo-mycommu.png"
                alt="マイコミュ"
              />
            </div>
            <div  v-else>
              <img
                src="../images/header-logo-asmama.jpg"
                alt="AsMama"
              />
            </div>
          </a>
        </div>

        <div
          v-if="showSideMenu"
          class="header-lefticons"
        >
          <a
            v-show="visibleBackNav"
            class="back-nav"
            @click="goBack"
          >
            <i class="fal fa-chevron-left" />
          </a>
          <a
            class="menu-nav"
            @click="isNavOpen=true"
          >
            <i class="fal fa-bars" />
          </a>
        </div>

        <div class="header-title">
          <a href="/home">
            <div  v-if="isMyCommuDomain">
              <img
                class="header-logo-mobile my-commu"
                src="../images/header-logo-mycommu.png"
                alt="マイコミュ"
              />
            </div>
            <div  v-else>
              <img
                class="header-logo-mobile"
                src="../images/header-logo-asmama.jpg"
                alt="AsMama"
              />
            </div>
          </a>
        </div>
      </div>
    </header>
    <div class="main-container">
      <main class="app-main">
        <slot name="main" />
      </main>

      <div
        v-if="showSideMenu"
        :class="{'is-nav-open': isNavOpen}"
        class="side-nav"
      >
        <div class="inner">
          <div class="side-nav-header">
            <div
              class="side-back"
              @click="isNavOpen=false"
            >
              <i class="icon fal fa-times" />
              <span v-text="navPaths.close.label" />
            </div>
            <div class="profile">
              <div class="face-icon">
                <a :href="navPaths.profile.path">
                  <img
                    :src="navPaths.profile.avatarPath"
                    alt
                  >
                </a>
              </div>
              <div class="profile-name">
                <a :href="navPaths.profile.path">
                  <span
                    class="full-name"
                    v-text="navPaths.profile.fullName"
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="side-nav-scroll-area">
            <div class="side-nav-main">
              <ul>
                <li v-if="navPaths.organizations.show">
                  <a
                    :href="navPaths.organizations.path"
                    v-text="navPaths.organizations.label"
                  />
                </li>
                <li v-if="navPaths.organizations.show">
                  <a
                    :href="navPaths.corporateStaffParticipations.path"
                    v-text="navPaths.corporateStaffParticipations.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.communities.path"
                    v-text="navPaths.communities.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.events.path"
                    v-text="navPaths.events.label"
                  />
                </li>
                <li v-if="navPaths.ads">
                  <a
                    :href="navPaths.ads.path"
                    v-text="navPaths.ads.label"
                  />
                </li>
              </ul>
            </div>
            <div class="side-login">
              <a
                :href="navPaths.signOut.path"
                data-method="delete"
                v-text="navPaths.signOut.label"
              />
            </div>

            <div class="copyright">
              Copyright &copy; AsMama,Inc. All Rights reserved.
            </div>
          </div>
        </div>
      </div>
      <Transition name="side-bgfade">
        <div
          v-if="isNavOpen"
          class="side-nav-bg"
          @click="isNavOpen=false"
        />
      </Transition>
    </div>
    <footer class="app-footer" />
  </div>
</template>

<script>
export default {
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    showSideMenu: {
      type: Boolean,
      default: true
    },
    useBackNav: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isNavOpen: false
    }
  },
  computed: {
    visibleBackNav () {
      return this.useBackNav && window.history.length > 1
    }
  },
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'

  .face-icon img
    border-radius: 50%
    width: 50px
    height: 50px

  .app-header
    width: 100%
    border-bottom: 1px solid #eee
    > .inner
      width: 740px
      height: $app-desktop-header-height - 1px
      margin: auto
      display: flex
      justify-content: space-between
      align-items: center
      a
        text-decoration: none
        display: block
      .main-nav
        display: none
        flex-grow: 1
        > ul
          justify-content: flex-start
          li
            a
              padding: 10px 20px
            &.is-current a
              color: #ec6b00
      .logo
        width: 240px
        img
          width: 100px
          vertical-align: middle
        .my-commu
          img
            width: 140px
            vertical-align: middle          
      .icon
        font-size: 22px
      .menu-nav, .back-nav, .user, .header-title
        display: none
  +app-mobile
    .app-header
      position: fixed
      top: 0
      left: 0
      z-index: 19
      background: #fff
      box-shadow: 0 1px 1px #efefef
      border-bottom: 1px solid #e4e4e4

  +app-mobile
    .app-header > .inner .main-nav > ul li a
      padding: 10px 0px

  +app-mobile
    .app-header > .inner .icon
      font-size: 20px
      text-align: center

  +app-mobile
    // FIXME: for iOS TEST
    html, body
      -webkit-overflow-scrolling : touch !important
      overflow: auto !important
      height: 100% !important
    .app-header > .inner
      width: 100vw
      padding: 0
      height: $app-mobile-header-height - 1px
      position: relative
      .header-lefticons
        display: flex
        align-items: center
        z-index: 2
        padding-left: 6px
      .menu-nav, .back-nav, .user, .header-title
        display: block
      .menu-nav
        font-size: 24px
        position: relative
        padding-left: 10px
        padding-right: 10px
        color: $color-headericon
      .back-nav
        font-size: 25px
        position: relative
        padding-left: 15px
        padding-right: 15px
        color: $color-headericon
      .header-title
        text-align: center
        font-size: 18px
        font-weight: 600
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 1
        .header-logo-mobile
          width: 20vw
          max-height: $app-mobile-header-height - 5px
          vertical-align: middle
        .header-logo-mobile.my-commu
          width: 35vw
          max-width: 144px
      .logo
        display: none

  +app-mobile
    .app-header
      > .inner
        .main-nav
          display: block
    .main-nav
      position: fixed
      bottom: 0
      left: 0
      width: 100vw
      background-color: #fff
      height: 50px
      border-top: 1px solid #eee
      z-index: 10
      padding: 0 0px
      ul
        display: flex
        justify-content: space-between !important
        align-items: stretch !important
        height: 100%
      li
        margin: 0 !important
        flex-grow: 1
        flex-basis: 1px
        a
          height: 100%
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          line-height: 1
          span
            margin-top: 3px
            font-size: 11px !important
      .user
        .face-icon img
          height: 36px
          width: 36px
        .nav-label
          display: none

  .side-nav
    width: 200px
    .side-back
      display: none
    .profile
      margin: 16px 0
      display: flex
      justify-content: space-between
      +app-mobile
        margin-top: 0
      .face-icon
        width: 50px
    .profile-name
      flex-grow: 1
      width: calc(100% - 50px)
      padding-left: 8px
      display: flex
      flex-direction: column
      justify-content: center
      .full-name
        margin-bottom: 3px
        display: block
        font-size: 16px
        font-weight: 600
      .full-name-kana
        display: block
        font-size: 9px
        margin-top: 3px
        font-weight: normal
    .side-nav-main
      ul
      li a
        color: #333
        font-weight: 600

        display: block
        padding: 4px 0
        font-size: 14px
        cursor: pointer
        transition: 0.2s
        &:hover
          background-color: #f9f9f9
          text-decoration: none
          padding-left: 5px
    .side-nav-sub
      margin-top: 10px
      li a
        color: #333
        padding: 4px 0
        font-size: 12px
      &.side-nav-banner
        border-top: 1px solid #eee
        border-bottom: 1px solid #eee
        padding: 10px 0
        li a
          color: $primary
          line-height: 1.3
          display: block
    .side-login
      margin: 10px 0
      a
        font-size: 12px
        color: #333
        font-weight: 600
    .copyright
      margin-top: 10px
      font-size: 9px
      color: #9b9b9b
      padding-bottom: 100px

  +app-mobile
    .side-nav
      width: auto
      z-index: 110
      .inner
        width: 80vw
        height: 100vh
        padding: 0 20px 20px
        z-index: 110
        background-color: #fff
        box-shadow: 2px 0 10px #333
        position: fixed
        left: 0
        top: 0
        transform: translateX(-100vw)
        transition: 0.4s
        display: flex
        flex-direction: column
      .side-nav-scroll-area
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
      &.is-nav-open
        .inner
          transition: 0.4s 0.2s
          transform: translateX(0)
      .side-back
        display: inline-flex
        align-items: center
        justify-content: flex-start
        padding: 20px 0 15px
        .icon
          display: inline-block
          font-size: 24px
        span
          display: inline-block
          font-size: 14px
          margin-left: 5px
          line-height: 14px

  .side-nav-bg
    content: ""
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.6)
    position: fixed
    top: 0
    left: 0
    z-index: 105
    display: none

  +app-mobile
    .side-nav-bg
      display: block

  .side-bgfade-enter
    opacity: 0
    transition: 0.1s

  .side-bgfade-enter-to
    opacity: 1
    transition: 0.2s

  .side-bgfade-leave
    opacity: 1

  .side-bgfade-leave-to
    transition: 0.2s 0.4s
    opacity: 0

  .main-container
    width: 740px
    margin: auto
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-direction: row-reverse
    padding-bottom: 4rem

  +app-mobile
    .main-container
      width: 100vw
      margin-top: $app-mobile-header-height
      display: block
      padding-bottom: 50px

  .app-main
    width: 500px

    +app-mobile
      width: auto

  .is-single-column
    .main-container
      width: auto

  .is-single-column
    .main-container
      width: 100vw
    .app-main
      width: 500px
      margin: auto

  +app-mobile
    .is-single-column
      .app-main
        width: auto
</style>
