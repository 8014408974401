<template>
  <div
    class="photo-uploader-outer use-flex"
    :class="`columns-${flexItems}`"
  >
    <PhotoUploader
      v-for="(image, idx) in images"
      :key="image.name"
      v-bind="image"
      display-width="100%"
      display-height="100%"
      :is-no-label="true"
      :class="photoUploaderClass(idx)"
      @change="onPhotoFileChanged"
    >
      <BaseCheckboxInput
        v-if="isVisibleRemoveCheckbox(idx)"
        v-bind="removes[idx]"
        :use-background="true"
      />
    </PhotoUploader>
    <slot />
  </div>
</template>

<script>

export default {
  props: {
    flexItems: {
      type: Number,
      default: 3 // 2-4
    },
    images: {
      type: Array,
      required: true
    },
    removes: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      imageValues: this.images.map(({ name, value }) => ({ name, value }))
    }
  },
  computed: {
    isVisibleRemoveCheckbox () {
      return index => !!this.images[index].value
    },
    isVisiblePhotoUploader () {
      return index => index === 0 || !!this.imageValues[index - 1].value
    },
    photoUploaderClass () {
      return index => ({
        'is-hidden': !this.isVisiblePhotoUploader(index)
      })
    }
  },
  methods: {
    onPhotoFileChanged (name, value) {
      for (let i = 0; i < this.imageValues.length; ++i) {
        if (this.imageValues[i].name === name) {
          this.imageValues.splice(i, 1, { name, value })
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.photo-uploader-outer
  margin-bottom: 2rem
  > .field
    margin-bottom: 0
  &.use-flex
    display: flex
    flex-wrap: wrap
    justify-self: flex-start
    max-width: 500px
    width: 100%
    &.columns-0
      /deep/ > .photo-uploader
        margin-right: 1rem
        margin-bottom: 2rem
    &.columns-2,
    &.columns-3,
    &.columns-4
      /deep/ > .photo-uploader
        margin-right: 5%
        height: auto
        position: relative
        margin-bottom: 2.5rem
        &::before
          content: ""
          display: block
          padding-top: 100%
        > .field
          display: block
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
    &.columns-2
      /deep/ > .photo-uploader
        width: 47.5% // 100% - 5% / 2
      /deep/ > .photo-uploader:nth-child(2n)
        margin-right: 0
    &.columns-3
      /deep/ > .photo-uploader
        width: 30% // 100% - (5% * 2) / 3
      /deep/ > .photo-uploader:nth-child(3n)
        margin-right: 0
    &.columns-4
      /deep/ > .photo-uploader
        width: 21.25% // 100% - (5% * 3) / 4
      /deep/ > .photo-uploader:nth-child(4n)
        margin-right: 0
</style>
