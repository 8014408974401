<template>
  <span>
    <span
      class="link"
      @click="onClick"
    >
      <slot />
    </span>
    <BModal
      :active.sync="isModalActive"
      has-modal-card
    >
      <ShareFriendCardModal
        :level-by-me="levelByMe"
        :level-by-friend="levelByFriend"
        :user="user"
      />
    </BModal>
  </span>
</template>

<script>
import ShareFriendCardModal from 'components/ShareFriendCardModal'

export default {
  components: { ShareFriendCardModal },
  props: {
    levelByMe: {
      type: Number,
      required: true
    },
    levelByFriend: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  methods: {
    onClick () {
      this.isModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
span.link
  cursor: pointer
</style>
