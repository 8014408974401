<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
  >
    <div class="picker-container">
      <div
        class="picker-text"
        @click="edit"
      >
        <div class="date-container">
          <div
            class="year"
            v-text="formattedDate.year"
          />
          <div class="unit">
            年
          </div>
          <div
            class="month"
            v-text="formattedDate.month"
          />
          <div class="unit">
            月
          </div>
          <div
            class="date"
            v-text="formattedDate.date"
          />
          <div class="unit">
            日
          </div>
          <div
            class="day"
            v-text="formattedDate.day"
          />
        </div>
      </div>

      <Transition>
        <div
          v-if="isEdit"
          class="calendar-container"
        >
          <div class="date-result">
            日時を指定しましょう
          </div>
          <Calendar
            v-model="selectedDate"
            :is-show-selected-date="true"
          />
          <button
            class="ok-button"
            type="button"
            @click="setDate"
          >
            決定
          </button>
          <div
            class="close-button"
            @click="close"
          >
            <i class="fas fa-times iconclose" />
          </div>
        </div>
      </Transition>
      <input
        :value="inputValue"
        :name="name"
        type="hidden"
      >
    </div>
  </BField>
</template>

<script>
import { format, parse } from 'date-fns'
import Calendar from 'components/Calendar.vue'
import * as inputs from './inputs'

export default {
  components: { Calendar },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      date: parse(this.value),
      selectedDate: parse(this.value),
      isEdit: false
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    inputValue () {
      return format(this.date, 'YYYY-MM-DD')
    },
    formattedDate () {
      return {
        year: this.date.getFullYear(),
        month: this.date.getMonth() + 1,
        date: this.date.getDate(),
        day: this.weekName[this.date.getDay()]
      }
    },
    weekName () {
      return ['日', '月', '火', '水', '木', '金', '土']
    }
  },
  methods: {
    edit () {
      this.isEdit = true
    },
    close () {
      this.isEdit = false
    },
    setDate () {
      this.date = this.selectedDate
      this.close()
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../stylesheets/bulma-variables'

.v-enter-active, .v-leave-active
  transition: opacity 0.3s

.v-enter, .v-leave-to
  opacity: 0

.close-button
  position: absolute
  right: 20px
  top: 20px
  z-index: 41
  width: 40px
  height: 40px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer

  &:hover
    background-color: rgba(#ccc, 0.5)

  > .iconclose
    font-size: 16px

.ok-button
  margin-top: 20px
  appearance: none
  background-color: #fff
  border: 2px solid $primary
  display: inline-block
  color: $primary
  font-weight: bold
  border-radius: 1.4em
  font-size: 14px
  padding: 0.2em 5em
  cursor: pointer

.picker-container
  display: inline-block

  > .picker-text
    display: inline-flex
    font-family: Avenir, sans-serif
    background-color: rgba($primary, 0.01)
    padding: 0
    cursor: pointer

    &:hover
      opacity: 0.8

    &:active
      opacity: 0.5
      background-color: #eee

    > .iconcalendar
      font-size: 12px
      color: #999
      padding: 5px 10px

    > *
      display: flex
      justify-content: center
      align-items: center
      font-weight: 500
      font-size: 20px
      color: $primary
      line-height: 1

      > .day
        font-size: 70%

        &::before, &::after
          font-size: 80%
          position: relative
          top: -2px

        &::before
          content: '('
          margin-right: 1px

        &::after
          content: ')'
          margin-left: 1px

      > .year
        font-size: 80%
        // margin-left: 10px

      &.time-container
        margin-left: 10px
        margin-top: 4px
        margin-bottom: 3px
        margin-right: 10px

        > .unit
          position: relative
          //top: -3px

.unit
  font-size: 60%
  font-weight: normal
  margin: 0 2px

.calendar-container
  position: fixed
  background-color: rgba(#fff, 0.8)
  z-index: 40
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  font-size: 12px

.date-result
  font-size: 1.1rem
  margin-bottom: 0.4rem

.time-selector
  display: flex
  align-items: center
  justify-content: center
  margin-top: 15px
  vertical-align: bottom
  border-bottom: 1px solid #ccc
  padding: 10px 40px
  line-height: 1

  > select
    appearance: none
    line-height: 1
    display: block
    font-size: 18px
    background-color: transparent
    border: none
    text-align: right
    padding: 0
    font-family: Avenir, sans-serif

  > .unit
    margin-right: 1rem
    font-size: 120%
    margin-top: 1px

    &:last-child
      margin-right: 0

  .iconclock
    font-size: 120%
    margin-right: 1em
</style>
