<template>
  <div class="horizontal-scrollable">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.horizontal-scrollable
  width: 100%
  overflow-x: auto
</style>
