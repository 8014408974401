<template>
  <div
    class="sign-up-status-notice"
    :class="positionClass"
  >
    <BTooltip
      label="プロフィールの登録状況を確認しよう！"
      position="is-bottom"
      always
      :active="active"
    >
      <a
        href="/profiles/sign_up_status"
        @click="handleClick"
      >
        <span class="sign-up-status-notice__link-icon" />
      </a>
    </BTooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

function openKey (user) {
  return `kosodate-share__signUpStatusNoticeOpened_${user.id}`
}

function openNotice (user) {
  window.localStorage.setItem(openKey(user), Date.now())
}

function isNoticeOpened (user) {
  return !!window.localStorage.getItem(openKey(user))
}

export default {
  props: {
    position: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    positionClass () {
      return {
        'sign-up-status-notice--left': this.position === 'left',
        'sign-up-status-notice--right': this.position === 'right'
      }
    }
  },
  created () {
    this.active = !isNoticeOpened(this.currentUser)
  },
  methods: {
    handleClick () {
      openNotice(this.currentUser)
      return true
    }
  }
}
</script>

<style scoped lang="sass">
.sign-up-status-notice
  /deep/ .b-tooltip.is-bottom
    &::after
      transform: none
.sign-up-status-notice--left
  /deep/ .b-tooltip.is-bottom
    &::after
      left: -10px
.sign-up-status-notice--right
  /deep/ .b-tooltip.is-bottom
    &::after
      left: auto
      right: -10px

.sign-up-status-notice__link-icon
  display: block
  width: 1.2rem
  height: 1.2rem
  background-image: url('../images/wakabamark.png')
  background-size: contain
  background-repeat: no-repeat
  background-position: center
</style>
