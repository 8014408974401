<template>
  <div class="face-icon">
    <img
      :src="src"
      class="icon"
    >
    <p class="name">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.face-icon
  display: inline-block
  text-align: center

  > .icon
    background-color: #fff
    border-radius: 50%
    border: 1px solid transparent
    height: $size-face-icon-regular
    width: $size-face-icon-regular

  &.is-large
    > .icon
      height: 5.5rem
      width: 5.5rem

  &.is-small
    > .icon
      height: 2.3rem
      width: 2.3rem

  &.is-x-small
    > .icon
      height: 1.6rem
      width: 1.6rem

  &.is-border-white
    > .icon
      border-color: #fff
</style>
