<template>
  <div>
    <slot
      :onChangeZipCode="onChangeZipCode"
      :onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import autoInputAddress from 'lib/auto_input_address'
import autoInputLatlng from 'lib/auto_input_latlng'

export default {
  methods: {
    onChangeZipCode (e) {
      const result = autoInputAddress(e.currentTarget)
      return result
    },
    async onSubmit () {
      await autoInputLatlng()
      this.$el.querySelector('form').submit()
    }
  }
}
</script>

<style scoped lang="sass">
</style>
