<template>
  <div class="label-section">
    <div>
      <div :class="labelClass">
        <slot name="label">
          <span v-text="label" />
        </slot>
      </div>
      <div class="section-value">
        <slot name="value">
          <span v-text="value" />
        </slot>
      </div>
      <div
        v-show="hint"
        class="section-hint"
      >
        <slot name="hint">
          <span v-text="hint" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    hint: {
      type: String,
      default: ''
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isProtected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    labelClass () {
      let result = 'section-label '
      result += this.isHidden ? ' hidden-label ' : ''
      result += this.isProtected ? ' protected-label ' : ''
      return result
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
/deep/
  @import '~stylesheets/components/published-label'

.label-section
  display: flex
  flex-direction: column
  margin-bottom: 1rem

  .section-label
    font-size: 1rem
    color: #9b9b9b
    font-weight: 600
    margin-bottom: 3px
    display: flex
    align-items: center

  .section-value
    font-size: 1.2rem
    font-weight: 400
    overflow-wrap: break-word
    table
      font-size: 14px

  .section-hint
    font-size: 1rem
    font-weight: 400
</style>
