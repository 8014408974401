<template>
  <div class="topic-card">
    <TopicHeader v-bind="topicHeaderProps" v-if="viewMode">
      <p v-if="canUpdateTopic" class="dropdown-item item-hover" @click="toggleViewEdit()">編集</p>
      <slot name="option-menu" />
    </TopicHeader>
    <div class="main-content" v-if="viewMode">
      <div v-if="hasImages" class="main-image">
        <image-modal
          :src="mainImage.url"
          :original-src="mainImage.original"
        />
        <div class="sub-image-container">
          <div
            v-for="(img, idx) in subImages"
            :key="idx"
            class="sub-image"
          >
            <image-modal
              :src="img.small"
              :original-src="img.original"
            />
          </div>
        </div>
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div>
        <div
          v-if="displayFullPost"
          class="body"
          v-html="body"
        />
        <div
          v-else
          class="body"
          v-html="shortBody"
        />
        <div
          v-if="textTooLong && !displayFullPost"
          class="read-more"
          @click="toggleDisplayFullPost"
        >
          <span>
            続きを読む
          </span>
        </div>
        <div
          v-else
          class="blank-space"
        >
        </div>
      </div>
    </div>
    <div v-else>
      <TopicForm
        v-bind="editTopicFormProps"
        :inSwiper="inSwiper"
      />
    </div>
    <div class="like-and-comment">
      <ReactionTopic
        :likes="likes"
        :likesCount="likesCount"
        :liked="liked"
        :reactionUrl="reactionUrl"
      />
      <div class="actions">
        <a
          href="javascript:void(0)"
          class="toggle-comment-form"
          :class="{ hide: !canPostComment }"
          @click="toggleCommentForm"
        >
          <i class="far fa-comments" />
          コメントする
        </a>
        <span
          v-if="hasComments"
          v-text="commentsText"
          @click="toggleDisplayAllComments"
        />
      </div>
    </div>
    <div
      v-if="!hideComment && hasComments && !displayAllComments && latestComment"
      class="latest-comment"
    >
      <TopicComment
        v-bind="latestComment"
        :short="true"
      />
    </div>
    <div
      v-if="hasComments && displayAllComments"
      class="comments"
    >
      <TopicComment
        v-for="(comment, idx) in comments"
        v-bind="comment"
        :key="idx"
        :short="true"
      />
    </div>
    <div
      v-if="canPostComment"
      class="comment-form"
      :class="{ visible: commentFormVisible }"
    >
      <TopicForm v-bind="commentFormProps" />
    </div>
  </div>
</template>

<script>
import TopicHeader from 'components/TopicHeader'
import ReactionTopic from 'components/ReactionTopic'

export default {
  components: { TopicHeader, ReactionTopic },
  props: {
    writer: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    shortBody: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      default: () => []
    },
    createdAt: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    },
    commentsCount: {
      type: Number,
      required: true
    },
    commentFormProps: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      default: null
    },
    canPostComment: {
      type: Boolean,
      required: true
    },
    likes: {
      type: Array,
      default: () => []
    },
    likesCount: {
      type: Number,
      required: true,
      default: 0
    },
    reactionUrl: {
      type: String,
      required: true
    },
    liked: {
      type: Boolean,
      required: true,
      default: false
    },
    label: {
      type: Object,
      default: null
    },
    useNickname: {
      type: Boolean,
      required: true
    },
    hideComment: {
      type: Boolean,
      default: false
    },
    labelProps: {
      type: Object,
      default: null
    },
    canUpdateTopic: {
      type: Boolean,
      default: false
    },
    editTopicProps: {
      type: Object,
      default: null
    },
    inSwiper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      commentFormVisible: false,
      displayAllComments: false,
      displayFullPost: false,
      viewMode: true
    }
  },
  computed: {
    topicHeaderProps () {
      return {
        ...this.writer,
        createdAt: this.createdAt,
        label: this.label,
        title: this.title,
        useNickname: this.useNickname,
        viewMode: this.viewMode,
        toggleViewEdit: this.toggleViewEdit,
      }
    },
    editTopicFormProps () {
      return {
        ...this.editTopicProps,
        toggleViewEdit: this.toggleViewEdit,
      }
    },
    latestCommentTopicHeaderProps () {
      const { writer, createdAt } = this.latestComment
      return { ...writer, createdAt }
    },
    hasImages () {
      return this.images.length > 0
    },
    mainImage () {
      return this.images[0]
    },
    subImages () {
      return this.images.slice(1)
    },
    hasComments () {
      return this.commentsCount > 0
    },
    commentsText () {
      return `${this.commentsCount}件のコメント`
    },
    latestComment () {
      return this.comments[this.commentsCount - 1]
    },
    textTooLong () {
      return this.shortBody.length !== this.body.length
    }
  },
  methods: {
    toggleCommentForm () {
      this.commentFormVisible = !this.commentFormVisible
    },
    toggleDisplayAllComments () {
      this.displayAllComments = !this.displayAllComments
    },
    toggleDisplayFullPost () {
      this.displayFullPost = !this.displayFullPost
    },
    toggleViewEdit () {
      this.viewMode = !this.viewMode
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.topic-card
  background-color: #fff
  border: 1px solid #d7d7d7
  padding: 1rem
  margin-bottom: 5px
  border-radius: 0.375rem
  .item-hover
    cursor: pointer
  .item-hover:hover
    background-color: whitesmoke
    color: #0a0a0a
.main-content
  display: flex
  margin-top: 12px
  .main-image, .sub-image
    border-radius: 6px
    background-repeat: no-repeat
    background-position: center
    background-size: cover
  .main-image
    position: relative
    width: 178px
    min-width: 178px
    height: 178px
    margin-right: 16px
    +app-mobile
      width: 80px
      min-width: 80px
      height: 87px
    &.smaller-size
      width: 82px
      min-width: 82px
      height: 82px
      +app-mobile
        width: 55px
        min-width: 55px
        height: 55px
  .sub-image-container
    position: absolute
    left: 0
    bottom: 0
    display: flex
  .sub-image
    width: 30px
    height: 30px
    margin: 5px 5px 5px 0
    &:first-child
      margin-left: 5px
    +app-mobile
      width: 10px
      height: 10px
      margin: 2.5px 2.5px 2.5px 0
      &:first-child
        margin-left: 2.5px
  .body
    font-size: 12px
    +app-mobile
      .read-more
        margin-top: 0px
  .edit-body
    border-radius: 3px
    border: 1px solid #d7d7d7
    padding: 6px 12px
    resize: none
    font-size: 14px
    width: 100%
.actions
  width: 74%
  +app-mobile
    width: 75%
  display: flex
  justify-content: space-between
  .toggle-comment-form
    &.hide
      visibility: hidden
  span
    cursor: pointer
.latest-comment
  margin-top: 24px
  .topic-comment
    padding: 12px 0
.comments
  margin-top: 24px
  .topic-comment
    padding: 12px 0
    border-top: 1px solid #eee
.comment-form
  display: none
  &.visible
    margin-top: 12px
    display: block
.read-more
  text-align: right
  span
    cursor: pointer
.like-and-comment
  display: flex
  align-items: center
  margin-top: 20px
  +app-mobile
    font-size: 0.7em
  .like-and-popup
    width: 26%
    +app-mobile
      width: 25%
</style>
