<template>
  <div class="chat-main-wrapper">
    <div class="chat-main">
      <ChatMessage
        v-for="chatMessage in chatMessages"
        :key="chatMessage.id"
        v-bind="chatMessage"
        :is-myself="chatMessage.writerId === currentUser.id && chatMessage.writerType === currentUser.type"
      />
      <slot name="dialog" />
    </div>
  </div>
</template>

<script>
import ChatMessage from 'components/ChatMessage'

export default {
  components: { ChatMessage },
  props: {
    chatMessages: {
      type: Array,
      default: () => []
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  watch: {
    chatMessages () {
      this.$nextTick(() => {
        this.scroll()
      })
    }
  },
  mounted () {
    this.scroll()
  },
  methods: {
    scroll () {
      this.$el.scrollTop = this.$el.scrollHeight
    }
  }
}
</script>

<style scoped lang="sass">
.chat-main
  padding-bottom: 10px

.chat-main-wrapper
  flex: 1
  overflow-y: scroll
  position: relative
  -webkit-overflow-scrolling: touch
</style>
