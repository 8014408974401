/* eslint no-console:0 */

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import initApp from 'client/application'
import 'stylesheets/application'

require.context('../images', true, /\.(png|jpg|jpeg|svg|gif)$/)

initApp()
