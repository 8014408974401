<template>
  <div>
    <slot
      :onSubmit="onSubmit"
      :onChangeStartedAt="onChangeStartedAt"
      :onChangeFinishedAt="onChangeFinishedAt"
    />
  </div>
</template>

<script>
import { format } from 'date-fns'
import * as shareable from './shareable_form'

export default {
  props: {
    startedOn: {
      type: String,
      default: ''
    },
    finishedOn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputtedStartedOn: this.startedOn,
      inputtedFinishedOn: this.finishedOn
    }
  },
  methods: {
    onSubmit (ev) {
      if (shareable.confirmSharingCandidateEmpty(ev)) {
        this.confirmOvernight()
      }
    },
    onChangeStartedAt (date) {
      this.inputtedStartedOn = format(date, 'YYYY/MM/DD')
    },
    onChangeFinishedAt (date) {
      this.inputtedFinishedOn = format(date, 'YYYY/MM/DD')
    },
    confirmOvernight () {
      if (this.inputtedStartedOn !== this.inputtedFinishedOn) {
        if (!window.confirm('開始日と終了日が異なります。このまま発信しても宜しいですか?')) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style scoped lang="sass">
</style>
