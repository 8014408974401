<template>
  <div
    :class="{'is-home': isHome}"
    class="panel"
  >
    <a
      v-for="(announcement, key) in announcements"
      :key="key"
      :href="`/announcements/${announcement.id}`"
      class="panel-block"
    >
      <span
        class="is-mr-1 announce-date"
        v-text="announcement.announced_date"
      />
      <span
        class="panel-content announce-title"
        v-text="announcement.title"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    announcements: {
      type: Array,
      required: true
    },
    isHome: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.panel
  border-radius: 4px
  padding: 0.5rem
  border: none
  &.is-home
    border-radius: 0
    margin-top: 0
    margin-bottom: 0

.panel-block
  border: none
  padding: 0.5rem 1rem
  font-size: 0.75rem
  padding: 0.2em 0.5rem
  color: $primary
  &:first-child
    border-top: none

.announce-date
  min-width: 115px

.announce-title
  overflow-x: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
