<template>
  <span
    v-if="privacy === 'closed'"
    class="privacy-closed"
  >
    <i class="fas fa-lock" />
    非公開
  </span>
</template>

<script>
export default {
  props: {
    privacy: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.privacy-closed
  color: #b01f24
  font-size: 11px
  margin-right: 6px
</style>
