<template>
  <div class="topic-container">
    <div class="is-my-3">
      <a
        class="is-primary-link"
        :href="topicsPath"
      >
        &laquo;&nbsp;
        <span v-text="topicsBacklinkText" />
      </a>
    </div>
    <div class="topic-contents">
      <TopicHeader v-bind="topicHeaderProps" v-if="viewMode">
        <p v-if="canUpdateTopic" class="dropdown-item item-hover" @click="toggleViewEdit()">編集</p>
        <slot name="option-menu" />
      </TopicHeader>
      <div class="main-content">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-if="viewMode">
          <div
            class="body"
            v-html="body"
          />
        </div>
        <div v-else>
          <TopicForm v-bind="editTopicFormProps" />
        </div>
        <div class="actions">
          <ReactionTopic
            :likes="likes"
            :likesCount="likesCount"
            :liked="liked"
            :reactionUrl="reactionUrl"
          />
          <span
            v-if="hasComments"
            v-text="commentsText"
          />
        </div>
        <div
          v-if="hasImages && viewMode"
          class="images"
        >
          <div
            v-for="(img, idx) in images"
            :key="idx"
            class="image"
          >
            <image-modal
              :src="img.url"
              :original-src="img.original"
            />
          </div>
        </div>
      </div>
      <div class="comments">
        <slot name="comments" />
      </div>
    </div>
    <div
      v-if="canPostComment"
      class="comment-form"
    >
      <TopicForm v-bind="commentFormProps" />
    </div>
  </div>
</template>

<script>
import TopicHeader from 'components/TopicHeader'
import ReactionTopic from 'components/ReactionTopic'

export default {
  components: { TopicHeader, ReactionTopic },
  props: {
    writer: {
      type: Object,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      default: () => []
    },
    createdAt: {
      type: String,
      required: true
    },
    comments: {
      type: Array,
      default: () => []
    },
    commentFormProps: {
      type: Object,
      required: true
    },
    topicsPath: {
      type: String,
      required: true
    },
    topicsBacklinkText: {
      type: String,
      required: true
    },
    canPostComment: {
      type: Boolean,
      required: true
    },
    label: {
      type: Object,
      default: null
    },
    useNickname: {
      type: Boolean,
      required: true
    },
    likes: {
      type: Array,
      default: () => []
    },
    likesCount: {
      type: Number,
      required: true,
      default: 0
    },
    liked: {
      type: Boolean,
      required: true,
      default: false
    },
    reactionUrl: {
      type: String,
      required: true
    },
    canUpdateTopic: {
      type: Boolean,
      default: false
    },
    editTopicProps: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      viewMode: true,
    }
  },
  computed: {
    topicHeaderProps () {
      return {
        ...this.writer,
        createdAt: this.createdAt,
        label: this.label,
        useNickname: this.useNickname,
        viewMode: this.viewMode,
      }
    },
    editTopicFormProps () {
      return {
        ...this.editTopicProps,
        toggleViewEdit: this.toggleViewEdit,
      }
    },
    hasImages () {
      return this.images.length > 0
    },
    hasComments () {
      return this.comments.length > 0
    },
    commentsText () {
      return `${this.comments.length}件のコメント`
    }
  },
  methods: {
    toggleViewEdit () {
      this.viewMode = !this.viewMode
    },
  },
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.topic-container
  padding-bottom: 1rem
  height: calc(100vh - #{$app-desktop-header-height})
  display: flex
  flex-direction: column
  +app-mobile
    height: calc(100vh - #{$app-mobile-header-height})
  > *
    +app-mobile
      padding: 0 0.5rem

.topic-contents
  flex: 1
  overflow-y: scroll
  > *
    padding-right: 0.5rem
    +app-mobile
      padding-left: 0.5rem
  .item-hover
    cursor: pointer
  .item-hover:hover
    background-color: whitesmoke
    color: #0a0a0a

.main-content
  margin-top: 12px
  .body
    font-size: 12px
  .edit-body
    border-radius: 3px
    border: 1px solid #d7d7d7
    padding: 6px 12px
    resize: none
    font-size: 14px
    width: 100%
  .actions
    margin-top: 12px
    display: flex
    justify-content: space-between
  .images
    margin-top: 12px
    display: flex
    .image
      flex: 1 1
      max-width: 33.3333333%
      /deep/
        img
          border-radius: 0
.comments
  margin-top: 18px
.topic-comment
  padding: 12px 0
  border-top: 1px solid #eee
.comment-form
  border-top: 1px solid #eee
  padding-top: 12px
  display: block
</style>
