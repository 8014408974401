<template>
  <div :class="{'is-single-column': !showSideMenu}">
    <header
      v-if="visibleHeader"
      class="app-header"
    >
      <div class="inner">
        <div v-if="isMyCommuDomain" class="logo">
          <a href="/home">
            <img src="../images/header-logo-mycommu.png" alt="マイコミュ" />
          </a>
        </div>
        <div
          v-else-if="!showCommunityHeader"
          class="logo"
        >
          <a href="/home">
            <img
              src="../images/header-logo-kosodate-share.svg"
              alt="子育てシェア"
            >
          </a>
        </div>
        <div
          v-else
          class="logo community"
        >
          <a href="/home?for_community=1">
            <img
              src="../images/header-logo-asmama.jpg"
              alt="AsMama"
            >
          </a>
        </div>

        <div class="header-lefticons">
          <a
            v-show="visibleBackNav"
            class="back-nav"
            @click="goBack"
          >
            <i class="fal fa-chevron-left" />
          </a>
          <a
            v-if="currentUser"
            class="menu-nav"
            @click="isNavOpen=true"
          >
            <i class="fal fa-bars" />
          </a>
          <SignUpStatusNotice
            v-if="visibleSignUpStatusNotice"
            class="menu-nav"
          />
        </div>

        <div
          v-if="visibleFooter"
          class="main-nav"
        >
          <ul>
            <li :class="{'is-current': navPaths.home.isCurrent}">
              <a :href="navPaths.home.path">
                <i class="icon fal fa-home" />
                <span
                  class="nav-label"
                  v-text="navPaths.home.label"
                />
              </a>
            </li>
            <li :class="{'is-current': navPaths.history.isCurrent}">
              <a :href="navPaths.history.path">
                <i class="icon fal fa-handshake" />
                <span
                  class="nav-label"
                  v-text="navPaths.history.label"
                />
              </a>
            </li>
            <li :class="{'is-current': navPaths.event.isCurrent}">
              <a :href="navPaths.event.path">
                <i class="icon fal fa-users" />
                <span
                  class="nav-label"
                  v-text="navPaths.event.label"
                />
              </a>
            </li>
            <li :class="{'is-current': navPaths.chat.isCurrent}">
              <a :href="navPaths.chat.path">
                <i class="icon fal fa-comment">
                  <span v-if="unopenedCount > 0" class="unopened-count">{{ unopenedCount }}</span>
                </i>
                <span
                  class="nav-label"
                  v-text="navPaths.chat.label"
                />
              </a>
            </li>
          </ul>
        </div>

        <div
          v-if="isMyCommuDomain"
          class="header-title"
        >
          <a href="/home">
            <img
              class="header-logo-mobile my-commu"
              src="../images/header-logo-mycommu.png"
              alt="マイコミュ"
            >
          </a>
        </div>
        <div
          v-else-if="!showCommunityHeader"
          class="header-title"
        >
          <a href="/home">
            <img
              class="header-logo-mobile"
              src="../images/header-logo-kosodate-share.svg"
              alt="子育てシェア"
            >
          </a>
        </div>
        <div
          v-else
          class="header-title community"
        >
          <a href="/home?for_community=1">
            <img
              class="header-logo-mobile"
              src="../images/header-logo-asmama.jpg"
              alt="AsMama"
            >
          </a>
        </div>

        <div
          v-if="visibleNotifications"
          class="header-notifications"
        >
          <ul>
            <li
              v-if="visibleSignUpStatusNotice"
              class="header-notifications__sign-up-status"
            >
              <SignUpStatusNotice position="right" />
              <span>登録状況</span>
            </li>
            <li>
              <the-notification :label="navPaths.notification.label" />
            </li>

            <li class="header-notifications__profile">
              <a :href="navPaths.profile.path">
                <div class="face-icon">
                  <img
                    :src="navPaths.profile.avatarPath"
                    alt
                  >
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
    <div
      class="main-container"
      :class="{ 'has-footer-menu': visibleFooter, 'full-screen-content': fullScreenContent }"
    >
      <main
        class="app-main"
        :class="{ 'app-main-full': mainFullWidth }"
      >
        <slot name="main" />
      </main>

      <div
        v-if="showSideMenu"
        :class="{'is-nav-open': isNavOpen}"
        class="side-nav"
      >
        <div class="inner">
          <div class="side-nav-header">
            <div
              class="side-back"
              @click="isNavOpen=false"
            >
              <i class="icon fal fa-times" />
              <span v-text="navPaths.close.label" />
            </div>
            <div class="profile">
              <div class="face-icon">
                <a
                  :href="navPaths.profile.path"
                  @click="handleClickSideMenu"
                >
                  <img
                    :src="navPaths.profile.avatarPath"
                    alt
                  >
                </a>
              </div>
              <div class="profile-name">
                <a
                  :href="navPaths.profile.path"
                  @click="handleClickSideMenu"
                >
                  <span
                    class="full-name"
                    v-text="navPaths.profile.fullName"
                  />
                  <span
                    class="full-name-kana"
                    v-text="navPaths.profile.fullNameKana"
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="side-nav-scroll-area">
            <div class="side-nav-main">
              <ul v-if="isMyCommuDomain">
                <li>
                  <a :href="navPaths.top.path" @click="handleClickSideMenu" v-text="navPaths.top.label" />
                </li>
                <li v-if="!isV2()">
                  <a :href="navPaths.event.path" @click="handleClickSideMenu" v-text="navPaths.event.label" />
                </li>
                <li>
                  <a :href="navPaths.history.path" @click="handleClickSideMenu" v-text="navPaths.history.label" />
                </li>
                <li>
                  <a :href="navPaths.profilePrivacy.path" @click="handleClickSideMenu" v-text="navPaths.profilePrivacy.label" />
                </li>
                <li>
                  <a :href="navPaths.usersMap.path" @click="handleClickSideMenu" v-text="navPaths.usersMap.label" />
                </li>
                <li>
                  <a
                    :href="navPaths.inquiry.path"
                    target="_blank"
                    rel="noopener noreferrer"
                    v-text="navPaths.inquiry.label"
                  />
                </li>
                <li v-if="navPaths.news">
                  <a :href="navPaths.news.path" @click="handleClickSideMenu" v-text="navPaths.news.label" />
                </li>
                <li v-if="navPaths.admin">
                  <a :href="navPaths.admin.path" @click="handleClickSideMenu" v-text="navPaths.admin.label" />
                </li>
              </ul>
              <ul v-else>
                <li>
                  <a
                    :href="navPaths.top.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.top.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.search.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.search.label"
                  />
                </li>
                <li v-if="!isV2()">
                  <a
                    :href="navPaths.community.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.community.label"
                  />
                </li>
                <li v-if="!isV2()">
                  <a
                    :href="navPaths.event.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.event.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.history.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.history.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.chat.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.chat.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.profile.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.profile.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.profilePrivacy.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.profilePrivacy.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.usersMap.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.usersMap.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.inquiry.path"
                    target="_blank"
                    rel="noopener noreferrer"
                    v-text="navPaths.inquiry.label"
                  />
                </li>
                <li v-if="navPaths.news">
                  <a
                    :href="navPaths.news.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.news.label"
                  />
                </li>
                <li v-if="navPaths.admin">
                  <a
                    :href="navPaths.admin.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.admin.label"
                  />
                </li>
              </ul>
            </div>
            <div class="side-nav-sub">
              <ul>
                <li>
                  <a
                    :href="navPaths.terms.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.terms.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.userPolicy.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.userPolicy.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.privacyPolicy.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.privacyPolicy.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.safety.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.safety.label"
                    :target="isPc ? '_blank' : false"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.emergency.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.emergency.label"
                    :target="isPc ? '_blank' : false"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.grant.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.grant.label"
                    :target="isPc ? '_blank' : false"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.law.path"
                    @click="handleClickSideMenu"
                    v-text="navPaths.law.label"
                  />
                </li>
                <li>
                  <a
                    :href="navPaths.operatingCompany.path"
                    target="_blank"
                    v-text="navPaths.operatingCompany.label"
                  />
                </li>
              </ul>
            </div>
            <div v-if="!isMyCommuDomain" class="side-nav-sub side-nav-banner" >
              <ul>
                <li>
                  <a
                    :href="navPaths.oiwaipresent.path"
                    target="_blank"
                    v-text="navPaths.oiwaipresent.label"
                  />
                </li>
              </ul>
            </div>
            <div class="side-login">
              <a
                :href="navPaths.signOut.path"
                data-method="delete"
                v-text="navPaths.signOut.label"
              />
            </div>

            <div class="copyright">
              Copyright &copy; AsMama,Inc. All Rights reserved.
            </div>
          </div>
        </div>
      </div>
      <Transition name="side-bgfade">
        <div
          v-if="isNavOpen"
          class="side-nav-bg"
          @click="isNavOpen=false"
        />
      </Transition>
    </div>
    <footer class="app-footer" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TheNotification from 'components/TheNotification'
import SignUpStatusNotice from 'components/SignUpStatusNotice'
import { isMobileSafari, isIOS } from 'lib/browser'

export default {
  components: { TheNotification, SignUpStatusNotice },
  props: {
    navPaths: {
      type: Object,
      required: true
    },
    visibleNotifications: {
      type: Boolean,
      required: true
    },
    initialVisibleFooter: {
      type: Boolean,
      default: true
    },
    showSideMenu: {
      type: Boolean,
      default: true
    },
    useBackNav: {
      type: Boolean,
      default: false
    },
    mainFullWidth: {
      type: Boolean,
      default: false
    },
    fullScreenContent: {
      type: Boolean,
      default: false
    },
    visibleSignUpStatusNotice: {
      type: Boolean,
      default: false
    },
    showCommunityHeader: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isNavOpen: false,
      visibleHeader: true,
      visibleFooter: this.initialVisibleFooter
    }
  },
  computed: {
    ...mapState('session', ['currentUser']),
    ...mapState('notification', ['notifications']),
    visibleBackNav () {
      return this.useBackNav && window.history.length > 1
    },
    isPc () {
      return window.innerWidth > 768
    },
    unopenedCount () {
      return this.notifications.filter((item) => {
        return item.path.includes('chats') && item.openedAt === null
      }).length
    }
  },
  mounted () {
    // iOSでソフトェアキーボードが出てるときにフッターメニューのposition: fixedが効かなくなることへの対応。
    // ソフトウェアキーボードが出てるときはヘッダ、フッターメニューを隠すように
    if (window.APP.rails.is_mobile_app_iphone || isMobileSafari()) {
      this.$el.addEventListener('focus', ({ target }) => {
        if (target.matches('input[type=text], textarea, select')) {
          this.visibleHeader = false
          this.visibleFooter = false
        }
      }, true)
      this.$el.addEventListener('blur', ({ target }) => {
        if (target.matches('input[type=text], textarea, select')) {
          this.visibleHeader = true
          this.visibleFooter = this.initialVisibleFooter
        }
      }, true)
    }
  },
  methods: {
    goBack () {
      window.history.back()
    },
    isV2 () {
      return location.pathname.indexOf('/v2') === 0
    },
    handleClickSideMenu () {
      if (isIOS()) {
        this.isNavOpen = false
      }
    }
  }
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'

  $mobile-header-z-index: $over-leaflet-z-index
  $mobile-sidenav-z-index: $over-leaflet-z-index + 2
  $mobile-sidenav-bg-z-index: $over-leaflet-z-index + 1
  $app-mobile-footer-height: 50px

  // TODO: 必要なスタイルだけに絞りたい
  .mb1r
    margin-bottom: 1rem

  .mb10
    margin-bottom: 10px

  .column
    display: flex
    flex-direction: column

  .textbutton
    appearance: none
    background-color: transparent
    font-weight: bold
    font-size: 100%
    border: none
    color: #ec6b00
    &[disabled]
      color: #666

  .main-content
    padding: 80px 0

  .title-header-1
    font-weight: bold
    font-size: 120%

  .title-header-2
    font-size: 105%
    font-weight: bold
    margin-bottom: 0.6em

  .title-header-3
    color: #666
    font-size: 85%
    font-weight: bold
    margin-bottom: 0.6em

  .help
    color: #999
    .fa-exclamation-circle
      margin-right: 5px

  .notation
    border: 1px solid #eee
    background-color: #fefefe
    padding: 0.5rem
    font-size: 80%

  .create-profile
    padding: 1rem 1rem 3rem

  .title-container
    font-weight: bold
    font-size: 24px
    margin: 1.1em 0
    &.small
      font-size: 18px

  .text-container
    font-size: 16px
    margin-bottom: 1em

  .flow-container
    display: flex
    justify-content: space-between
    align-items: center
    position: relative
    > .bullet
      position: relative
      width: 10px
      height: 10px
      border-radius: 50%
      background-color: #fff
      border: 2px solid #ccc
      &.is-active
        background-color: #ccc
    &::before
      position: absolute
      content: ""
      height: 2px
      width: 100%
      background-color: #ccc
      top: 6px
      left: 0

  .face-icon img
    border-radius: 50%
    width: 50px
    height: 50px

  .badge-notification
    position: absolute
    padding: 2px 6px
    border-radius: 1em
    color: white
    display: flex
    justify-content: center
    align-items: center
    background-color: #ec6b00
    font-size: 11px
    line-height: 1
    top: 5px
    right: 15px

  +app-mobile
    .badge-notification
      top: 0px
      right: 0px

  html
    position: relative
    box-sizing: border-box
    *
      box-sizing: border-box
      &:before, &:after
        box-sizing: border-box

  h1, h2, h3
    margin: 0

  img
    max-width: 100%
    vertical-align: middle

  body
    color: #333
    font-size: 16px
    line-height: 1.5
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", HiraginoCustom, "Hiragino Kaku Gothic ProN", YuGothic, "Yu Gothic Medium", Meiryo, sans-serif
    padding-bottom: 30px
    margin: 0
    position: relative
    padding: 0

  ul, li
    list-style-type: none
    margin: 0
    padding: 0

  figure
    margin: 0
    padding: 0

  .l-section
    padding: 0 1rem

  .app-header
    width: 100%
    border-bottom: 1px solid #eee
    > .inner
      width: 740px
      height: $app-desktop-header-height - 1px
      margin: auto
      display: flex
      justify-content: space-between
      align-items: center
      a
        text-decoration: none
        display: block
      >
        .main-nav ul, .header-notifications ul
          display: inline-flex
          justify-content: space-between
          align-items: stretch
        .main-nav ul li a, .header-notifications ul li a
          position: relative
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          color: #333
        .main-nav ul li a span, .header-notifications ul li a span
          font-size: 9px
        .main-nav ul li a:hover, .header-notifications ul li a:hover
          background-color: #f9f9f9
          border-radius: 4px
      .main-nav
        display: none
        flex-grow: 1
        > ul
          justify-content: flex-start
          li
            a
              padding: 10px 20px
            &.is-current a
              color: #ec6b00
      .header-notifications > ul
        justify-content: flex-end
        align-items: stretch
        li a
          padding: 10px 20px
      .logo
        width: 240px
        img
          width: 140px
      .logo.community
        img
          width: 100px
      .icon
        font-size: 22px
      .menu-nav, .back-nav, .user, .header-title
        display: none
  .header-notifications__profile
    display: none
    .face-icon img
      width: 34px
      height: 34px
  .header-notifications__sign-up-status
    display: flex
    flex-direction: column
    align-items: center
    padding: 10px 20px
    span
      font-size: 9px
      margin-top: 6px
  +app-mobile
    .header-notifications__profile
      display: block
      a
        padding: 0 5px!important
    .header-notifications__sign-up-status
      display: none
  +app-mobile
    .app-header
      position: fixed
      top: 0
      left: 0
      z-index: $mobile-header-z-index
      background: #fff
      box-shadow: 0 1px 1px #efefef
      border-bottom: 1px solid #e4e4e4

  +app-mobile
    .app-header > .inner >
      .main-nav ul, .header-notifications ul
        display: flex

  +app-mobile
    .app-header > .inner .main-nav > ul li a
      padding: 10px 0px

  +app-mobile
    .app-header > .inner .icon
      font-size: 20px
      text-align: center
      .unopened-count
        display: inline-block
        position: absolute
        top: 0px
        width: 17px
        line-height: 17px
        color: #fff
        background: #ff3860
        border-radius: 50%
        box-sizing: content-box

  +app-mobile
    // FIXME: for iOS TEST
    html, body
      -webkit-overflow-scrolling : touch !important
      overflow: auto !important
      height: 100% !important
    .app-header > .inner
      width: 100vw
      padding: 0
      height: $app-mobile-header-height - 1px
      position: relative
      .header-lefticons
        display: flex
        align-items: center
        z-index: 2
        padding-left: 6px
      .menu-nav, .back-nav, .user, .header-title
        display: block
      .menu-nav
        font-size: 24px
        position: relative
        padding-left: 10px
        padding-right: 10px
        color: $color-headericon
      .back-nav
        font-size: 25px
        position: relative
        padding-left: 15px
        padding-right: 15px
        color: $color-headericon
      .header-title
        text-align: center
        font-size: 18px
        font-weight: 600
        position: absolute
        width: 100%
        height: 100%
        left: 0
        top: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 1
        .header-logo-mobile
          width: 35vw
          max-height: $app-mobile-header-height - 5px
        .header-logo-mobile.my-commu
          max-width: 144px
      .header-title.community
        .header-logo-mobile
          width: 20vw
      .logo
        display: none
      .header-notifications
        position: relative
        z-index: 2
        margin-right: 10px
        > ul
          justify-content: flex-end
          li
            margin-left: 0
            a
              padding: 5px
              .icon
                display: flex
                justify-content: center
                align-items: center
                color: $color-headericon
              span
                display: none

  +app-mobile
    .app-header
      > .inner
        .main-nav
          display: block
    .main-nav
      position: fixed
      bottom: 0
      left: 0
      width: 100vw
      background-color: #fff
      height: $app-mobile-footer-height
      border-top: 1px solid #eee
      z-index: 10
      padding: 0 0px
      ul
        display: flex
        justify-content: space-between !important
        align-items: stretch !important
        height: 100%
      li
        margin: 0 !important
        flex-grow: 1
        flex-basis: 1px
        a
          height: 100%
          display: flex
          justify-content: center
          align-items: center
          flex-direction: column
          line-height: 1
          span
            margin-top: 3px
            font-size: 11px !important
      .user
        .face-icon img
          height: 36px
          width: 36px
        .nav-label
          display: none

  .side-nav
    width: 200px
    .side-back
      display: none
    .profile
      margin: 16px 0
      display: flex
      justify-content: space-between
      +app-mobile
        margin-top: 0
      .face-icon
        width: 50px
    .profile-name
      flex-grow: 1
      width: calc(100% - 50px)
      padding-left: 8px
      display: flex
      flex-direction: column
      justify-content: center
      .full-name
        margin-bottom: 3px
        display: block
        font-size: 16px
        font-weight: 600
      .full-name-kana
        display: block
        font-size: 9px
        margin-top: 3px
        font-weight: normal
    .side-nav-main
      ul
      li a
        color: #333
        font-weight: 600

        display: block
        padding: 4px 0
        font-size: 14px
        cursor: pointer
        transition: 0.2s
        &:hover
          background-color: #f9f9f9
          text-decoration: none
          padding-left: 5px
    .side-nav-sub
      margin-top: 10px
      li a
        color: #333
        padding: 4px 0
        font-size: 12px
      &.side-nav-banner
        border-top: 1px solid #eee
        border-bottom: 1px solid #eee
        padding: 10px 0
        li a
          color: $primary
          line-height: 1.3
          display: block
    .side-login
      margin: 10px 0
      a
        font-size: 12px
        color: #333
        font-weight: 600
    .copyright
      margin-top: 10px
      font-size: 9px
      color: #9b9b9b
      padding-bottom: 100px

  +app-mobile
    .side-nav
      width: auto
      z-index: $mobile-sidenav-z-index
      .inner
        width: 80vw
        height: 100vh
        padding: 0 20px 20px
        z-index: $mobile-sidenav-z-index
        background-color: #fff
        box-shadow: 2px 0 10px #333
        position: fixed
        left: 0
        top: 0
        transform: translateX(-100vw)
        transition: 0.4s
        display: flex
        flex-direction: column
      .side-nav-scroll-area
        overflow-y: scroll
        -webkit-overflow-scrolling: touch
      &.is-nav-open
        .inner
          transition: 0.4s 0.2s
          transform: translateX(0)
      .side-back
        display: inline-flex
        align-items: center
        justify-content: flex-start
        padding: 20px 0 15px
        .icon
          display: inline-block
          font-size: 24px
        span
          display: inline-block
          font-size: 14px
          margin-left: 5px
          line-height: 14px

  .side-nav-bg
    content: ""
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.6)
    position: fixed
    top: 0
    left: 0
    z-index: $mobile-sidenav-bg-z-index
    display: none

  +app-mobile
    .side-nav-bg
      display: block

  .side-bgfade-enter
    opacity: 0
    transition: 0.1s

  .side-bgfade-enter-to
    opacity: 1
    transition: 0.2s

  .side-bgfade-leave
    opacity: 1

  .side-bgfade-leave-to
    transition: 0.2s 0.4s
    opacity: 0

  .main-container
    width: 740px
    margin: auto
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex-direction: row-reverse
    &.has-footer-menu
      padding-bottom: 4rem

  +app-mobile
    .main-container
      width: 100vw
      margin-top: $app-mobile-header-height
      display: block
      &.has-footer-menu
        margin-bottom: $app-mobile-footer-height
      &.full-screen-content
        padding-bottom: 0
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

  .app-main
    width: $app-main-desktop-width
    &.app-main-full
      width: 100%

    +app-mobile
      width: auto

  .flex
    display: flex
    justify-content: space-between
    align-items: center

  .is-flex-start
    justify-content: flex-start

  .is-flex-center
    justify-content: center

  .is-single-column
    .main-container
      width: auto

  .is-single-column
    .main-container
      width: 100vw
    .app-main
      width: 500px
      margin: auto
      &.app-main-full
        width: 100%

  +app-mobile
    .is-single-column
      .app-main
        width: auto
</style>
