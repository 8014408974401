<template>
  <div
    class="tab-noicon is-px-2"
    :class="{ 'in-app-container': inAppContainer }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    inAppContainer: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

.tab-noicon
  margin-bottom: 30px
  &.in-app-container
    +app-mobile
      margin-left: -10px
      margin-right: -10px

  ul
    display: flex
    justify-content: space-between
    align-items: center
  li
    flex-grow: 1
    flex-basis: 1px
    position: relative

    .last-border
      display: none

    +app-mobile
      &:last-child
        .last-border
          content: none
    &.is-active
      background-color: $primary
      border-radius: 8px
      a
        color: #fff

    a
      display: flex
      justify-content: center
      align-items: center
      position: relative
      padding: 8px 3px
      line-height: 1
      font-size: 14px
      text-align: center
      +app-mobile
        font-size: 12px

      &::after
        transition: 0.2s
        transform: scale(0)
        opacity: 0
        content: ''
        position: absolute
        left: 5px
        top: 0
        width: calc(100% - 10px)
        height: calc(90% + 3px)
        border-radius: 8px
        background-color: rgba(rgb(238, 238, 238), 0.5)
        display: block
    &:not(.is-active)
      a
        &:hover::after
          transform: scale(1)
          opacity: 1
        &:active::after
          background-color: darken(rgb(238, 238, 238), 10%)
          transform: scale(1)
          opacity: 1
</style>
