<template>
  <div class="share-friend-animation">
    <img
      class="icons"
      src="../images/share_friend_animation_icons.png"
    >
    <canvas class="canvas" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      card: null,
      icons: null,
      canvas: null,
      ctx: null
    }
  },
  mounted () {
    [this.icons] = this.$el.getElementsByClassName('icons');
    [this.canvas] = this.$el.getElementsByClassName('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.animate()
  },
  methods: {
    generateIcon () {
      const icon = {
        x: Math.floor(Math.random() * this.$el.clientWidth),
        y: this.$el.clientHeight + Math.random() * 500,
        vy: Math.random() * 5 + 3,
        type: Math.floor(Math.random() * 14),
        life: true
      }
      icon.update = () => {
        icon.y -= icon.vy
        if (icon.y < -40) {
          icon.life = false
        }
      }
      return icon
    },
    animate () {
      let timerId = 0
      this.canvas.width = this.$el.clientWidth
      this.canvas.height = this.$el.clientHeight

      const icons = []
      const iconsNum = 100

      for (let i = 0; i < iconsNum; i += 1) {
        const icon = this.generateIcon()
        icons.push(icon)
      }

      const update = () => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        timerId = requestAnimationFrame(update)
        for (let i = 0; i < iconsNum; i += 1) {
          const icon = icons[i]
          icon.update()
          this.ctx.save()
          this.ctx.setTransform(1, 0, 0, 1, icon.x, icon.y)
          this.ctx.drawImage(this.icons, icon.type * 52, 0, 52, 46, 0, 0, 26, 23)
          this.ctx.restore()
        }
        if (!icons.some(icon => icon.life === true)) {
          cancelAnimationFrame(timerId)
          this.$emit('animation-finished')
        }
      }

      update()
    }
  }
}
</script>

<style scoped lang="sass">
.share-friend-animation
  width: 100%
  height: 100%

.icons
  display: none
</style>
