// NOTE: app/models/age.rb を js に移植
function isPreviousDayOfMonth (date, now) {
  return now.getMonth() > date.getMonth() ||
         (now.getMonth() === date.getMonth() && now.getDate() >= date.getDate())
}

function years (date, now) {
  return now.getFullYear() - date.getFullYear() - (isPreviousDayOfMonth(date, now) ? 0 : 1)
}

function months (date, now) {
  if (isPreviousDayOfMonth(date, now)) { return now.getMonth() - date.getMonth() } else { return now.getMonth() + 12 - date.getMonth() }
}

export function ageOf (date, now = null) {
  now = now || new Date()
  return {
    years: years(date, now),
    months: months(date, now)
  }
}
