<template>
  <div>
    <slot :onChangeHistoryQuery="onChangeHistoryQuery" />
  </div>
</template>

<script>
export default {
  props: {
    basePath: {
      type: String,
      required: true
    }
  },
  methods: {
    onChangeHistoryQuery (value) {
      const url = value === 'past' ? `${this.basePath}?past=1` : this.basePath
      window.location.href = url
    }
  }
}
</script>

<style scoped lang="sass">
</style>
