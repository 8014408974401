<template>
  <MessageModalLink :modal-title="modalTitle">
    <template #default="slotProps">
      <ButtonSquareEdge
        @click="slotProps.onClick"
        v-text="label"
      />
    </template>
    <template #form>
      <slot />
    </template>
  </MessageModalLink>
</template>

<script>
import ButtonSquareEdge from 'components/ButtonSquareEdge'
import MessageModalLink from 'components/MessageModalLink'

export default {
  components: { ButtonSquareEdge, MessageModalLink },
  props: {
    label: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      default: '一言どうぞ'
    }
  }
}
</script>

<style scoped lang="sass">
</style>
