<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :custom-class="customClass"
    expanded
  >
    <BField>
      <BInput
        :id="id"
        v-model="inputValue"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        expanded
        @blur="handleBlur"
        @input="handleInput"
      />
      <p class="control">
        <span
          class="button is-static"
          v-text="addon"
        />
      </p>
    </BField>
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    onInput: {
      type: Function,
      default: () => {}
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    addon: {
      type: String,
      required: true
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data () {
    return { inputValue: this.value }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    }
  },
  methods: {
    handleBlur (e) {
      this.onBlur(e)
    },
    handleInput (value) {
      this.onInput(value)
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  @import '~stylesheets/components/required-label'
</style>
