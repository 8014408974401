<template>
  <span>
    <a
      v-if="asLink"
      href="javascript:void(0)"
      class="is-primary-link"
      @click.prevent="isHelpModalActive = true"
    >
      <span
        v-if="!$slots.button"
        v-text="label"
      />
      <slot name="button" />
    </a>
    <button
      v-else
      class="help-button"
      type="button"
      title="ヘルプ"
      @click.prevent="isHelpModalActive = true"
    >
      <BIcon
        pack="far"
        icon="question-circle"
      />
      <span
        v-if="showLabel"
        class="help-button__label"
        v-text="label"
      />
    </button>

    <BModal
      :active.sync="isHelpModalActive"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="title"
          />
          <BIcon
            pack="far"
            icon="question-circle"
          />
        </header>
        <section class="modal-card-body">
          <slot />
        </section>
      </div>
    </BModal>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    asLink: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'ヘルプ'
    }
  },
  data () {
    return {
      isHelpModalActive: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.help-button
  background-color: transparent
  border: none
  cursor: pointer
  padding: 0
  display: inline-flex
  align-items: center

.help-button__label
  line-height: 24px
  +mobile
    display: none

.modal-card
  font-size: 1rem
  text-align: left
  width: auto

.modal-card-body
  border-radius: 0 0 $radius-large $radius-large

  /deep/ > * ~ *
    margin-top: 1rem

  /deep/ .header-content + *
    margin-top: unset

  /deep/ ul, /deep/ ol
    margin-left: 2rem

  /deep/ ul
    list-style-type: disc
</style>
