<template>
  <div class="sharing-candidate-community">
    <FaceIcon
      :src="imagePath"
      class="community-icon"
    />
    <div class="community-text">
      <p class="community-name">
        <span v-text="name" />
      </p>
      <p
        class="community-address is-size-7"
        v-text="addressAndDistance"
      />
    </div>
  </div>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'

export default {
  components: {
    FaceIcon
  },
  props: {
    imagePath: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    addressAndDistance: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$left-padding: 64px
$left-padding-sp: 48px

.sharing-candidate-community
  display: flex
  justify-content: space-between
  width: 100%
  padding-left: $left-padding
  align-items: center
  position: relative
  min-height: 62px
  +app-mobile
    min-height: 52px
    padding-left: $left-padding-sp
  .community-text
    flex-grow: 1
    margin-left: 8px
  .community-name
    font-weight: bold
  .community-address
    color: #777
  .community-icon
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    display: block
    width: $left-padding
    +app-mobile
      width: $left-padding-sp
/deep/ .face-icon
  display: block
/deep/ .face-icon > .icon
  display: block
  margin: 0
  &:first-child:not(:last-child)
    margin: 0
+app-mobile
  /deep/ .face-icon > .icon
    width: 48px
    height: auto
</style>
