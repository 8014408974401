export function writerShowPath ({ type, id }) {
  switch (type) {
    case 'User':
      return `/users/${id}`
    case 'CorporateUser':
      return `/corp/corporate_users/${id}`
    default:
      return '#'
  }
}
