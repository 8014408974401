<template>
  <h3
    :style="{textAlign: textAlign}"
    :class="{'has-background-white-ter': hasBg,'is-home': isHome}"
    class="header-content"
  >
    <slot />
  </h3>
</template>

<script>
export default {
  props: {
    textAlign: {
      type: String,
      default: 'left'
    },
    hasBg: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.header-content
  padding: 0.2em 0
  font-weight: 700
  font-size: 1rem
  margin: 0.6em 0

  +mobile
    +header-fs

// HOME用：app-container対応で、モバイル時左右にマイナスマージンあり
.is-home
  font-size: 1rem
  font-weight: bold
  background-color: #eeeeee
  margin: 0 0 10px
  padding: 5px 10px
  +app-mobile
    margin-left: -10px
    margin-right: -10px

.is-sign-up-subtitle
  font-size: 1.2rem
  border-bottom: 1px solid #ddd
  padding-bottom: 0
</style>
