<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p>発信先から除外する人を選ぶ</p>
    </header>
    <section class="modal-card-body">
      <div class="has-background-light is-p-3 is-m-3">
        <BField
          label="名前またはメールアドレス"
          label-for="exclude-target-select-modal-email-or-name"
        >
          <BInput
            id="exclude-target-select-modal-email-or-name"
            v-model="emailOrName"
            placeholder="名前で探す"
          />
        </BField>
        <div class="has-text-centered">
          <button
            type="button"
            class="button is-primary is-rounded is-halfwidth"
            @click="search"
          >
            検索する
          </button>
        </div>
      </div>
      <p class="section-label">
        除外した人
      </p>
      <div
        v-if="selectedUsers.length > 0"
        class="user-wrapper"
      >
        <div class="user-bar">
          <div class="bar" />
        </div>
        <div class="user-items">
          <div
            v-for="user in selectedUsers"
            :key="user.id"
            class="sharing-candidate-checkbox"
          >
            <BCheckboxButton
              v-model="selectedUserIds"
              :native-value="user.id"
            >
              <SharingCandidateUser v-bind="user" />
            </BCheckboxButton>
          </div>
        </div>
      </div>
      <p class="section-label">
        検索結果
      </p>
      <div
        v-if="foundUsers.length > 0"
        class="user-wrapper"
      >
        <div class="user-bar">
          <div class="bar" />
        </div>
        <div class="user-items">
          <div
            v-for="user in visibleUsers"
            :key="user.id"
            class="sharing-candidate-checkbox"
          >
            <BCheckboxButton
              v-model="selectedUserIds"
              :native-value="user.id"
            >
              <SharingCandidateUser v-bind="user" />
            </BCheckboxButton>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-rounded"
        type="button"
        @click="$parent.close()"
      >
        閉じる
      </button>
      <button
        class="button is-primary is-rounded"
        type="button"
        @click="submit"
      >
        除外する
      </button>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'

const MAX_USER_COUNT = 300

export default {
  props: {
    organization: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      emailOrName: '',
      foundUsers: [],
      selectedUsers: this.users,
      selectedUserIds: this.users.map(_ => _.id)
    }
  },
  computed: {
    visibleUsers () {
      return this.foundUsers.slice(0, MAX_USER_COUNT)
    }
  },
  watch: {
    selectedUserIds (newValues) {
      const map = new Map()
      this.collectSelectedUsers(map, this.foundUsers, newValues)
      this.collectSelectedUsers(map, this.selectedUsers, newValues)
      this.selectedUsers = Array.from(map.values())
    }
  },
  methods: {
    async search () {
      const params = {
        'q[by_email_or_name]': this.emailOrName
      }
      const { data: { users } } = await axios.get(`/organizations/${this.organization.id}/users.json`, { params })
      this.foundUsers = users
    },
    submit () {
      this.$emit('submit', this.selectedUsers)
      this.$parent.close()
    },
    collectSelectedUsers (map, users, selectedUserIds) {
      users.filter(_ => selectedUserIds.includes(_.id)).forEach((user) => {
        map.set(user.id, user)
      })
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
@import '~stylesheets/components/sharing-candidate-checkbox'

.modal-card-head
  padding: 14px
  justify-content: center
  p
    text-align: center
    font-weight: bold

.modal-card-foot
  padding: 12px
  justify-content: center

.modal-card-body
  padding: 0 0 20px

.has-text-centered
  .control
    text-align: center
    margin-bottom: 1rem

.section-label
  font-size: 14px
  font-weight: 600
  border-top: 1px solid $color-border-gray
  border-bottom: 1px solid $color-border-gray
  padding: 10px 14px 6px

.user-wrapper
  display: flex
  justify-content: space-between

.user-bar
  width: 26px
  text-align: center
  padding: 6px 10px
  .bar
    display: block
    background-color: #eee
    width: 6px
    height: 100%
    border-radius: 6px

.user-items
  border-left: 1px solid #d7d7d7
  flex: 1
</style>
