<template>
  <div>
    <slot
      :onChangeHaveAllergy="onChangeHaveAllergy"
      :haveAllergy="haveAllergySelected"
    />
  </div>
</template>

<script>
import * as AutoKana from 'vanilla-autokana'
import * as inputs from './inputs'

function isElementExists (elementId) {
  return !!document.getElementById(elementId)
}

export default {
  props: {
    haveAllergy: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      haveAllergySelected: this.haveAllergy
    }
  },
  mounted () {
    this.bindAutoKana('child_profile[first_name]', 'child_profile[first_name_kana]')
  },
  methods: {
    bindAutoKana (name, kana) {
      const nameId = inputs.idForName(name)
      const kanaId = inputs.idForName(kana)
      if (isElementExists(nameId) && isElementExists(kanaId)) {
        AutoKana.bind(nameId, kanaId, { katakana: true })
      }
    },
    onChangeHaveAllergy (v) {
      this.haveAllergySelected = (parseInt(v, 10) === 1)
    }
  }
}
</script>

<style scoped lang="sass">
</style>
