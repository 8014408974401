<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.law-header
  +app-mobile
    margin: 0 20px

.law-wrapper
  +app-mobile
    margin: 0 0 0 20px

.law-table-wrapper
  +app-mobile
    margin: 0 20px
  th
    padding-left: 0
    padding-right: 0
    white-space: nowrap
  td
    padding-right: 0
    vertical-align: middle

.law-box
  &.is-narrow
    dd
      margin: 0 0 5px
  dd
    margin: 0 0 15px

    &:last-of-type
      margin: 0

.law-list
  li
    list-style: disc
    margin-left: 1rem

.law-small
  display: block
  margin: 5px 0 0
  line-height: 1.5
</style>
