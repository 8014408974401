<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
.profile-main
  position: relative
  margin-bottom: 2.75rem
  .profile-cover
    display: flex
    justify-content: center
    align-items: center
    background-size: cover
    background-position: center
    background-image: linear-gradient(#f76b1c, #fa61ba)
    .add-cover-button
      display: inline-block
      font-size: 10px
      color: rgba(#fff, 0.8)
      border: 2px solid rgba(#fff, 0.5)
      text-shadow: 0 0 10px #333
      padding: 0.86em 1.86em
      border-radius: 2.4em
      font-weight: 600
      &:active
        background-color: rgba(#333, 0.1)
      i
        margin-right: 5px

  .face-icon
    position: absolute
    bottom: -2.75rem
    left: 20px

  .sign-up-status-notice
    position: absolute
    bottom: -2.75rem
    right: 20px

.profile-name-container
  display: flex
  justify-content: space-between
  padding: 0 20px
  align-items: center
</style>
