import axios from 'axios'
import { showSuccess, showError } from 'lib/app-toast'

/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {},
  mutations: {
    setNofications (state, notifications) {
      state.notifications = notifications
    }
  },
  actions: {
    async fetchNotifications ({ commit }) {
      try {
        const { data: notifications = [] } = await axios.get('/api/v1/notifications')
        commit('setNofications', notifications)
      } catch (e) {
        showError(e.message)
      }
    },
    async openAll ({ dispatch, commit }) {
      try {
        await axios.post('/api/v1/all_notifications_open')
        showSuccess('すべて既読にしました。')
        dispatch('fetchNotifications')
      } catch (e) {
        showError(e.message)
      }
    }
  }
}
export default store
