<template>
  <div class="range-slider-input-container">
    <label
      v-if="label"
      class="label"
      v-text="label"
    />
    <div class="control slider-container">
      <vue-slider
        v-model="value"
        :absorb="true"
        :data="sliderData"
        :marks="true"
        :tooltip-formatter="tooltipFormatter"
        @change="handleChange"
      />
    </div>
    <input
      type="hidden"
      :name="minName"
      :value="currentMinValue"
    >
    <input
      type="hidden"
      :name="maxName"
      :value="currentMaxValue"
    >
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  components: { VueSlider },
  props: {
    minName: {
      type: String,
      required: true
    },
    maxName: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    minValue: {
      type: [String, Number],
      default: () => null
    },
    maxValue: {
      type: [String, Number],
      default: () => null
    },
    collection: {
      type: Array,
      required: true
    },
    tooltips: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      value: [this.minValue, this.maxValue],
      tooltipFormatter: v => this.valueToTooltip(v)
    }
  },
  computed: {
    sliderData () {
      return this.collection.map(([v]) => v)
    },
    marks () {
      const obj = {}
      this.collection.forEach(([v, k]) => { obj[k] = v })
      return obj
    },
    valueToTooltipMap () {
      if (!this.tooltips) return {}

      const obj = {}
      for (let i = 0; i < this.tooltips.length; ++i) {
        const v = this.sliderData[i]
        obj[v] = this.tooltips[i]
      }
      return obj
    },
    currentMinValue () {
      return this.value[0]
    },
    currentMaxValue () {
      return this.value[1]
    }
  },
  methods: {
    handleChange (value) {
      this.value = value
      this.$emit('change', value)
    },
    valueToTooltip (value) {
      return this.valueToTooltipMap[value] || value
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.range-slider-input-container
  margin-bottom: 3rem
  +app-mobile
    margin-bottom: 1.25rem

.slider-container
  padding-left: 0.25rem
  padding-right: 0.25rem
  +app-mobile
    padding-left: 3rem
    padding-right: 3rem
  /deep/
    .vue-slider
      box-sizing: initial
    +app-mobile
</style>
