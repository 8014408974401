<template>
  <div class="is-px-3">
    <TabNoIcon
      :in-app-container="true"
      class="is-mb-3"
    >
      <ul>
        <li :class="{ 'is-active': isZipCodeView }">
          <a
            href="javascript:void(0)"
            @click="setZipCodeView"
          >
            郵便番号を入力
          </a>
        </li>
        <li :class="{ 'is-active': !isZipCodeView }">
          <a
            href="javascript:void(0)"
            @click="setAddressView"
          >
            住所を入力
          </a>
        </li>
      </ul>
    </TabNoIcon>
    <div
      v-show="isZipCodeView"
      class="sign-up-address-input-zip-code"
    >
      <BField
        :message="zipCodeErrorMessage"
        :type="zipCodeStatus"
        class="is-mb-3"
      >
        <BInput
          v-model="dirtyZipCode"
          name="profile[zip_code]"
          placeholder="例：1500001"
          @blur="handleZipCodeBlur"
          @keypress.native="handleZipCodeKeypress"
        />
      </BField>
      <div class="has-text-centered is-mb-3">
        <a
          href="https://www.post.japanpost.jp/zipcode/index.html"
          class="is-primary-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="far fa-external-link" />
          郵便番号が分からない方はこちら
        </a>
      </div>
      <div
        v-if="isAddressMatched"
        class="has-text-centered is-size-5 sign-up-address-input-zip-code__address"
      >
        <p class="has-text-grey is-size-7 is-mb-1">
          登録されるご住所
        </p>
        <p v-text="dirtyPrefecture" />
        <p v-text="dirtyAddress1" />
      </div>
    </div>
    <div
      v-show="!isZipCodeView"
      class="sign-up-address-input-address"
    >
      <div class="has-text-centered is-mb-2">
        住所を入力してください。
      </div>
      <div class="has-background-light is-p-3 sign-up-address-input-address__form">
        <BField
          label="都道府県"
          label-for="profile_prefecture_"
          class="is-mb-2"
        >
          <BSelect
            id="profile_prefecture_"
            v-model="dirtyPrefecture"
            name="profile[prefecture]"
            class="js-geocoding-region"
          >
            <option
              v-for="opt in prefectureOptions"
              :key="opt"
              :value="opt"
              v-text="opt"
            />
          </BSelect>
        </BField>
        <BField
          label="市区町村"
          label-for="profile_address1_"
        >
          <BInput
            id="profile_address1_"
            v-model="dirtyAddress1"
            name="profile[address1]"
            placeholder="例：渋谷区神宮前"
            class="js-geocoding-address1"
            @keypress.native="handleAddress1Keypress"
          />
        </BField>
      </div>
    </div>
  </div>
</template>

<script>
import { findAddressFromPostal } from 'lib/auto_input_address'
import TabNoIcon from 'components/TabNoIcon'

const VIEW_ZIP_CODE = 'zip-code'
const VIEW_ADDRESS = 'address'
const ENTER_KEY = 13

function preventSubmitWithEnter (ev) {
  if (ev.which === ENTER_KEY || ev.keyCode === ENTER_KEY) {
    ev.preventDefault()
    return true
  } else {
    return false
  }
}

export default {
  components: { TabNoIcon },
  props: {
    zipCode: {
      type: String,
      default: ''
    },
    prefecture: {
      type: String,
      default: ''
    },
    address1: {
      type: String,
      default: ''
    },
    prefectureOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dirtyZipCode: this.zipCode,
      dirtyPrefecture: this.prefecture,
      dirtyAddress1: this.address1,
      zipCodeErrorMessage: '',
      currentView: VIEW_ZIP_CODE
    }
  },
  computed: {
    zipCodeStatus () {
      return this.zipCodeErrorMessage.length > 0 ? 'is-danger' : null
    },
    isZipCodeView () {
      return this.currentView === VIEW_ZIP_CODE
    },
    isAddressMatched () {
      return this.dirtyPrefecture || this.dirtyAddress1
    }
  },
  methods: {
    setZipCodeView () {
      this.setView(VIEW_ZIP_CODE)
    },
    setAddressView () {
      this.setView(VIEW_ADDRESS)
    },
    setView (view) {
      this.currentView = view
    },
    async fillAddressByZipCode (zipCode) {
      const { address, error } = await findAddressFromPostal(zipCode)
      if (address) {
        this.dirtyPrefecture = address.region
        this.dirtyAddress1 = address.locality + address.street
        this.zipCodeErrorMessage = ''
      } else {
        this.dirtyPrefecture = ''
        this.dirtyAddress1 = ''
        this.zipCodeErrorMessage = error
      }
    },
    handleZipCodeBlur ({ currentTarget }) {
      this.fillAddressByZipCode(currentTarget.value)
    },
    handleZipCodeKeypress (ev) {
      if (preventSubmitWithEnter(ev)) {
        this.fillAddressByZipCode(this.dirtyZipCode)
      }
    },
    handleAddress1Keypress (ev) {
      preventSubmitWithEnter(ev)
    }
  }
}
</script>

<style scoped lang="sass">
.sign-up-address-input-zip-code__address
  min-height: 2rem

.sign-up-address-input-address__form
  border-radius: 6px
</style>
