<template>
  <div>
    <div
      v-if="label"
      class="label"
      v-text="label"
    />
    <MapBasedSelector
      class="is-mb-3"
      :radius="radiusProps.value"
      :latitude="latitudeProps.value"
      :longitude="longitudeProps.value"
      :location="locationProps.value || ''"
      :location-label="locationProps.label"
      :mark-supporter="true"
      :mark-community="true"
      :mark-event="true"
      :mark-organization="true"
      @change="onLocationChange"
    >
      <div class="user-count-container">
        半径
        <span v-text="radius / 1000" />
        km圏内
        <span v-if="neighborUserCount > 0">
          に
          <span
            class="user-count"
            v-text="neighborUserCount"
          />
          人のユーザがいます
        </span>
        <span v-else>
          にはユーザがいません
        </span>
      </div>
    </MapBasedSelector>
    <input
      :name="locationProps.name"
      :value="location"
      type="hidden"
    >
    <input
      :name="latitudeProps.name"
      :value="lat"
      type="hidden"
    >
    <input
      :name="longitudeProps.name"
      :value="lng"
      type="hidden"
    >
    <input
      :name="radiusProps.name"
      :value="radius"
      type="hidden"
    >
  </div>
</template>

<script>
import axios from 'axios'
import MapBasedSelector from 'components/MapBasedSelector'

export default {
  components: { MapBasedSelector },
  props: {
    radiusProps: {
      type: Object,
      required: true
    },
    latitudeProps: {
      type: Object,
      required: true
    },
    longitudeProps: {
      type: Object,
      required: true
    },
    locationProps: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      radius: this.radiusProps.value,
      lat: this.latitudeProps.value,
      lng: this.longitudeProps.value,
      location: this.locationProps.value || '',
      neighborUserCount: null
    }
  },
  methods: {
    onLocationChange (ev) {
      const {
        radius,
        latitude,
        longitude,
        location
      } = ev
      this.radius = radius
      this.lat = latitude
      this.lng = longitude
      this.location = location
      this.$_reloadNeighborUserCount()
    },
    async $_reloadNeighborUserCount () {
      const params = { latitude: this.lat, longitude: this.lng, radius: this.radius }
      const { data: { users: { count } } } = await axios.get('/neighbor_users.json', { params })
      this.neighborUserCount = count
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.user-count-container
  color: $primary
  border-bottom: 1px solid $primary
  .user-count
    font-size: 1.2rem
    font-weight: bold
</style>
