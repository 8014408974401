<template>
  <section class="labeled-action-boxes">
    <div
      class="title"
      v-text="label"
    />
    <div class="boxes">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.labeled-action-boxes
  position: relative
  width: 100%
  padding: 10px
  background-color: #f4f4f4
  text-align: center
  > .title
    text-align: center
    font-size: 1.2rem
    font-weight: normal
    color: #8a8a8a
    margin-bottom: 14px
    +mobile
      font-size: 1rem

  > .boxes
    width: 100%
    display: flex
    justify-content: center
    align-items: stretch

    > .action-box
      position: static
      /deep/
        .action-box-button
          position: static

    > .action-box:first-child
      margin-right: 10px
      /deep/
        .action-box-button
          background: #5cac00

    > .action-box:last-child
      /deep/
        .action-box-button
          background: #f2796e
</style>
