<template>
  <div class="snsshare-content">
    <p
      class="snsshare-title"
      v-text="title.length > 1 ? title : 'この募集をSNSでシェアする'"
    />
    <ul class="snsshare-list">
      <li
        v-if="facebookUrl"
        class="is-facebook"
      >
        <a
          :href="facebookUrl"
          target="_blank"
        >
          <i class="fab fa-facebook" />
        </a>
      </li>
      <li
        v-if="twitterUrl"
        class="is-twitter"
      >
        <a
          :href="twitterUrl"
          target="_blank"
        >
          <i class="fab fa-twitter" />
        </a>
      </li>
      <li
        v-if="lineUrl"
        class="is-line"
      >
        <a :href="lineUrl">
          <i class="fab fa-line" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    facebookUrl: {
      type: String,
      default: null
    },
    twitterUrl: {
      type: String,
      default: null
    },
    lineUrl: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.snsshare-title
  margin: 0 0 12px
  font-size: 16px
  font-weight: bold
  line-height: 1.5

.snsshare-list
  display: flex
  justify-content: flex-start
  li
    max-width: 150px
    flex: 1
    text-align: center
    a
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 50px
      color: #fff
      line-height: 1
    i
      font-size: 26px
    &.is-facebook
      a
        background-color: #3c5a99
    &.is-twitter
      a
        background-color: #1da1f2
    &.is-line
      display: none
      +app-mobile
        display: block
      a
        background-color: #00b900

// 中央揃え
.snsshare-content
  &.is-center
    .snsshare-list
      justify-content: center
    .snsshare-title
      text-align: center
</style>
