<template>
  <div class="modal-card">
    <header class="modal-card-head">
      携帯電話の下四桁を入力
    </header>
    <section class="modal-card-body">
      <BMessage type="is-info">
        『託児のシェア友』は「親子ともによく知る仲」が前提です。確認のために、相手の携帯電話の下四桁を入力してください。わからない場合は、相手に直接聞いてくださいね
      </BMessage>
      <BInput
        v-model="phoneNumber"
        :placeholder="placeholder"
        name="auth_key"
      />
    </section>
    <footer class="modal-card-foot">
      <button
        class="button is-rounded"
        type="button"
        @click="$parent.close()"
      >
        閉じる
      </button>
      <button
        class="button is-primary is-rounded"
        type="button"
        @click="submit"
      >
        申請する
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      phoneNumber: ''
    }
  },
  computed: {
    placeholder () {
      return `${this.user.lastName}さんの電話番号の下4桁`
    }
  },
  methods: {
    submit () {
      this.$emit('submit', this.phoneNumber)
      this.$parent.close()
    }
  }
}
</script>

<style scoped lang="sass">
</style>
