<template>
  <time
    :dateTime="machineReadable"
    :title="title"
    v-text="humanReadable"
  />
</template>

<script>
import {
  parse, isSameDay, format as dateFormat, distanceInWords, eachDay
} from 'date-fns'
import jaLocale from 'date-fns/locale/ja'

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    dateTimeFormat: {
      type: String,
      default: 'YYYY/MM/DD HH:mm'
    },
    timeFormat: {
      type: String,
      default: 'HH:mm'
    },
    autoUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      now: new Date(),
      timer: null
    }
  },
  computed: {
    date () {
      return parse(this.value)
    },
    format () {
      return isSameDay(this.date, new Date()) ? this.timeFormat : this.dateTimeFormat
    },
    machineReadable () {
      return dateFormat(this.date, 'YYYY-MM-DDTHH:mm:ssZ')
    },
    humanReadable () {
      return eachDay(this.date, this.now).length < 8 ?
        distanceInWords(this.now, this.date, { locale: jaLocale })
          .replace(/ぐらい$/, '')
          .replace(/$/, '前')
          .replace(/以下前$/, '以内') :
        dateFormat(this.date, 'YYYY年M月D日')
    },
    title () {
      return dateFormat(this.date, 'YYYY-MM-DD HH:mm')
    }
  },
  mounted () {
    if (this.autoUpdate) {
      const gap = Math.random() * 10000 - 5000 // 60 秒ごとに一斉に起き上がると負荷が集中するので適当にバラけさせる
      this.timer = setInterval(this.tick.bind(this), 60000 + gap)
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    tick () {
      this.now = new Date()
    }
  }
}
</script>
