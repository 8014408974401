<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :custom-class="customClass"
  >
    <div
      class="photo-uploader-box"
      :class="{
        'is-large': isLarge,
        'is-square': isSquare,
        'is-error': (status || isGenerateError),
        'is-generated': isGenerated
      }"
    >
      <input
        :name="name"
        :value="bgImageURL"
        type="hidden"
      >
      <template v-if="!isGenerated">
        <div
          class="preview-photo-container"
          @click="onModalOpen"
        >
          <i class="photo-icon fal fa-image" />
          <div
            class="label"
            v-text="message"
          />
        </div>
      </template>
      <template v-else>
        <div
          class="resolve-photo-container"
          @click="onModalOpen"
        >
          <div
            class="resolve-photo-bgimg"
            :style="{'background-image': bgImageURL}"
            :class="{
              'is-large': isLarge,
              'is-square': isSquare,
            }"
          >
            <template v-if="withUploadIcon">
              <span class="upload-wrapper">
                <i class="upload-icon fal fa-image" />
                <span class="upload-label">
                  写真
                </span>
              </span>
            </template>
          </div>
        </div>
      </template>
      <BModal
        :active.sync="isModalActive"
        @close="onImageRemove"
      >
        <div class="croppa-modal-container">
          <!-- Croppaコンポーネント：<croppa></croppa>でないと作動しない -->
          <!-- eslint-disable vue/html-self-closing,vue/component-name-in-template-casing -->
          <croppa
            v-model="myCroppa"
            canvas-color="transparent"
            placeholder
            auto-sizing
            :accept="'image/png,image/jpeg'"
            :show-remove-button="false"
            @new-image-drawn="onNewImage"
            @file-choose="onFileChoosed"
            @image-remove="onImageRemove"
          ></croppa>
          <!-- eslint-enable -->
          <div
            class="croppa-modal-message"
            :class="{ hidden: isPhotoSelected}"
          >
            <template v-if="isDrawing">
              <i class="photo-icon fal fa-spinner fa-spin" />
              <div class="label">
                読み込み中
              </div>
            </template>
            <template v-else>
              <i class="photo-icon fal fa-image" />
              <div class="label">
                写真を選択
              </div>
            </template>
          </div>
          <div class="croppa-modal-circle">
            <!-- SVGでエラーがでるため追記 -->
            <!-- eslint-disable -->
            <svg
              v-if="!isSquare"
              xmlns="http://www.w3.org/2000/svg"
              width="500"
              height="500"
              viewBox="0 0 500 500"
            >
              <path
                d="M0 0v500h500V0H0zm250 490C117.45 490 10 382.55 10 250S117.45 10 250 10s240 107.45 240 240-107.45 240-240 240z"
              ></path>
            </svg>
            <!-- eslint-enable -->
          </div>
          <div class="croppa-modal-btn-outer">
            <div
              class="button is-primary is-rounded is-outlined croppa-modal-btn"
              :class="{active: isPhotoChose}"
              @click="onFileChoose"
            >
              写真を変更する
            </div>
            <div
              class="button is-primary is-rounded croppa-modal-btn"
              :class="{ active: isPhotoChose}"
              @click="generateImage"
            >
              この写真を使う
            </div>
          </div>
        </div>
      </BModal>
    </div>
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    isMarginless: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    isSquare: {
      type: Boolean,
      default: false
    },
    withUploadIcon: {
      type: Boolean,
      default: false
    },
    isGeneratedImage: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: null
    }
  },
  data () {
    const url = this.value ? `url(${this.value})` : 'none'
    return {
      bgImageURL: url,
      message: '写真を選択',
      isPhotoChose: false,
      isPhotoSelected: false,
      isModalActive: false,
      isGenerated: !!this.value,
      isGenerateError: false,
      isDrawing: false,
      myCroppa: null,
      displayErrors: this.errors
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    errorMessage () {
      return this.displayErrors
    }
  },
  methods: {
    generateImage () {
      const generateImgUrl = this.myCroppa.generateDataUrl('image/jpeg')
      this.isPhotoChose = false
      this.isModalActive = false
      this.myCroppa.remove()
      if (!generateImgUrl || !this.isGeneratedImage) {
        // GeneratedImage失敗
        this.isGenerate = false
        this.isGenerateError = true
        this.message = '失敗！もう一度選択'
        this.bgImageURL = 'none'
        this.isPhotoSelected = false
      } else {
        // GeneratedImage成功
        this.isGenerated = true
        this.isGenerateError = false
        this.bgImageURL = `url(${generateImgUrl})`
      }
    },
    onNewImage () {
      // 画像読み込み完了
      if (this.myCroppa.orientation === 6) {
        this.myCroppa.rotate(-1);
      }
      this.isPhotoSelected = true
      this.isPhotoChose = true
      this.isDrawing = false
    },
    onImageRemove () {
      // 画像取消時
      this.isPhotoSelected = false
      this.isPhotoChose = false
    },
    onFileChoose () {
      this.myCroppa.remove()
      this.myCroppa.chooseFile()
    },
    onFileChoosed () {
      // 画像選択時
      this.isDrawing = true
    },
    onModalOpen () {
      this.isModalActive = true
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

/deep/
  @import '~stylesheets/components/required-label'

$error-color: #ff3860

// 写真の角丸・丸の設定
=radius()
  $pc: 140px
  $sp: 20vw
  $pc-l: 180px
  $sp-l: 30vw
  $square: 6px

  width: $pc
  height: $pc
  border-radius: $pc / 2
  +app-mobile
    width: $sp
    height: $sp
    border-radius: $sp / 2
  &.is-large
    width: $pc-l
    height: $pc-l
    border-radius: $pc-l / 2
    +app-mobile
      width: $sp-l
      height: $sp-l
      border-radius: $sp-l / 2
  &.is-square
    border-radius: $square
    +app-mobile
      border-radius: $square
    &.is-large
      border-radius: $square
      +app-mobile
        border-radius: $square

.modal-content
  position: static

.croppa-container
  display: block
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, calc(-50% - 33px))
  width: 50vh
  height: 50vh
  margin: 0 auto
  overflow: hidden

  canvas
    display: block
    width: 100%
    height: 100%

.croppa-modal-container
  position: static
  width: 100%
  height: 100%
  min-height: calc(50vh + 66px)

.croppa-modal-circle
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, calc(-50% - 33px))
  width: 50vh
  height: 50vh
  pointer-events: none

  svg
    opacity: 0.2
    width: 100%
    height: 100%

.croppa-modal-message
  position: absolute
  text-align: center
  top: 50%
  left: 50%
  transform: translate(-50%, calc(-50% - 33px))
  pointer-events: none

  &.hidden
    display: none

.croppa-modal-btn
  display: none

  &.button
    +app-mobile
      font-size: 14px
    &.is-primary
      &.is-outlined
        background-color: #fff
        &:hover
          background-color: rgba(#fff,.9)
          color: #ff7b00

  &.active
    display: inline-block
    margin-top: 30px
    margin-left: 6px
    margin-right: 6px

.croppa-modal-btn-outer
  display: block
  position: absolute
  width: 100%
  height: 66px
  bottom: 0
  left: 0
  text-align: center

// IE11対応
@media all and (-ms-high-contrast: none)
  *::-ms-backdrop,
  .croppa-container
    transform: translate(-50%, -55%)

  *::-ms-backdrop,
  .croppa-modal-circle
    transform: translate(-50%, -55%)

  *::-ms-backdrop,
  .croppa-modal-message
    transform: translate(-50%, -55%)

  *::-ms-backdrop,
  .croppa-modal-btnOuter
    bottom: -10px

.photo-uploader-box
  display: block
  position: relative
  background-color: rgb(238, 238, 238)
  border: 2px dotted rgb(215, 215, 215)
  border-radius: 10vw
  cursor: pointer
  transition: 0.2s
  width: 20vw
  height: 20vw
  border-radius: 10vw
  +radius
  &.is-error
    border-color: $error-color
    .photo-icon,
    .label
      color: $error-color
  &.is-generated
    border: none
    &:hover
      border: none
  &:hover
    background-color: darken(rgb(238, 238, 238), 10%)
    border: 2px dotted darken(rgb(215, 215, 215), 20%)

    .photo-icon,
    .label
      color: white

.resolve-photo-container
  position: relative
  width: 100%
  height: 100%
  &:hover
    transition: 0.2s
    filter: brightness(0.8)

.resolve-photo-bgimg
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  position: absolute
  top: 0
  left: 0
  transition: 0.3s
  +radius

// アイコンやメッセージ
.photo-icon
  font-size: 30px
  color: #777
  transition: 0.2s

.label
  color: #777
  transition: 0.2s
  font-size: 0.9rem
  line-height: 1.2

.preview-photo-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  position: relative
  height: 100%
  +app-mobile
    .photo-icon
      font-size: 18px
    .label
      font-size: 10px

.upload-wrapper
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  color: white
  height: 140px
  +app-mobile
    height: 70px

  &::after
    position: absolute
    z-index: 1
    top: 0
    left: 0
    width: 100%
    height: 100px
    display: block
    content: ''
    opacity: 0.05
    transition: opacity 0.1s

  &:hover
    &::after
      opacity: 0
  &:active
    &::after
      opacity: 0.2

.upload-label
  font-size: 12px
  text-shadow: 0 1px 1px rgb(104, 85, 67)
  font-weight: 600
  position: relative
  z-index: 2

.upload-icon
  font-size: 20px
  text-shadow: 0 1px 1px rgb(104, 85, 67)
  position: relative
  z-index: 2

// NOTE: iOS アプリ (UIWebView) でファイル選択が効かないことへの対処。
// 画像が選択されていない間だけ、input[type=file] を canvas より上に配置し、
// ユーザーによる操作でクリックさせるようにする。画像が選択されたあとは、
// canvas のドラッグが効くように、元のサイズ/場所に戻す
.croppa-container:not(.croppa--has-target)
  /deep/
    > input[type=file]
      height: 100% !important
      width: 100% !important
      margin-left: 0 !important
      position: static !important
      display: block
      opacity: 0
      overflow: unset !important
</style>
