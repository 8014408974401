<template>
  <div
    :class="wrapperClass"
  >
    <input
      :name="name"
      type="hidden"
      value="0"
    >
    <BCheckbox
      v-model="checked"
      :name="name"
      native-value="1"
      :disabled="disabled"
      @input="onChange"
      :class="{ 'is-border-none': borderCheckBox }"
    >
      <span v-text="label" />
    </BCheckbox>
    <BField
      :message="errors"
      :type="status"
    />
  </div>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    useBackground: {
      type: Boolean,
      default: false
    },
    borderCheckBox: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { checked: this.value }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    wrapperClass () {
      return {
        'use-background': this.useBackground
      }
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  .b-checkbox.checkbox
    align-items: flex-start
  .is-border-none
    span.check
      border-radius: 0px !important
      border: 1px solid #7a7a7a !important
      width: 1.0em !important
      height: 1.0em !important
.use-background
  display: flex
  padding: 5px
  background: rgba(255, 255, 255, 0.7)
  opacity: 0.9
  justify-content: center
</style>
