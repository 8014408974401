import { getMinutes, addMinutes } from 'date-fns'

export function idForName (name) {
  // 一意になればいいので適当に決める
  return name.replace(/\[/g, '_').replace(/]/g, '_')
}

export function status (errors, isInputted = false) {
  return isInputted ? 'is-success' : (errors.length > 0 ? 'is-danger' : null)
}

export function customClass ({ isRequired, isPublished = false, isHidden = false, isProtected = false }) {
  let result = isRequired ? 'required-label ' : ''
  result += isPublished ? 'published-label ' : ''
  result += isHidden ? 'hidden-label ' : ''
  result += isProtected ? 'protected-label ' : ''
  return result
}

export function normalizeTime (date, selectableMinutesDuration) {
  const min = getMinutes(date)
  const r = min % selectableMinutesDuration
  if (r > 0) {
    return addMinutes(date, selectableMinutesDuration - r)
  }
  return date
}
