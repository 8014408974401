<template>
  <div class="topic-comment">
    <TopicHeader v-bind="topicHeaderProps" v-if="viewMode">
      <p v-if="canUpdateComment" class="dropdown-item item-hover" @click="toggleViewEdit()">編集</p>
      <slot name="option-menu" />
    </TopicHeader>
    <div class="main-content" v-if="viewMode">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-if="showTruncatedBody"
        class="body"
        v-html="truncatedBody"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-else
        class="body"
        v-html="mentionDeletedBody"
      />
      <image-modal
        v-if="hasImage"
        class="image"
        :src="image.url"
        :original-src="image.original"
      />
    </div>
    <TopicForm v-else v-bind="editTopicCommentFormProps" />
    <div
      v-if="needReadMore && viewMode"
      class="read-more"
    >
      <a
        href="javascript:void(0)"
        @click="toggleBody"
      >
        <span v-text="readMoreText" />
      </a>
    </div>
  </div>
</template>

<script>
import TopicHeader from 'components/TopicHeader'

export default {
  components: { TopicHeader },
  props: {
    writer: {
      type: Object,
      required: true
    },
    shortBody: {
      type: String,
      required: true
    },
    mediumBody: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    image: {
      type: Object,
      default: null
    },
    createdAt: {
      type: String,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    },
    useNickname: {
      type: Boolean,
      required: true
    },
    canUpdateComment: {
      type: Boolean,
      default: false
    },
    editTopicCommentProps: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showTruncatedBody: true,
      viewMode: true
    }
  },
  computed: {
    topicHeaderProps () {
      return { ...this.writer, createdAt: this.createdAt, useNickname: this.useNickname }
    },
    editTopicCommentFormProps () {
      return {
        ...this.editTopicCommentProps,
        toggleViewEdit: this.toggleViewEdit,
      }
    },
    hasImage () {
      return !!this.image
    },
    // マイコミュでは"[[@<返信先ユーザ名>]]"をメンション機能的に本文先頭に付与する動作がある。
    // 子育てシェアからマイコミュへの移行期において、マイコミュからの返信を子育てシェアで見た時に
    // このメンション表記が邪魔なので除外する。
    mentionDeletedBody () {
      return this.body.replace(/^\[\[@.+\]\] /, '')
    },
    mentionDeletedShortBody () {
      return this.shortBody.replace(/^\[\[@.+\]\] /, '')
    },
    mentionDeletedMediumBody () {
      return this.mediumBody.replace(/^\[\[@.+\]\] /, '')
    },
    truncatedBody () {
      return this.short ? this.mentionDeletedShortBody : this.mentionDeletedMediumBody
    },
    readMoreText () {
      return this.showTruncatedBody ? '続きを読む' : '隠す'
    },
    needReadMore () {
      return this.truncatedBody !== this.mentionDeletedBody
    },
  },
  methods: {
    toggleBody () {
      this.showTruncatedBody = !this.showTruncatedBody
    },
    toggleViewEdit () {
      this.viewMode = !this.viewMode
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.main-content
  display: flex
  margin-top: 6px
  .body
    word-break: break-word
    padding-left: 40px
    font-size: 12px
    flex: 1
  .edit-body
    border-radius: 3px
    border: 1px solid #d7d7d7
    padding: 6px 12px
    resize: none
    font-size: 14px
    width: 100%
  .image
    width: 110px
    height: 110px
    +app-mobile
      width: 55px
      height: 55px
.read-more
  margin-top: 8px
  padding-left: 40px
</style>
