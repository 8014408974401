export function confirmSharingCandidateEmpty (event) {
  const select = event.target.querySelector('.sharing-candidate-select-input')
  if (select) {
    const selected = select.querySelectorAll('input.sharing-candidate-selected')
    if (selected.length === 0 && !window.confirm('発信先のシェア友が選択されていません。このまま発信しますか？')) {
      event.stopPropagation() // 「送信中」にならないように
      event.preventDefault()
      return false
    }
  }
  return true
}

export default confirmSharingCandidateEmpty
