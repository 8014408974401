<template>
  <div class="the-notification">
    <b-dropdown
      position="is-bottom-left"
      :mobile-modal="false"
    >
      <a
        slot="trigger"
        href="javascript:void(0)"
        class="notification-toggler"
        :class="{ unopen: hasUnopen }"
      >
        <i class="icon fal fa-bell" />
        <span
          class="nav-label"
          v-text="label"
        />
      </a>

      <b-dropdown-item
        custom
        paddingless
      >
        <div class="notifications-wrapper">
          <div class="all-notifications-opener-wrapper">
            <span class="notification-title">
              お知らせ
            </span>
            <a
              class="notification-open-all"
              @click="openAll"
            >
              すべて既読にする
            </a>
          </div>
          <notifications :notifications="notifications" />
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Notifications from 'components/Notifications'

export default {
  components: { Notifications },
  props: {
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('notification', ['notifications']),
    hasUnopen () {
      return this.notifications.some(_ => _.openedAt === null)
    }
  },
  created () {
    this.fetchNotifications()
  },
  methods: {
    ...mapActions('notification', ['fetchNotifications', 'openAll'])
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
+app-mobile
  /deep/ .dropdown.is-bottom-left .dropdown-menu
    position: fixed
    top: 46px
    left: 10px
    width: calc(100vw - 20px)
    height: calc(100vh - 60px - 80px)
    .all-notifications-opener-wrapper
      padding: 0 10px
    .notifications-wrapper
      width: auto
    .notifications
      height: calc(100vh - 60px - 80px - 40px)
.the-notification
  /deep/ .dropdown.is-bottom-left .dropdown-menu
    height: calc(100vh - 60px - 80px)
    z-index: 101
    box-shadow: 0 0 12px #ccc
    border: 1px solid #ccc
    overflow: hidden
    border-radius: 6px
    padding-top: 0
    .dropdown-content
      padding-top: 0
      padding-bottom: 0
      box-shadow: none
    .notifications
      height: calc(100vh - 60px - 80px - 40px)
      width: 100%
      overflow: auto
      -webkit-overflow-scrolling: touch

  .notification-title
    font-weight: bold
    line-height: 1
  .notification-open-all
    color: $primary
    line-height: 1
  .notification-toggler
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: $color-headericon
    padding: 10px 20px
    color: #333
    text-align: center
    +app-mobile
      color: $color-headericon
    &:hover
      background-color: #f9f9f9
      border-radius: 4px
    > .nav-label
      font-size: 9px
    &.unopen
      &::before
        display: inline-block
        position: absolute
        top: 7px
        right: 28px
        content: ''
        width: 8px
        height: 8px
        background: #ec6b00
        border: 2px solid #fff
        border-radius: 10px
        box-sizing: content-box
        +app-mobile
          right: 8px
          top: 2px
  .icon
    font-size: 22px
  b-dropdown-item
    width: 400px
  .notifications-wrapper
    width: 400px
  .all-notifications-opener-wrapper
    border-bottom: 1px solid #ccc
    height: 40px
    display: flex
    align-items: center
    justify-content: space-between
    overflow: hidden
    padding: 0 20px
  +app-mobile
    .icon
      font-size: 21px
      width: auto
      height: auto
    .notification-toggler
      padding: 7px 14px
      .nav-label
        display: none
</style>
