<template>
  <div class="block">
    <label
      class="label"
      v-text="label"
    />
    <div
      v-for="child in collection"
      :key="child.id"
      class="child"
    >
      <BCheckbox
        v-model="selectedChildren"
        :name="name"
        :native-value="child.id"
        :title="child.firstName"
      >
        <FaceIcon
          :src="child.src"
          :class="{'is-checked': selectedChildren.indexOf(child.id) >= 0}"
        />
      </BCheckbox>
      <div
        class="child-name"
        v-text="child.firstNameWithHonorific"
      />
    </div>
    <BField
      :message="errors"
      type="is-danger"
      class="is-mt-3"
    />
  </div>
</template>

<script>
export default {
  props: {
    collection: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedChildren: this.value,
      checked: []
    }
  }
}
</script>

<style scoped lang="sass">
.child
  display: inline-flex
  flex-direction: column
  align-items: center
  height: 64px
  position: relative

  label.b-checkbox.checkbox
    width: 75px

    /deep/ .check
      position: absolute
      right: 6px
      bottom: 4px
      border-radius: 0.625em
      background-color: #fff
      z-index: 1

    /deep/ .control-label img
      opacity: 0.5

    /deep/ .control-label .is-checked img
      opacity: 1
  .child-name
    text-align: center
    font-size: 0.75rem
</style>
