<template>
  <div>
    <BaseCheckboxInput
      v-bind="showAgeConditionsProps"
      :on-change="handleChangeShowAgeRange"
    />
    <div
      v-show="isShowAgeRange"
      class="date-of-birth-range-input"
    >
      <div>
        <BirthdayPicker v-bind="dateOfBirthFromProps" />
      </div>
      <div class="is-px-3">
        〜
      </div>
      <div>
        <BirthdayPicker v-bind="dateOfBirthToProps" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckboxInput from 'components/BaseCheckboxInput.vue'
import BirthdayPicker from 'components/BirthdayPicker.vue'

export default {
  components: { BaseCheckboxInput, BirthdayPicker },
  props: {
    showAgeConditionsProps: {
      type: Object,
      required: true
    },
    dateOfBirthFromProps: {
      type: Object,
      required: true
    },
    dateOfBirthToProps: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShowAgeRange: this.showAgeConditionsProps.value
    }
  },
  methods: {
    handleChangeShowAgeRange (value) {
      this.isShowAgeRange = value
    }
  }
}
</script>

<style scoped lang="sass">
.date-of-birth-range-input
  display: flex
  align-items: center
</style>
