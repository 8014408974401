<template>
  <h4
    :style="{textAlign: textAlign}"
    class="header-content"
  >
    <slot />
  </h4>
</template>

<script>
export default {
  props: {
    textAlign: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.header-content
  padding: 1em 0
  font-weight: bold
  font-size: 1.0rem
  +mobile
    +header-fs
</style>
