<template>
  <a
    :href="href"
    class="button is-primary is-square"
    @click="onClick"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: 'javascript:void(0)'
    }
  },
  methods: {
    onClick (ev) {
      this.$emit('click', ev)
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
.button.is-square
  max-width: 400px
  width: 100%
  border-radius: 0
  font-size: 18px
  font-weight: bold
  line-height: 1
  padding: 16px
  &[disabled]
    background-color: #9b9b9b
</style>
