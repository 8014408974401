import YubinBango from 'yubinbango-core'

const REGION_SELECTOR = '.js-yubinbango-region input, .js-yubinbango-region select'
const ADDRESS_SELECTOR = '.js-yubinbango-address input'

// 値を自動でセットした場合にchangeイベントを発火させるため
const event = new Event('change', { bubbles: true })
event.simulated = true

const postalCode2Address = postalCode => new Promise(
  (resolve, reject) => new YubinBango.Core(postalCode, (addr) => {
    if (addr.region_id === '') {
      return reject(new Error('no match'))
    }
    return resolve(addr)
  })
)

export async function findAddressFromPostal (postalCode) {
  if (!postalCode.match(/^\d{3}-?\d{4}$/)) {
    return { error: '郵便番号を正しく入力してください。' }
  }

  let errorMessage = null
  const address = await postalCode2Address(postalCode).catch(() => {
    errorMessage = '該当する郵便番号が見つかりませんでした。'
  })
  if (address) {
    return { address }
  }

  return { error: errorMessage || '該当する郵便番号が見つかりませんでした。' }
}

const inputAddressFromPostal = async (postalCode) => {
  const { address, error } = await findAddressFromPostal(postalCode)
  if (address) {
    const inputRegion = document.querySelector(REGION_SELECTOR)
    inputRegion.value = address.region
    inputRegion.dispatchEvent(event)
    const inputAddress = document.querySelector(ADDRESS_SELECTOR)
    inputAddress.value = address.locality + address.street
    inputAddress.dispatchEvent(event)
  }
  return error || null
}

export default (element) => {
  const postalCode = element.value
  return inputAddressFromPostal(postalCode)
}
