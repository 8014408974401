<template>
  <div :class="formClass">
    <slot
      :onReplyTypeChanged="onReplyTypeChanged"
      :onNeedTimeArrangementChanged="onNeedTimeArrangementChanged"
    />
  </div>
</template>

<script>
export default {
  props: {
    initialReplyType: {
      type: String,
      required: true
    },
    initialNeedTimeArrangement: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      replyType: this.initialReplyType,
      needTimeArrangement: this.initialNeedTimeArrangement
    }
  },
  computed: {
    formClass () {
      const classes = [`staff-candidate-${this.replyType}`]
      if (this.needTimeArrangement) {
        classes.push('need-time-arrangement')
      }
      return classes
    }
  },
  methods: {
    onReplyTypeChanged (value) {
      this.replyType = value
    },
    onNeedTimeArrangementChanged (value) {
      this.needTimeArrangement = value
    }
  }
}
</script>

<style scoped lang="sass">
.acceptance, .conditional, .conditional-time
  display: none

.staff-candidate-acceptance
  .acceptance
    display: block

.staff-candidate-conditionally_acceptance
  .conditional
    display: block

.staff-candidate-conditionally_acceptance.need-time-arrangement
  .conditional-time
    display: block
</style>
