<template>
  <div>
    <a
      class="history-action"
      :class="classObject"
      @click.prevent="isModalActive = true"
    >
      <h3
        class="origtitle"
        v-text="title"
      />
      <h4
        class="origsubtitle"
        v-text="subTitle"
      />
      <!-- ここはXSSのリスクない -->
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="description"
        v-html="description"
      />
      <div class="boxes">
        <slot />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    isSos: {
      type: Boolean,
      default: false
    },
    isSupport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalActive: false
    }
  },
  computed: {
    classObject () {
      return {
        'is-sos': this.isSos,
        'is-support': this.isSupport
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.history-action
  display: block
  text-align: center
  padding: 3rem 1rem

  &.is-sos
    background-image: url("../images/histories/history_bg_sends.jpg")

  &.is-support
    background-image: url("../images/histories/history_bg_receives.jpg")

  > * ~ *
    margin-top: 1rem

  > .origtitle
    font-size: 2rem
    font-weight: 700

  > .origsubtitle
    font-size: 1.375rem
    font-weight: 700

  > .button
    white-space: unset
    height: unset

  > .boxes
    width: 100%
    display: flex
    justify-content: center
    align-items: stretch

    > .action-box
      position: static
      /deep/
        .action-box-button
          position: static

    > .action-box:first-child
      margin-right: 10px

.modal-card-title
  text-align: center
  font-size: 16px

.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  padding: 0

  .item
    border-top: 1px solid #ddd
    font-size: 20px
    line-height: 30px
    font-weight: bold
    padding: 20px 40px
    display: flex
    -webkit-align-items: center
    align-items: center

    &:first-child
      border-top: 0

    > i
      font-size: 32px
      &:nth-child(2)
        font-size: 22px
        margin-left: 6px
        width: 30px
</style>
