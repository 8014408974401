<template>
  <div
    :class="{ 'is-border-white': faceIcon.isBorderWhite, 'is-multiple': multiple }"
    class="face-icon"
  >
    <img
      v-for="(face, key) in zMappedFaces"
      :key="key"
      :src="face.src"
      :style="{ 'z-index': face['z-index'] }"
      class="icon"
    >
    <FaceIcon
      v-if="faceIcon.faceIcon"
      v-bind="faceIcon"
      class="is-nested"
    />
    <p class="name">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'FaceIcon',
  props: {
    faceIcon: {
      type: Object,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    zMappedFaces () {
      const { faces } = this.faceIcon
      if (faces && faces.length > 0 && !this.multiple) {
        return [...Array(faces.length).keys()].reverse().map((value, index) => {
          faces[index]['z-index'] = value
          return faces[index]
        })
      }
      return faces
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.face-icon
  display: inline-block
  position: relative
  text-align: center
  font-size: 1rem

  +app-mobile
    font-size: 3vw
  &.is-mobile-same
    +app-mobile
      font-size: 1rem

  > .icon
    background-color: #fff
    border-radius: 50%
    border: 1px solid transparent
    position: relative
    margin-left: -10%
    height: 5.5em
    width: 5.5em

    &:first-child
      margin-left: 0

  &.is-small
    > .icon
      height: 3.5em
      width: 3.5em

  &.is-x-small
    > .icon
      height: 1.6em
      width: 1.6em

  &.is-nested
    > .icon
      height: 2.5em
      width: 2.5em

  &.is-border-white
    > .icon
      border-color: #fff

  > .face-icon
    position: absolute
    right: -2em
    bottom: 0
  &.is-multiple
    border-radius: 50%
    overflow: hidden
    position: relative
    height: 3.5em
    width: 3.5em
    &::before, &::after
      position: absolute
      content: ""
      display: block
      width: 1px
      height: 100%
      top: 0
      left: 50%
      background-color: #fff
      z-index: 4
    &::after
      width: 100%
      height: 1px
      top: 50%
      left: 50%
      z-index: 5
      width: 50%
    .icon
      position: absolute
      top: 0
      left: -25%
      border-radius: 0
      z-index: 1
      border: none
      width: 100%
      height: 100%
      &:nth-child(2)
        top: 0%
        right: 0%
        left: initial
        z-index: 2
        width: 50%
        height: 50%
      &:nth-child(3)
        z-index: 3
        top: 50%
        left: initial
        right: 0
        width: 50%
        height: 50%
    &.is-x-small
      height: 1.6em
      width: 1.6em

</style>
