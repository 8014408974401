<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.table
  width: 100%
  >tbody >tr >th
    width: 30%
    background: hsla(0,0%,95%,.3)
+app-mobile
  .table.is-th-sp-auto
    >tbody >tr >th
      width: auto
.table.is-bordered
  .table
    th,td
      border: none
      border-bottom: $table-cell-border
    tr:first-child
      th,td
        +app-mobile
          padding-top: 0
    tr:last-child
      th,td
        border-top: 2px solid #eee
        border-bottom: none
</style>
