<template>
  <div>
    <slot
      :visible="visible"
      :onBreak="onBreak"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      deleted: []
    }
  },
  computed: {
    visible () {
      return userId => this.deleted.indexOf(userId) === -1
    }
  },
  methods: {
    onBreak (userId) {
      this.deleted.push(userId)
    }
  }
}
</script>

<style scoped lang="sass">
</style>
