<template>
  <ul>
    <li
      v-for="nav in navPaths"
      :key="nav.path"
      class="action-box__list-item"
      :class="{ 'is-helper': isHelper, 'is-helpee': isHelpee }"
    >
      <a :href="nav.path">
        <i
          v-for="(icon, index) in nav.icons"
          :key="index"
          :class="`fal fa-${icon}`"
        />
        <div class="action-box__link">
          <span v-text="nav.label" />
          <p
            v-if="nav.hint"
            class="has-text-grey"
            v-text="nav.hint"
          />
        </div>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    navPaths: {
      type: Array,
      required: true
    },
    actionType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isHelpee () {
      return this.actionType === 'helpee'
    },
    isHelper () {
      return this.actionType === 'helper'
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.action-box__list-item
  border-top: 1px solid #ddd
  &:first-child
    border-top: 0
  &.is-helpee
    i
      color: #5cac00
  &.is-helper
    i
      color: #f2796e
  a
    display: flex
    font-weight: bold
    padding: 15px 40px
    align-items: center
    i
      font-size: 32px
      text-align: left
      &:nth-child(2)
        font-size: 22px
        margin-left: 6px
        width: 30px
    .action-box__link
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-left: 8px
      line-height: 1.3
      p
        font-weight: normal
        font-size: 0.8em
</style>
