export default {
  props: {
    formInputPropItems: {
      type: Array
    },
    defaultFormInputProps: {
      type: Object
    },
    addLabel: {
      type: String
    },
    removeLabel: {
      type: String
    },
    objectName: {
      type: String
    },
    associationName: {
      type: String
    }
  },
  data () {
    return {
      items: this.formInputPropItems,
      defaultItem: this.defaultFormInputProps,
      destroyIds: []
    }
  },
  created () {
    this.$_counter = 0
    this.items.forEach((item) => {
      item._key = this.$_newKey()
    })
  },
  methods: {
    add () {
      const newItem = Object.assign({}, this.defaultItem)
      newItem._key = this.$_newKey()
      this.items.push(newItem)
    },
    remove (index) {
      const removeItem = this.items.splice(index, 1)[0]
      if (removeItem.id) {
        this.destroyIds.push(removeItem.id.value)
      }
    },
    nestedItemId (index) {
      return `${this.objectName}-${this.associationName}-${index}`
    },
    nestedInputProps (inputProps, index) {
      const name = this.nestedFieldName(inputProps.attribute, index)
      return Object.assign({}, inputProps, { name })
    },
    nestedArrayInputProps (inputProps, index) {
      const name = this.nestedArrayFieldName(inputProps.attribute, index)
      return Object.assign({}, inputProps, { name })
    },
    nestedFieldName (attribute, index) {
      // prettier-ignore
      return `${this.objectName}[${this.associationName}_attributes][${index}][${attribute}]`
    },
    nestedArrayFieldName (attribute, index) {
      // prettier-ignore
      return `${this.nestedFieldName(attribute, index)}[]`
    },
    $_newKey () {
      return this.$_counter++
    }
  }
}
