<template>
  <div>
    <slot
      :onChangeBankType="onChangeBankType"
      :isYucho="isYucho"
    />
  </div>
</template>

<script>
export default {
  props: {
    defaultBankType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      bankType: this.defaultBankType
    }
  },
  computed: {
    isYucho () {
      return this.bankType === 'post'
    }
  },
  methods: {
    onChangeBankType (value) {
      this.bankType = value
    }
  }
}
</script>
