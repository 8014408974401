import axios from 'axios'
import createChannel from 'client/cable'
import { showError } from 'lib/app-toast'

function showChatMessageCreateError (error) {
  if (!error.response) {
    showError(error.message)
    return
  }

  const { response: { data } } = error
  if (data && data.error_messages) {
    data.error_messages.forEach(msg => showError(msg))
  } else {
    showError(error.message)
  }
}

/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    currentChat: null
  },
  getters: {},
  mutations: {
    setChannel (state) {
      state.channel = createChannel(
        {
          channel: 'ChatChannel',
          chat_id: state.currentChat.id
        },
        {
          received: (data) => {
            switch (data.action) {
              case 'create_chat_message':
                state.currentChat.chatMessages.push(data.chat_message)
                break
              default:
                break
            }
          }
        }
      )
    },
    setCurrentChat (state, chat) {
      state.currentChat = chat
    }
  },
  actions: {
    initChat ({ commit }, chat) {
      commit('setCurrentChat', chat)
      commit('setChannel')
    },
    createChatMessage ({ state }, content) {
      const chatMessageParam = { chat_message: { content } }
      axios.post(
        `/api/v1/chat/${state.currentChat.id}/chat_messages`,
        chatMessageParam
      ).catch(e => showChatMessageCreateError(e))
    },
    createImageChatMessage ({ state: { currentChat } }, image) {
      const data = new FormData()
      data.append('chat_message[content]', '')
      data.append('chat_message[image]', image)
      axios.post(
        `/api/v1/chat/${currentChat.id}/chat_messages`,
        data
      )
    }
  }
}
export default store
