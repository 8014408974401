<template>
  <div>
    <Map2
      class="is-mb-3"
      :radius="selectedRadius"
      :lat="lat"
      :lng="lng"
      :mark-supporter="markSupporter"
      :mark-community="markCommunity"
      :mark-event="markEvent"
      :mark-organization="markOrganization"
    />
    <BField
      :label="locationLabel"
      label-for="_map_based_selector_location"
    >
      <input
        id="_map_based_selector_location"
        ref="locationInput"
        class="input"
        @blur="handleBlur"
      >
    </BField>
    <p>発信範囲を選択してください。</p>
    <div class="buttons are-small">
      <button
        v-for="rad in [2000, 5000, 10000, 20000]"
        :key="rad"
        type="button"
        :class="radiusButtonClass(rad)"
        :data-radius="rad"
        @click.prevent="$_onClickRadius"
      >
        <span v-text="rad / 1000" />km
      </button>
    </div>
    <slot />
  </div>
</template>

<script>
import GoogleMapsLoader from 'google-maps'
import Map2 from 'components/Map2'

export default {
  components: { Map2 },
  props: {
    radius: {
      type: Number,
      default: 2000
    },
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    },
    location: {
      type: String,
      required: true
    },
    markSupporter: {
      type: Boolean,
      default: false
    },
    markCommunity: {
      type: Boolean,
      default: false
    },
    markEvent: {
      type: Boolean,
      default: false
    },
    markOrganization: {
      type: Boolean,
      default: false
    },
    locationLabel: {
      type: String,
      default: '場所'
    }
  },
  data () {
    return {
      selectedRadius: this.radius,
      lat: this.latitude,
      lng: this.longitude,
      inputLocation: this.location
    }
  },
  computed: {
    locationInput () {
      return this.$refs.locationInput
    },
    radiusButtonClass () {
      return value => ({
        button: true,
        'is-info': this.selectedRadius === value
      })
    }
  },
  mounted () {
    this.locationInput.value = this.inputLocation
    GoogleMapsLoader.load(this.$_loadMap)
  },
  methods: {
    handleBlur () {
      GoogleMapsLoader.load((google) => {
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode(
          {
            address: this.locationInput.value,
            region: 'jp'
          },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const { geometry: { location } } = results[0]
              this.inputLocation = this.locationInput.value
              this.lat = location.lat()
              this.lng = location.lng()
              this.$_fireChange()
            }
          }
        )
      })
    },
    $_loadMap (google) {
      /* eslint-disable no-new */
      new google.maps.places.Autocomplete(this.locationInput)
      this.$_fireChange()
    },
    $_onClickRadius (ev) {
      this.selectedRadius = parseInt(ev.currentTarget.dataset.radius, 10)
      this.$_fireChange()
    },
    $_fireChange () {
      this.$emit('change', {
        radius: this.selectedRadius,
        latitude: this.lat,
        longitude: this.lng,
        location: this.inputLocation
      })
    }
  }
}
</script>

<style scoped lang="sass">
</style>
