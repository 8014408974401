<template>
  <div>
    <slot
      :onChangeZipCode="onChangeZipCode"
      :onSubmit="onSubmit"
      :onLeaveOpen="onLeaveOpen"
      :onLeaveClose="onLeaveClose"
      :showLeaveForm="showLeaveForm"
    />
  </div>
</template>

<script>
import * as AutoKana from 'vanilla-autokana'
import autoInputAddress from 'lib/auto_input_address'
import autoInputLatlng from 'lib/auto_input_latlng'
import * as inputs from './inputs'

function isElementExists (elementId) {
  return !!document.getElementById(elementId)
}

export default {
  data () {
    return {
      showLeaveForm: false
    }
  },
  mounted () {
    this.bindAutoKana('profile[last_name]', 'profile[last_name_kana]')
    this.bindAutoKana('profile[first_name]', 'profile[first_name_kana]')
  },
  methods: {
    bindAutoKana (name, kana) {
      const nameId = inputs.idForName(name)
      const kanaId = inputs.idForName(kana)
      if (isElementExists(nameId) && isElementExists(kanaId)) {
        AutoKana.bind(nameId, kanaId, { katakana: true })
      }
    },
    onChangeZipCode (e) {
      const result = autoInputAddress(e.currentTarget)
      return result
    },
    async onSubmit () {
      await autoInputLatlng()
      this.$el.querySelector('form').submit()
    },
    onLeaveOpen () {
      this.showLeaveForm = true
    },
    onLeaveClose () {
      this.showLeaveForm = false
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  @import '~stylesheets/components/published-label'
.the-profile-form__sub-title
  font-size: 1.2rem
  font-weight: bold
  margin-bottom: 10px
.the-profile-form__top-message
    line-height: 1.4
    .label.published-label
      display: inline-flex
    .published-label:before
      top: -2px
      position: relative
</style>
