<template>
  <div>
    <slot :is-search-box-open="isSearchBoxOpen" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      isSearchBoxOpen: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.search-form-panel
  /deep/
    +mobile
      .scheduled-date-input
        .column:nth-child(1)
          padding-bottom: 0
        .column:nth-child(2)
          padding: 6px
        .column:nth-child(3)
          padding-top: 0
</style>
