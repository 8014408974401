<template>
  <div class="message-modal-link">
    <slot :on-click="onClick" />
    <BModal
      :active.sync="isModalOpen"
      has-modal-card
    >
      <div class="modal-card">
        <header
          class="modal-card-head"
          v-text="modalTitle"
        />
        <div class="modal-card-body">
          <slot name="form" />
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
export default {
  props: {
    modalTitle: {
      type: String,
      default: '一言どうぞ'
    }
  },
  data () {
    return {
      isModalOpen: false
    }
  },
  methods: {
    onClick () {
      this.isModalOpen = true
    }
  }
}
</script>

<style scoped lang="sass">
.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
</style>
