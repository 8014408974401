<template>
  <span
    class="join-policy"
    :class="`join-policy-${joinPolicy}`"
  >
    <i
      class="fas is-mr-1"
      :class="joinPolicyIcon"
    />
    <span v-text="joinPolicyText" />
  </span>
</template>

<script>
const JOIN_POLICIES = {
  unlimited: '誰でも参加可能',
  password: '合言葉で参加',
  invitation: '招待のみ'
}
const JOIN_POLICY_ICONS = {
  unlimited: 'fa-user-friends',
  password: 'fa-lock',
  invitation: 'fa-lock'
}

export default {
  props: {
    joinPolicy: {
      type: String,
      required: true
    }
  },
  computed: {
    joinPolicyText () {
      return JOIN_POLICIES[this.joinPolicy]
    },
    joinPolicyIcon () {
      return JOIN_POLICY_ICONS[this.joinPolicy]
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.join-policy
  font-size: 11px
.join-policy-unlimited
  color: #417505
.join-policy-password
  color: #b01f24
.join-policy-invitation
  color: #b01f24
</style>
