import Vue from 'vue'
import Vuex from 'vuex'
import session from 'store/modules/session'
import chat from 'store/modules/chat'
import notification from 'store/modules/notification'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    chat,
    notification
  }
})
