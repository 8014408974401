import axios from 'axios'

export default {
  data () {
    return {
      selectedCards: [],
      isModalActive: false
    }
  },
  computed: {
    hasSelectedCard () {
      return this.selectedCards.length > 0
    }
  },
  methods: {
    async onClickMarker (ev) {
      this.hideCard()
      if (Array.isArray(ev)) {
        ev.map(e => this.$_handleClickMarker(e))
      } else {
        this.$_handleClickMarker(ev)
      }
    },
    $_handleClickMarker (m) {
      switch (m.type) {
        case 'supporter':
          this.$_showSupporter(m.id)
          break
        case 'community':
          this.$_showCommunity(m.id)
          break
        case 'event':
          this.$_showEvent(m.id)
          break
        case 'online_event':
          this.$_showOnlineEvent(m.id)
          break
        case 'organization':
          this.$_showOrganization(m.id)
          break
        default:
          this.hideCard()
          break
      }
    },
    hideCard () {
      this.selectedCards = []
    },
    async $_showSupporter (id) {
      const { data } = await axios.get(`/markers/supporters/${id}.json`)
      this.$_showCard({ type: 'supporter', key: `suppoter-${data.id}`, data })
    },
    async $_showCommunity (id) {
      const { data } = await axios.get(`/markers/communities/${id}.json`)
      this.$_showCard({ type: 'community', key: `community-${data.id}`, data })
    },
    async $_showEvent (id) {
      const { data } = await axios.get(`/markers/events/${id}.json`)
      this.$_showCard({ type: 'event', key: `event-${data.id}`, data })
    },
    async $_showOnlineEvent (id) {
      const { data } = await axios.get(`/markers/online_events/${id}.json`)
      this.$_showCard({ type: 'event', key: `online-event-${data.id}`, data })
    },
    async $_showOrganization (id) {
      const { data } = await axios.get(`/markers/organizations/${id}.json`)
      this.$_showCard({ type: 'organization', key: `organization-${data.id}`, data })
    },
    $_showCard (info) {
      this.selectedCards.push(info)
      this.isModalActive = true
    }
  }
}
