<template>
  <div class="modal-card">
    <header class="modal-card-head">
      投稿者名を編集する
    </header>
    <section class="modal-card-body">
      <BField
        :message="message"
        :type="status"
      >
        <BInput
          v-model="value"
          placeholder="投稿者名"
        />
      </BField>
      <button
        type="button"
        class="button is-rounded is-primary"
        @click="submit"
      >
        更新する
      </button>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      value: this.name,
      errorMessage: []
    }
  },
  computed: {
    status () {
      return inputs.status(this.errorMessage)
    },
    message () {
      return ['更新すると、あなたの過去の投稿すべての投稿者名が変わります。'].concat(this.errorMessage)
    }
  },
  methods: {
    submit () {
      axios.put('/profiles/nicknames', { profile: { nickname: this.value } })
        .then(() => {
          this.$emit('nickname-updated', this.value)
          this.$parent.close()
        })
        .catch((e) => {
          const { response: { data: { errorMessages } } } = e
          this.errorMessage = errorMessages
        })
    }
  }
}
</script>

<style scoped lang="sass">
.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
</style>
