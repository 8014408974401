<template>
  <div
    class="action-bar"
    :class="{'is-show': isShow}"
  >
    <div class="inner">
      <div class="space" />
      <div class="action-bar-main">
        <button
          type="button"
          class="action-bar-button is-sos"
          @click.prevent="isSosModalActive = true"
          @close="isSosModalActive = false"
          v-text="sosButtonLabel"
        /><button
          type="button"
          class="action-bar-button is-support"
          @click.prevent="isSupportModalActive = true"
          @close="isSupportModalActive = false"
          v-text="supportButtonLabel"
        />
      </div>
    </div>
    <BModal
      :active.sync="isSosModalActive"
      :width="320"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="sosModalTitle"
          />
        </header>
        <section class="modal-card-body">
          <ul>
            <li
              v-for="nav in sosNavPaths"
              :key="nav.path"
              class="action-box__list-item is-helpee"
            >
              <a :href="nav.path">
                <i
                  v-for="(icon, index) in nav.icons"
                  :key="index"
                  :class="`fal fa-${icon}`"
                />
                <div class="action-box__link">
                  <span v-text="nav.label" />
                  <p
                    v-if="nav.hint"
                    class="is-size-6 has-text-grey"
                    v-text="nav.hint"
                  />
                </div>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </BModal>
    <BModal
      :active.sync="isSupportModalActive"
      :width="320"
      @close="$emit('close')"
    >
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <p
            class="modal-card-title"
            v-text="supportModalTitle"
          />
        </header>
        <section class="modal-card-body">
          <ul>
            <li
              v-for="nav in supportNavPaths"
              :key="nav.path"
              class="action-box__list-item is-helper"
            >
              <a :href="nav.path">
                <i
                  v-for="(icon, index) in nav.icons"
                  :key="index"
                  :class="`fal fa-${icon}`"
                />
                <div class="action-box__link">
                  <span v-text="nav.label" />
                  <p
                    v-if="nav.hint"
                    class="is-size-6 has-text-grey"
                    v-text="nav.hint"
                  />
                </div>
              </a>
            </li>
          </ul>
        </section>
      </div>
    </BModal>
  </div>
</template>

<script>
export default {
  props: {
    sosModalTitle: {
      type: String,
      required: true
    },
    sosNavPaths: {
      type: Array,
      required: true
    },
    sosButtonLabel: {
      type: String,
      required: true
    },
    supportModalTitle: {
      type: String,
      required: true
    },
    supportNavPaths: {
      type: Array,
      required: true
    },
    supportButtonLabel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isShow: false,
      isSosModalActive: false,
      isSupportModalActive: false
    }
  },
  mounted () {
    [this.actionBoxButton] = document.getElementsByClassName('action-box-button')
    this.isShow = this.actionBoxButton.getBoundingClientRect().top < 0
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.isShow = this.actionBoxButton.getBoundingClientRect().top < 0
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.action-bar
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 3

  > .inner
    display: flex
    justify-content: space-between
    margin: auto
    width: 740px

    > .space
      width: 200px

    > .action-bar-main
      width: 500px

  +mobile
    margin-top: $app-mobile-header-height

    > .inner
      display: block

      > .space
        width: unset

      > .action-bar-main
        width: 100vw

  &.is-show
    display: block

.action-bar-main
  padding: 10px
  background-color: rgba(#f9f9f9, .7)
  display: flex
  justify-content: center

  > .action-bar-button + .action-bar-button
    margin-left: 20px

    +mobile
      margin-left: 5vw

.action-bar-button
  margin: 0
  font-size: 1rem
  font-weight: bold
  text-align: center
  border-radius: 1.5em
  width: 185px
  padding: 5px
  color: #fff

  +mobile
    font-size: 14px

  br
    display: none

  &.is-sos
    background: #5cac00

  &.is-support
    background: #f2796e

.modal-card-title
  text-align: center
  font-size: 16px
.modal-card-body
  border-bottom-left-radius: 6px
  border-bottom-right-radius: 6px
  padding: 0
  ul
    li
      border-top: 1px solid #ddd
    li:first-child
      border-top: 0

.action-box__list-item
  &.is-helpee
    i
      color: #5cac00
  &.is-helper
    i
      color: #f2796e
  a
    font-size: 20px
    display: flex
    font-weight: bold
    padding: 15px 40px
    align-items: center
    i
      font-size: 32px
      text-align: left
      &:nth-child(2)
        font-size: 22px
        margin-left: 6px
        width: 30px
    .action-box__link
      display: flex
      flex-direction: column
      align-items: flex-start
      margin-left: 8px
      line-height: 1.3
      p
        font-weight: normal
</style>
