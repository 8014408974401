<template>
  <span
    class="tag is-rounded organization"
    v-text="name"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.organization
  color: #ec6b00
  background-color: #ffe4ce
  font-size: 11px
  margin-right: 6px
</style>
