<template>
  <h2
    :style="{textAlign: textAlign}"
    class="header-content"
    :class="{'required-label': isRequired,'is-home': isHome}"
  >
    <slot />
  </h2>
</template>

<script>
export default {
  props: {
    textAlign: {
      type: String,
      default: 'left'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.header-content
  padding: 1em 0
  font-weight: 300
  font-size: 1.5rem
  +mobile
    font-size: 1.3rem
    font-weight: bold
    +header-fs
  line-height: 1.4

@import "~stylesheets/components/required-label"

// h2タグのデフォルトサイズを上書き
h2.required-label
  &::after
    vertical-align: middle
    font-size: 1rem

// HOME用：app-container対応で、モバイル時左右にマイナスマージンあり
.is-home
  font-size: 1rem
  font-weight: bold
  background-color: #eeeeee
  margin: 17px 0 10px
  padding: 5px 10px
  line-height: 1.5
  +app-mobile
    margin-left: -10px
    margin-right: -10px

.header-h2__right-container
  float: right
  > *
    margin-left: 10px
  > *:first-child
    margin-left: 0

.is-sign-up-title
  padding-bottom: 0.25rem
  margin-bottom: 1.25rem
  border-bottom: 3px solid $primary
</style>
