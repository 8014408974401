<template>
  <a
    class="sharing-card"
    :href="path"
  >
    <div class="sharing-card-left">
      <div class="sharing-card-head">
        <FaceIcon
          class="is-x-small"
          :src="faceIcon"
        />
        <div>
          <div
            class="sharing-card-name"
            v-text="user.fullName"
          />
          <div
            class="sharing-card-date"
            v-text="formattedDate"
          />
        </div>
        <div class="sharing-card-cat tag is-rounded">
          <span v-text="title" />
        </div>
      </div>
      <div
        class="sharing-card-txt"
        v-html="body"
      />
    </div>
    <div
      v-if="hasImage"
      class="sharing-card-right"
    >
      <div class="sharing-card-img">
        <ImageModal
          v-for="image in imagePaths"
          :key="image.original"
          :src="image.thumb"
          :original-src="image.original"
        />
      </div>
    </div>
  </a>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'
import ImageModal from 'components/ImageModal.vue'
import { format as dateFormat, isSameYear } from 'date-fns'

const TITLES = {
  entry: '預かって',
  lending: '貸すよ',
  borrowing: '貸して',
  giving: '譲るよ',
  taking: '譲って',
  activeInviting: 'お誘い',
  passiveInviting: '誘って',
  support: '預かるよ',
  news: 'お知らせ'
}

export default {
  components: { FaceIcon, ImageModal },
  props: {
    user: {
      type: Object,
      required: true
    },
    sharingType: {
      type: String,
      required: true
    },
    body: {
      type: String,
      default: null
    },
    createdAt: {
      type: String,
      required: true
    },
    imagePaths: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: '#'
    }
  },
  computed: {
    faceIcon () {
      return this.user.avatarPath
    },
    title () {
      return TITLES[this.sharingType]
    },
    formattedDate () {
      return this.formatDate(this.createdAt)
    },
    hasImage () {
      return this.imagePaths.length > 0
    }
  },
  methods: {
    formatDate (date) {
      if (isSameYear(date, new Date())) {
        return dateFormat(date, 'M月D日')
      }
      return dateFormat(date, 'YYYY年M月D日')
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

.sharing-card
  display: flex
  justify-content: space-between
  padding: 1.2rem 1rem
  border-top: #e7e7e7 1px solid
  border-bottom: #c7c7c7 1px solid

  .sharing-card-left
    flex: 1
  .sharing-card-right
    width: 80px
    margin-left: 8px

  .sharing-card-head
    display: flex
    align-items: flex-start
    margin-bottom: 8px
    > *
      margin-right: 0.5rem

  .sharing-card-name
    font-weight: 700
    margin-top: -2px
  .sharing-card-date
    font-size: 0.9rem
    color: #999
    margin-top: -4px
  .sharing-card-cat
    font-weight: 700
    background-color: #919191
    color: #fff
  .sharing-card-txt
    font-size: 0.9rem
</style>
