<template>
  <div>
    <slot
      :onUnscheduledChanged="onUnscheduledChanged"
      :unscheduled="unscheduled"
      :onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import * as shareable from './shareable_form'

export default {
  props: {
    initialUnscheduled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      unscheduled: this.initialUnscheduled
    }
  },
  methods: {
    onUnscheduledChanged (value) {
      this.unscheduled = value
    },
    onSubmit (ev) {
      shareable.confirmSharingCandidateEmpty(ev)
    }
  }
}
</script>

<style scoped lang="sass">
</style>
