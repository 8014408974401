<template>
  <span>
    <button
      class="search-button"
      type="button"
      title="検索"
      @click.prevent="isSearchModalActive = true"
    >
      <BIcon
        pack="far"
        icon="search"
      />
      <span
        v-if="showLabel"
        :class="showLabelSp ? 'search-button__label--active' : 'search-button__label'"
        v-text="label"
      />
    </button>

    <BModal
      :active.sync="isSearchModalActive"
      has-modal-card
    >
      <header class="modal-card-head">
        <p
          class="modal-card-title"
          v-text="title"
        />
      </header>
      <div class="modal-card">
        <section class="modal-card-body">
          <slot />
        </section>
      </div>
    </BModal>
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '検索'
    },
    title: {
      type: String,
      default: '検索'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    showLabelSp: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSearchModalActive: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.search-button
  background-color: transparent
  border: none
  cursor: pointer
  padding: 0
  display: inline-flex
  align-items: center

.search-button__label
  line-height: 24px
  +mobile
    display: none

.search-button__label--active
  line-height: 24px

.modal-card
  font-size: 1rem;
  width: auto

.modal-card-body
  border-radius: 0 0 $radius-large $radius-large

  /deep/ > * ~ *
    margin-top: 1rem

  /deep/ .header-content + *
    margin-top: unset

  /deep/ ul, /deep/ ol
    margin-left: 2rem

  /deep/ ul
    list-style-type: disc
</style>
