<template>
  <div
    class="field"
    :class="{ 'is-linebreak': isLineBreak }"
  >
    <label
      class="label"
      :class="customClass"
      v-text="label"
    />
    <BRadio
      v-for="opt in options"
      :key="opt.value"
      v-model="selectedValue"
      :native-value="opt.value"
      :name="name"
      @input="handleInput"
    >
      <span v-text="opt.label" />
    </BRadio>
  </div>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    collection: {
      type: Array,
      required: true
    },
    includeBlank: {
      type: Boolean,
      default: false
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isLineBreak: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { selectedValue: this.value }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    options () {
      return this.collection.map(([label, value]) => ({ label, value }))
    }
  },
  methods: {
    handleInput (value) {
      this.onChange(value)
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
/deep/ .b-radio.radio + .radio
  margin-left: 0
/deep/ .b-radio.radio
  margin-right: 0.5rem
/deep/ .b-radio.radio:last-child
  margin-right: 0
.is-linebreak
  +app-mobile
    /deep/ .b-radio.radio + .radio
      display: flex
      position: relative
      align-items: flex-start
      margin-top: 0.5rem
</style>
