
<template>
  <div
    class="friend-gauge"
    :class="{'is-small': isSmall}"
  >
    <h4 class="origtitle">
      シェア友レベル
    </h4>
    <div class="gauge">
      <div
        v-for="(num, key) in 3"
        :key="key"
        class="origblock"
        :class="areEnable[num - 1]"
      />
    </div>
    <div class="labels">
      <span
        v-for="(num, key) in 3"
        :key="key"
        :class="areEnable[num - 1]"
        class="origlabel"
        v-text="labels[num - 1]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    friendLevel: {
      type: Number,
      default: 0
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    areEnable () {
      return Array(3)
        .fill({})
        .map((value, index) => ({ 'is-enable': index < this.friendLevel }))
    },
    labels () {
      return ['モノ', '予定', '託児']
    }
  }
}
</script>

<style scoped lang="sass">
$main-color: #ff7b00
$gauge-height: 0.625rem
$gauge-radius: $gauge-height / 2
.friend-gauge
  line-height: 1.2
  text-align: center
  > .origtitle
    margin: 0 0 1px
    font-size: 10px
  > .gauge
    display: flex
    justify-content: center;
    height: $gauge-height
    width: 4.5rem
    margin: 2px auto 0
    > .origblock
      flex: 1
      border-top: 0.1rem solid $main-color
      border-bottom: 0.1rem solid $main-color
      border-left: 0.1rem solid $main-color
      &:first-child
        border-radius: $gauge-radius 0 0 $gauge-radius
      &:last-child
        border-right: 0.1rem solid $main-color
        border-radius: 0 $gauge-radius $gauge-radius 0
      &.is-enable
        background-color: $main-color
      &.is-enable:not(:first-child)
        border-left: 0.1rem solid #fff
  > .labels
    font-size: 11px
    font-weight: bold
    padding-top: 0.25rem
    > .origlabel
      display: inline-block
      padding: 0 0.25rem
      color: #9b9b9b
      &.is-enable
        color: initial
    > .origlabel + .origlabel
      border-left: 1px solid #d7d7d7
  &.is-small
    > .origtitle
      font-size: 10px
    > .labels
      font-size: 10px
</style>
