<template>
  <div class="profile-section-hierarchy">
    <div class="hierarchy-title">
      <slot name="hierarchy-title" />
    </div>
    <div class="hierarchy-content">
      <slot name="hierarchy-content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfileSectionHierarchy'
}
</script>

<style lang="sass" scoped>
.profile-section-hierarchy
  position: relative

.hierarchy-title
  font-size: 100%
  margin-bottom: 0.8em

.hierarchy-content
  padding-left: 2em
  position: relative
  &::before
    content: ''
    display: block
    width: 4px
    height: 100%
    border-radius: 5px
    background-color: rgb(238, 238, 238)
    position: absolute
    left: 1em
    top: 0
</style>
