import { render, staticRenderFns } from "./SnsShareButton.vue?vue&type=template&id=65bc8ccd&scoped=true&"
import script from "./SnsShareButton.vue?vue&type=script&lang=js&"
export * from "./SnsShareButton.vue?vue&type=script&lang=js&"
import style0 from "./SnsShareButton.vue?vue&type=style&index=0&id=65bc8ccd&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65bc8ccd",
  null
  
)

export default component.exports