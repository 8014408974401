<template>
  <a
    class="organization-item"
    :href="showPath"
  >
    <div
      class="organization-item-thumb-container"
      :style="{ 'backgroundImage': `url(${imagePath})` }"
    >
    </div>
    <div class="organization-item-detail">
      <div class="organization-item-content">
        <div
          class="organization-item-name"
          v-text="name"
        />
        <div
            v-if="recentCount > 0"
            class="organization-item-recent-count"
        >
          この1週間で<span v-text="recentCount" />件の新着
        </div>
      </div>
      <div
        v-if="participatable"
        class="organization-item-entry-button button is-small is-primary is-rounded "
      >
        参加する
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    imagePath: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    recentCount: {
      type: Number,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    showPath: {
      type: String,
      required: true
    },
    participatable: {
      type: Boolean,
      required: true
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.organization-item
  display: flex
  flex-direction: column
  padding: 0
  border: 1px solid #d7d7d7
  margin-bottom: 20px
  border-radius: $radius-size
  box-shadow: 0 2.5px 2.5px 1.25px #bbb
  background-color: #fff

  .organization-item-thumb-container
    border-top-left-radius: $radius-size
    border-top-right-radius: $radius-size
    position: relative
    background-size: cover
    height: 185px
    background-position: center
    background-repeat: no-repeat
    +app-mobile
      padding-top: 200px

  .organization-item-detail
    display: flex
    padding: 0.5rem 1rem
    +app-mobile
      padding: 0.5rem 0.5rem

    .organization-item-content
      flex: 1
      overflow-x: hidden

      .organization-item-name
        font-weight: 700
        line-height: 1.5
        max-height: 3rem /* line-height * 2 */
        overflow-y: hidden

      .organization-item-recent-count
        font-size: 12px
        color: #d0021b

      .organization-item-entry-button
        align-self: flex-end
</style>
