<template>
  <div class="notice">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Notice'
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

.notice
  font-size: 12px
  background-color: rgba(241, 241, 241, 0.3)
  border: 1px solid rgb(216, 216, 216)
  border-radius: 2px
  padding: 0.6rem 1em
  +app-mobile
    font-size: 14px
  p
    margin-bottom: 0.6em
    &:last-child
      margin-bottom: 0
</style>
