<template>
  <BField
    :label="label"
    :message="errorMessage"
    :type="status"
    :label-for="id"
    :addons="false"
    :class="{'is-marginless': isMarginless}"
    :custom-class="customClass"
  >
    <BInput
      :id="id"
      v-model="inputValue"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      @blur="handleBlur"
    />
    <slot />
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    onBlur: {
      type: Function,
      default: () => {}
    },
    isMarginless: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isProtected: {
      type: Boolean,
      default: false
    },
    addInputtedClass: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    }
  },
  data () {
    return { inputValue: this.value, displayErrors: this.errors, isInputted: false }
  },
  computed: {
    status () {
      return inputs.status(this.displayErrors, this.isInputted)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    errorMessage () {
      if (this.isInputted) {
        // NOTE: ふつうに表示したいエラーメッセージも隠してしまう場合があるので要注意。制御を変えたほうがいい
        return this.displayErrors.filter(message => !message.match(/を入力してください$/i))
      }
      return this.displayErrors
    }
  },
  methods: {
    handleBlur (e) {
      this.isInputted = this.addInputtedClass && e.target.value.length > 0
      // inputのvalueをjsで入れた場合にstoreに入ってないので入れとく
      if (this.inputValue !== e.target.value) {
        this.inputValue = e.target.value
      }
      const result = this.onBlur(e)
      // 郵便番号自動入力時のエラーをトースト表示させないための苦肉の対応の模様
      // 郵便番号用のコンポーネントをいずれちゃんと作って分離しないとメンテが不安
      if (result instanceof Promise) {
        result.then((errorMessage) => {
          if (errorMessage) {
            this.displayErrors = [errorMessage]
            this.isInputted = false
          } else {
            this.displayErrors = []
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  @import '~stylesheets/components/required-label'
  @import '~stylesheets/components/published-label'
.is-short
  .control
    width: 33%
</style>
