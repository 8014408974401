<template>
  <div class="modal-card">
    <section class="modal-card-body">
      <ShareFriendCard
        :level-by-me="levelByMe"
        :level-by-friend="levelByFriend"
        :user="user"
        :in-modal="true"
      />
    </section>
  </div>
</template>

<script>
import ShareFriendCard from 'components/ShareFriendCard'

export default {
  components: { ShareFriendCard },
  props: {
    levelByMe: {
      type: Number,
      required: true
    },
    levelByFriend: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
  .modal-card-body
    padding: 0
</style>
