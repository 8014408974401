<template>
  <Transition name="slide">
    <div class="chat-wrapper">
      <div class="chat-header">
        <div class="chat-header-content">
          <template v-if="avatar">
            <FaceIcon
              class="is-x-small"
              :src="avatar"
            />
          </template><span
            class="chat-title"
            v-text="title"
          />
        </div>
        <div class="chat-header-right-content">
          <slot name="chat-header-right" />
          <slot name="option-menu-items" />
        </div>
      </div>
      <div
        v-if="visibleChatDescription"
        class="chat-header-description is-px-3 is-size-7"
        v-text="chat.description"
      />

      <ChatMessages
        :chat-messages="chatMessages"
        :current-user="currentUser"
      >
        <template
          v-if="isImageSending"
          slot="dialog"
        >
          <div class="talk-dialog">
            <div class="talk-box">
              <i class="fas fa-spinner fa-pulse" /> <Nl2br :text="'写真を送信中'" />
            </div>
          </div>
        </template>
      </ChatMessages>

      <div class="talk-wrapper">
        <div class="talk-toolbox">
          <div class="talk-toolbox-left talk-toolbox-block">
            <div class="talk-icon">
              <i class="talk-icon-file fal fa-camera" />
              <input
                id="chat-file-input"
                type="file"
                class="img-upload"
                accept="image/*"
                @change="doCreateImageChatMessage"
              >
            </div>
          </div>
          <div class="talk-toolbox-center talk-toolbox-block">
            <textarea
              v-model="message"
              class="talk-input"
              rows="1"
              placeholder="メッセージを入力"
              @keydown="handleKeydown"
            />
          </div>
          <div class="talk-toolbox-right talk-toolbox-block">
            <i
              class="talk-icon fal fa-paper-plane talk-submit"
              @click="doCreateChatMessage"
            />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Nl2br from 'components/Nl2br'
import ChatMessages from 'components/ChatMessages'
import FaceIcon from 'components/FaceIcon'
import { isMac } from '../lib/browser'

export default {
  components: { Nl2br, ChatMessages, FaceIcon },
  props: {
    chat: {
      type: Object,
      required: true
    },
    avatar: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      message: '',
      isImageSending: false
    }
  },
  computed: {
    ...mapState('chat', ['currentChat']),
    ...mapState('session', ['currentUser']),
    title () {
      return this.chat.title
    },
    chatMessages () {
      return this.chat.chatMessages
    },
    visibleChatDescription () {
      return this.chat.description.length > 0
    }
  },
  created () {
    this.initChat(this.chat)
  },
  mounted () {
    [this.talkInput] = this.$el.getElementsByClassName('talk-input')
    this.talkInput.addEventListener('input', this.handleTextareaHeight)
  },
  beforeDestroy () {
    this.talkInput.removeEventListener('input', this.handleTextareaHeight)
  },
  methods: {
    ...mapActions('chat', [
      'initChat',
      'createChatMessage',
      'createImageChatMessage'
    ]),
    doCreateChatMessage () {
      const msg = this.message.trim()
      this.message = ''
      if (msg.length === 0) return
      this.createChatMessage(msg)
      // 高さ調整にdomを触って対処する
      this.talkInput.style.height = window.getComputedStyle(this.talkInput, null).getPropertyValue('min-height')
    },
    async doCreateImageChatMessage ({ target }) {
      if (!window.confirm('画像を送信します。よろしいですか？')) {
        return
      }

      const {
        files: [file]
      } = target
      if (!file) return
      this.isImageSending = true
      await this.createImageChatMessage(file)
      this.isImageSending = false
      // eslint-disable-next-line
      target.value = null
    },
    handleKeydown ({ ctrlKey, metaKey, key }) {
      const modifierKey = isMac ? metaKey : ctrlKey
      if (modifierKey && key === 'Enter') {
        this.doCreateChatMessage()
      }
    },
    handleTextareaHeight (event) {
      // 高さ調整にdomを触って対処する
      // see https://lorisleiva.com/renderless-resizable-textarea/
      // eslint-disable-next-line
      event.target.style.height = 'auto'
      // eslint-disable-next-line
      event.target.style.height = `${event.target.scrollHeight}px`
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$border-color: #e4e4e4
$bg-gray-color: #fff
.chat-wrapper
  display: flex
  flex-direction: column
  height: calc(100vh - #{$app-desktop-header-height})
  background: #fff
  +app-mobile
    height: calc(100vh - #{$app-mobile-header-height})

+mobile
  .chat-wrapper
    width: 100%

.chat-header
  display: flex
  justify-content: space-between
  align-items: center
  min-height: 50px
  padding: 0 10px
  line-height: 1
  border-bottom: 1px solid $border-color
  .faceicon img
    width: 30px
  .chat-title
    // font-size: 12px
    line-height: 1.4
    font-weight: bold
    word-wrap: break-word
    max-width: inherit
  .chat-header-content
    display: flex
    max-width: calc(100% - 1.5rem)
    align-items: center
    .face-icon + .chat-title
      padding-left: 10px
  .chat-help-checker
    flex: 3
    display: flex
    align-items: center
    justify-content: flex-end
  .check-resolution
    appearance: none
    -webkit-appearance: none
    background-color: #e24a3f
    height: 28px
    line-height: 1
    position: relative
    display: flex
    align-items: center
    justify-content: center
    border-radius: 60px
    padding: 0px 0
    cursor: pointer
    overflow: hidden
    transition: 0.3s
    &:hover
      opacity: 0.9
    .handle
      width: 22px
      height: 22px
      border-radius: 50%
      background-color: #a22219
      position: absolute
      left: 3px
      top: 3px
      z-index: 1
      transition: 0.3s
    .label
      position: relative
      z-index: 2
      text-align: right
      display: block
      margin-left: 20px
      padding: 0 5px
      color: white
      font-size: 9px
      transform: scale(0.8)
      transition: 0.3s
    &.is-resoluted
      background-color: #0d9937
      &::before
        content: "解決済"
        color: white
        font-size: 9px
        width: 100%
        display: block
        position: absolute
        left: 10px
        text-align: left
      .handle
        left: calc(100% - 25px)
        background-color: #096a26
      .label
        opacity: 0

.chat-header-description
  height: 40px
  background: #eee
  display: flex
  align-items: center

.talk-toolbox
  background-color: $bg-gray-color
  padding: 8px
  line-height: 1
  display: flex
  align-items: flex-end
  justify-content: space-between
  .talk-icon
    position: relative
    color: #333
    cursor: pointer
    padding: 2px 10px
    width: 42px
    overflow: hidden
    font-size: 22px
    &:hover
      opacity: 0.9
      cursor: pointer
  .talk-icon-file
    cursor: pointer
  .img-upload
    position: absolute
    z-index: 2
    top: 0
    left: 0
    opacity: 0

+mobile
  .talk-toolbox .talk-icon
    font-size: 130%
    width: 40px

.talk-toolbox-block
  display: flex
  justify-content: space-between
  align-items: center
  &.talk-toolbox-left
    padding-bottom: 6px
  &.talk-toolbox-right
    padding-bottom: 6px
  &.talk-toolbox-center
    flex-grow: 1

.talk-wrapper
  border-top: 1px solid $border-color

.talk-input
  margin-left: 10px
  margin-right: 10px
  width: 100%
  overflow: hidden
  min-height: 36px
  border: 1px solid #d7d7d7
  border-radius: 18px
  font-size: 14px
  line-height: 1.4
  outline: none
  padding: 6px 12px
  resize: none

+mobile
  .talk-input
    font-size: 16px

.talk-dialog
  padding: 15px 10px 0
  display: flex
  justify-content: flex-end

  .talk-box
    max-width: 80%
    background: #eee
    border-radius: 12px 0 12px 12px
    padding: 8px 12px 8px
    font-size: 75%
    color: #000
    word-wrap: break-word

.slide-enter-active, .slide-leave-active
  transition: transform .5s
.slide-enter
  transform: translateX(250px)
.slide-leave-to
  transform: translateX(250px)

/deep/ .dropdown-menu
  +mobile
    max-width: 80vw
    .dropdown-item
      white-space: normal

</style>
