/* eslint-disable no-param-reassign */
const store = {
  namespaced: true,
  state: {
    currentUser: null
  },
  getters: {},
  mutations: {
    setCurrentUser (state, user) {
      state.currentUser = user
    }
  },
  actions: {}
}
export default store
