<template>
  <div>
    <a
      href="#"
      class="button is-primary is-rounded"
      @click.prevent="onClick"
      v-text="label"
    />
    <Portal to="modal">
      <BModal
        :active.sync="isMessageModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <header
            class="modal-card-head"
            v-text="messageModalTitle"
          />
          <div class="modal-card-body">
            <slot name="message" />
          </div>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    messageModalTitle: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isMessageModalActive: false
    }
  },
  methods: {
    onClick () {
      this.isMessageModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-card
  max-width: 500px
  border-radius: 0 0 $radius-size $radius-size
</style>
