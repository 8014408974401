<script>
const newlineRegex = /(\r\n|\r|\n)/g

export default {
  functional: true,
  props: {
    text: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  render (createElement, context) {
    return createElement(context.props.tag, context.props.text.split(newlineRegex).map((line) => {
      if (line.match(newlineRegex)) {
        return createElement('br')
      }
      return line
    }))
  }
}
</script>
