<template>
  <div>
    <slot
      :onChangeZipCode="onChangeZipCode"
      :onSubmit="onSubmit"
      :onChangeCategoryType="onChangeCategoryType"
      :onChangeJoinPolicy="onChangeJoinPolicy"
      :visibleSchoolTypeField="visibleSchoolTypeField"
      :visibleJoinPasswordField="visibleJoinPasswordField"
    />
  </div>
</template>

<script>
import autoInputAddress from 'lib/auto_input_address'
import autoInputLatlng from 'lib/auto_input_latlng'

export default {
  props: {
    categoryTypeSchool: {
      type: Boolean,
      required: true
    },
    joinPolicyPassword: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      visibleSchoolTypeField: this.categoryTypeSchool,
      visibleJoinPasswordField: this.joinPolicyPassword
    }
  },
  methods: {
    onChangeZipCode (e) {
      autoInputAddress(e.currentTarget)
    },
    async onSubmit () {
      await autoInputLatlng()
      this.$el.querySelector('form').submit()
    },
    onChangeCategoryType (v) {
      this.visibleSchoolTypeField = (v === 'School')
    },
    onChangeJoinPolicy (v) {
      this.visibleJoinPasswordField = (v === 'password')
    }
  }
}
</script>

<style scoped lang="sass">
</style>
