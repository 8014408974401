<template>
  <div>
    <slot
      :fullDescription="fullDescription"
      :toggleFullDescription="toggleFullDescription"
    />
  </div>
</template>

<script>
export default {
  data () {
    return {
      fullDescription: false
    }
  },
  methods: {
    toggleFullDescription () {
      this.fullDescription = !this.fullDescription
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.organization-cover-container
  position: relative
  padding-bottom: 25px
  margin-bottom: -12px
  .organization-cover
    display: flex
    justify-content: center
    align-items: center
    height: 185px
    background-size: cover
    background-position: center
    background-repeat: no-repeat
  .option-menu
    position: absolute
    right: 24px
    bottom: 0
    border-radius: 50%
    height: 53px
    width: 53px
    display: flex
    justify-content: center
    align-items: center
    background-color: white
    box-shadow: 0 3px 2px #ccc

.organization-subject
  .portal
    color: #666
    font-size: 12px
  .name
    font-size: 22px
    font-weight: bold
  .address
    font-size: 10px

.organization-description
  font-size: 12px

.organization-images
  .column
    display: flex
    justify-content: center
</style>
