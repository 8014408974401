<template>
  <section 
    :class="topicsSharesPage ? 'action-boxes topics-shares' : 'action-boxes'"
  >
    <div class="boxes">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    topicsSharesPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.action-boxes
  position: relative
  width: 500px
  height: 170px
  padding: 0 38px
  background: url(../images/home/keyvisual_pc.jpg) top center no-repeat
  background-size: cover
  text-align: center
  +app-mobile
    width: 100%
    height: auto
    padding: 0 2.6vh calc(100%*399/960) 2.7vh
    background: url(../images/home/keyvisual_sp.jpg) top center no-repeat
    background-size: 100%

  > .boxes
    width: 100%
    display: flex
    justify-content: space-between
    align-items: stretch

.topics-shares
  height: 100px
  background: none
  display: flex
  align-items: center
  margin-bottom: 1rem
  +app-mobile
    padding-bottom: 0px
    margin-bottom: 0
</style>
