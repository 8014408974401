<template>
  <div>
    <input
      :name="name"
      type="hidden"
    >
    <BCheckbox
      v-model="inputChecked"
      :name="name"
      :native-value="value"
    >
      <span v-html="label" />
    </BCheckbox>
    <BField
      :message="errors"
      :type="status"
    />
  </div>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    checked: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return { inputChecked: this.checked }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    }
  }
}
</script>

<style scoped lang="sass">
</style>
