<template>
  <div>
    <ButtonSquareEdge
      @click="onClick"
      v-text="label"
    />
    <Portal to="modal">
      <BModal
        :active.sync="isConfirmModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <header class="modal-card-head">
            <p>確認</p>
          </header>
          <section class="modal-card-body">
            <slot
              name="confirm"
              :onContinue="onContinue"
            />
          </section>
        </div>
      </BModal>
      <BModal
        :active.sync="isMessageModalActive"
        has-modal-card
      >
        <div class="modal-card">
          <header
            class="modal-card-head"
            v-text="messageModalTitle"
          />
          <div class="modal-card-body">
            <slot name="message" />
          </div>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    messageModalTitle: {
      type: String,
      required: true
    },
    needConfirm: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      isConfirmModalActive: false,
      isMessageModalActive: false
    }
  },
  methods: {
    onClick () {
      if (this.needConfirm) {
        this.isConfirmModalActive = true
      } else {
        this.isMessageModalActive = true
      }
    },
    onContinue () {
      this.isConfirmModalActive = false
      this.isMessageModalActive = true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-card
  max-width: 500px
  border-radius: 0 0 $radius-size $radius-size
</style>
