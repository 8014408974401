<template>
  <div>
    <p v-html="formattedText" />
    <span v-show="shouldCutText">
      <a
        href="#"
        class="is-primary-link"
        @click.prevent="handleClick"
        v-text="readMoreLinkText"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    maxChars: {
      type: Number,
      default: 100
    },
    moreText: {
      type: String,
      default: '続きを見る'
    },
    lessText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isReadMore: false
    }
  },
  computed: {
    shouldCutText () {
      return this.text.length > this.maxChars
    },
    formattedText () {
      let str = this.text
      if (!this.isReadMore && this.shouldCutText) {
        str = str.substring(0, this.maxChars) + '...'
      }
      return str
    },
    readMoreLinkText () {
      return this.isReadMore ? this.lessText : this.moreText
    }
  },
  methods: {
    handleClick () {
      this.isReadMore = !this.isReadMore
    }
  }
}
</script>

<style scoped lang="sass">
</style>
