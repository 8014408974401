<template>
  <div
    :class="{ 'is-border-white': isBorderWhite }"
    class="face-icon"
  >
    <span
      :style="{'background-image': `url(${bgurl}`}"
      class="icon upload-wrapper no-photo-wrapper"
    >
      <i class="upload-icon fal fa-image" />
      <span class="upload-label">
        写真
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    bgurl: {
      type: String,
      required: true
    },
    isBorderWhite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
.face-icon
  display: inline-block
  position: relative
  text-align: center

  > .icon
    background-color: #fff
    border-radius: 50%
    border: 1px solid #ffbe45
    position: relative
    margin-left: -10%
    height: 5.5rem
    width: 5.5rem

    &:first-child
      margin-left: 0

  &.is-border-white
    > .icon
      border-color: #fff

.no-photo-wrapper
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 60px
  height: 60px
  background-position: center
  background-size: cover
  color: #fff
  line-height: 1.2
  position: relative
  overflow: hidden
  border-radius: 50%

  &.upload-wrapper
    &::after
      position: absolute
      z-index: 1
      top: 0
      left: 0
      width: 100%
      height: 100px
      background-color: rgba(#000, 1)
      display: block
      content: ''
      opacity: 0.05
      transition: opacity 0.1s

    &:hover
      &::after
        opacity: 0

    &:active
      &::after
        opacity: 0.2

.upload-label
  font-size: 12px
  text-shadow: 0 1px 1px rgb(104, 85, 67)
  font-weight: 600
  position: relative
  z-index: 2

.upload-icon
  font-size: 20px
  text-shadow: 0 1px 1px rgb(104, 85, 67)
  position: relative
  z-index: 2
</style>
