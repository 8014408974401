<template>
  <div class="topic-form-container">
    <slot />
    <FaceIcon
      class="is-small"
      :src="user.avatarPath"
    />
    <form
      class="form"
      :action="url"
      method="post"
      enctype="multipart/form-data"
    >
      <input
        type="hidden"
        :name="csrfParam"
        :value="csrfToken"
      >
      <input name="_method" type="hidden" :value="method" />
      <div
        v-if="nicknameAvailable"
        class="nickname-container"
      >
        <BRadio
          v-model="useNickname"
          :name="useNicknameProps.name"
          :native-value="false"
        >
          実名
        </BRadio>
        <BRadio
          v-model="useNickname"
          :name="useNicknameProps.name"
          :native-value="true"
        >
          ニックネーム
        </BRadio>
        <BField
          v-if="useNickname && !!nickname"
          class="nickname-input"
        >
          <BInput
            :name="nicknameProps.name"
            :disabled="true"
            :value="nickname"
            placeholder="投稿者名"
            expanded
          />
          <p class="control">
            <button
              type="button"
              class="button is-primary"
              title="投稿者名を変更する"
              @click="openEditNicknameModal"
            >
              <i class="fas fa-pencil" />
            </button>
            <Portal to="modal">
              <BModal
                :active.sync="isEditNicknameModalActive"
                class="edit-nickname-modal"
                has-modal-card
              >
                <EditNicknameForm
                  :name="writerName"
                  @nickname-updated="onNicknameUpdated"
                />
              </BModal>
            </Portal>
          </p>
        </BField>
        <BInput
          v-else
          class="nickname-input"
          :name="nicknameProps.name"
          :disabled="writerNameDisabled"
          placeholder="投稿者名"
        />
      </div>
      <BSelect
        v-if="labelProps && labelProps.isRequired"
        v-model="label"
        :name="labelProps.name"
        class="select-label"
        placeholder="ラベル"
      >
        <option />
        <option
          v-for="labelOpt in labelProps.collection"
          :key="labelOpt[1]"
          :value="labelOpt[1]"
          v-text="labelOpt[0]"
        />
      </BSelect>
      <BInput
        v-if="titleProps && titleProps.isRequired"
        :name="titleProps.name"
        :value="titleProps.value"
        class="title-topic"
        placeholder="タイトル"
      />
      <BInput
        class="form-textarea"
        :name="bodyProps.name"
        :value="bodyProps.value"
        :placeholder="placeholder"
        rows="4"
        type="textarea"
      />
      <div
        class="form-buttons "
        v-if="isEdit || attachedImageCount > 0"
        :class="inSwiper ? 'in-swiper' : ''"
      >
        <div
          v-for="(image, idx) in imageProps"
          :key="idx"
          class="form-photo"
          :style="{ backgroundImage: `url(${images[idx]})` }"
        >
          <div v-if="isEdit">
            <div v-if="!!images[idx]" class="remove-photo" @click="removePhoto(idx)">削除</div>
            <button
              type="button"
              class="button is-rounded file-choose-button"
              :class="{ visible: !images[idx] }"
              style="height: 100%;"
            >
              <i class="far fa-camera "></i><br />
              写真
              <input
                class="file-choose-input"
                type="file"
                accept="image/*"
                :name="image.name"
                @change="fileChoosedOnEdit($event, idx)"
              >
            </button>
            <input :name="image.name.replace('[', '[remove_')" type="hidden" :value="removedImages[idx]" />
          </div>
        </div>
      </div>
      <div class="form-buttons">
        <input
          v-if="isEdit"
          class="button is-rounded button-cancel"
          @click="cancelEdit"
          value="キャンセル"
        >
        <input
          type="submit"
          class="button is-primary is-rounded"
          :value="isEdit ? '更新' : submitLabel"
        >
        <div v-if="!isEdit">
          <button
            v-for="(props, idx) in imageProps"
            :key="idx"
            type="button"
            class="button is-rounded file-choose-button"
            :class="imageChooseButtonClass(idx)"
          >
            <i class="far fa-camera is-mr-2" />
            写真
            <input
              class="file-choose-input"
              type="file"
              accept="image/*"
              :name="props.name"
              @change="fileChoosed"
            >
          </button>
        </div>
        <button
          v-if="!canChooseMorePhoto && !isEdit"
          type="button"
          class="button is-rounded file-choose-button disabled visible"
        >
          <i class="far fa-camera is-mr-2" />
          写真
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Rails from 'rails-ujs'
import EditNicknameForm from 'components/EditNicknameForm'

export default {
  components: { EditNicknameForm },
  props: {
    user: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    method: {
      type: String,
      default: 'post'
    },
    bodyProps: {
      type: Object,
      required: true
    },
    titleProps: {
      type: Object,
      default: null
    },
    labelProps: {
      type: Object,
      default: null
    },
    imageProps: {
      type: Array,
      required: true
    },
    useNicknameProps: {
      type: Object,
      default: null
    },
    nicknameProps: {
      type: Object,
      required: true
    },
    nicknameAvailable: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      default: '投稿'
    },
    toggleViewEdit: {
      type: Function,
      default: () => {}
    },
    inSwiper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      images: this.imageProps.map(img => img.value),
      removedImages: this.imageProps.map(_ => false),
      label: this.labelProps && this.labelProps.value,
      useNickname: false,
      isEditNicknameModalActive: false,
      nickname: this.user.nickname,
    }
  },
  computed: {
    csrfToken () {
      return Rails.csrfToken()
    },
    csrfParam () {
      return Rails.csrfParam()
    },
    imageChooseButtonClass () {
      return idx => ({ visible: this.isNextImage(idx) })
    },
    attachedImageCount() {
      return this.images.filter(Boolean).length
    },
    canChooseMorePhoto () {
      return this.imageProps.length > this.attachedImageCount
    },
    writerName () {
      return this.useNickname ? this.nickname : this.user.fullName
    },
    writerNameDisabled () {
      return !!this.writerName
    },
    isEdit () {
      return this.method == 'patch'
    }
  },
  methods: {
    isNextImage (idx) {
      return this.attachedImageCount === idx
    },
    fileChoosed (ev) {
      const file = ev.target.files[0]
      this.images.splice(this.attachedImageCount, 1, URL.createObjectURL(file))
    },
    fileChoosedOnEdit (ev, index) {
      const file = ev.target.files[0]
      this.images.splice(index, 1, URL.createObjectURL(file))
      this.removedImages[index] = false
    },
    openEditNicknameModal () {
      this.isEditNicknameModalActive = true
    },
    onNicknameUpdated (name) {
      this.nickname = name
    },
    removePhoto (idx) {
      this.images.splice(idx, 1, '');
      this.removedImages[idx] = true
    },
    cancelEdit () {
      this.toggleViewEdit();
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.topic-form-container
  display: flex
  flex-wrap: wrap
  .face-icon
    min-width: 37px
  .form
    flex: 1
    display: flex
    flex-direction: column
    margin-left: 10px
    .select-label,.title-topic
      margin-bottom: 8px
    .form-textarea
      textarea.textarea
        border-radius: 3px
        border: 1px solid #d7d7d7
        padding: 6px 12px
        resize: none !important
        font-size: 14px
    .form-buttons
      display: flex
      margin-top: 8px
      .file-choose-button
        position: relative
        border-color: #363636
        display: none
        margin-left: 9px
        &.disabled
          color: #999
          border-color: #999
        &.visible
          display: block
        .file-choose-input
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          appearance: none
          opacity: 0
          cursor: pointer
      .button
        +app-mobile
          padding-left: 0.6rem
          padding-right: 0.6rem
          font-size: 14px
    .form-photos
      display: flex
      flex-wrap: wrap
    .form-photo
      width: 70px
      height: 70px
      margin: 6px
      border-radius: 6px
      background-repeat: no-repeat
      background-position: center
      background-size: cover
      position: relative
      margin-bottom: 20px
      +app-mobile
        width: 50px
        height: 50px
      .remove-photo
        position: absolute
        top: 70px
        left: 18px
        cursor: pointer
        +app-mobile
          top: 53px
          left: 10px
          font-size: 14px
    .in-swiper
      .form-photo
        width: 50px
        height: 50px
        +app-mobile
          width: 35px
          height: 35px
        .remove-photo
          top: 54px
          left: 10px
          font-size: 14px
          +app-mobile
            font-size: 12px
            top: 40px
            left: 6px
      .button
        font-size: 14px
        padding-left: 0.7rem
        padding-right: 0.7rem
        +app-mobile
          font-size: 12px
          padding-left: 0.6rem
          padding-right: 0.6rem
  .nickname-container
    margin-bottom: 8px
    label
      margin-bottom: 8px
.button-cancel
  width: 120px
  margin-right: 10px
.form-textarea
  /deep/ > .textarea
    padding: 6px 12px
    resize: none
    font-size: 14px
    line-height: normal
</style>
