<template>
  <div class="event-item-schedule">
    <div class="event-item-date">
      <p><span v-text="month" />/<span v-text="day" /></p>
      <p v-text="dayOfTheWeek" />
    </div>
    <div class="event-item-times">
      <p
        v-for="time in timesToShow"
        :key="time"
        v-text="`${time}〜`"
      />
      <p v-if="hasMoreTimes">
        <i class="fal fa-ellipsis-v" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    month: {
      type: String,
      required: true
    },
    day: {
      type: String,
      required: true
    },
    dayOfTheWeek: {
      type: String,
      required: true
    },
    times: {
      type: Array,
      required: true
    }
  },
  computed: {
    timesToShow () {
      return this.times.slice(0, 2)
    },
    hasMoreTimes () {
      return this.times.length > 2
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.event-item-schedule
  text-align: center
  padding-right: 1rem
  line-height: 1.2
  +app-mobile
    padding-right: 0.5rem

  .event-item-date
    font-weight: 700
    font-size: 1.1rem
    padding-top: 12px
    margin-bottom: 5px
    background: url(../images/fig_calendar.png) top center no-repeat
    background-size: cover
    width: 65.13px
    height: 70px
    +app-mobile
      font-size: 0.9rem
      width: 55.83px
      height: 60px

  .event-item-times
    font-size: 0.85rem
    +app-mobile
      font-size: 0.75rem
</style>
