<template>
  <div>
    <div v-if="editable">
      <a
        class="is-primary-link exclude-text"
        @click.prevent="onClick"
      >
        <i class="far fa-minus-circle" />
        <span v-text="currentText" />
      </a>
    </div>
    <div
      v-else
      class="has-text-grey exclude-text"
    >
      <i class="far fa-minus-circle" />
      <span v-text="currentText" />
    </div>
    <template v-if="editable">
      <input
        v-for="user in selectedUsers"
        :key="user.id"
        :name="name"
        :value="user.id"
        type="hidden"
      >
    </template>
    <Portal to="modal">
      <BModal
        :active.sync="isModalActive"
        has-modal-card
      >
        <ExcludeTargetSelectModal
          v-bind="modalProps"
          @submit="onExcludeTargetSelected"
        />
      </BModal>
    </Portal>
  </div>
</template>

<script>
import ExcludeTargetSelectModal from 'components/ExcludeTargetSelectModal'

export default {
  components: { ExcludeTargetSelectModal },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    organization: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedUsers: this.value,
      isModalActive: false
    }
  },
  computed: {
    modalProps () {
      return {
        organization: this.organization,
        users: this.selectedUsers
      }
    },
    currentText () {
      if (this.selectedUsers.length === 0) {
        return '発信先から除外する人を選ぶ'
      }
      return `${this.selectedUsers.length}人を宛先から除外`
    }
  },
  methods: {
    onClick () {
      this.isModalActive = true
    },
    onExcludeTargetSelected (users) {
      this.selectedUsers = users
    }
  }
}
</script>

<style scoped lang="sass">
.exclude-text
  font-size: 0.75rem
</style>
