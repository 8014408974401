<template>
  <div>
    <div
      v-for="(item, index) in items"
      :id="nestedItemId(index)"
      :key="item._key"
    >
      <BCollapse
        class="panel is-mb-3"
        :open="!!item.firstName.value"
      >
        <div
          slot="trigger"
          class="panel-heading"
        >
          <span>子どもの情報<span v-text="index + 1" /></span>
        </div>
        <div
          class="panel-block"
          style="display: block"
        >
          <BaseSelectInput
            v-if="item.useAccompany.value"
            v-bind="nestedInputProps(item.accompany, index)"
          />
          <div
            v-if="item.useFirstName.value || item.useFirstNameKana.value"
            class="columns is-mobile"
          >
            <div
              v-if="item.useFirstName.value"
              class="column"
            >
              <BaseTextInput v-bind="nestedInputProps(item.firstName, index)" />
            </div>
            <div
              v-if="item.useFirstNameKana.value"
              class="column"
            >
              <BaseTextInput v-bind="nestedInputProps(item.firstNameKana, index)" />
            </div>
          </div>
          <BaseSelectInput
            v-if="item.useSex.value"
            v-bind="nestedInputProps(item.sex, index)"
          />
          <BirthdayPicker
            v-if="item.useDateOfBirth.value"
            v-bind="nestedInputProps(item.dateOfBirth, index)"
          />
          <BaseSelectInput
            v-if="item.useDischargeStep.value"
            v-bind="nestedInputProps(item.dischargeStep, index)"
          />
          <BaseSelectInput
            v-if="item.useHaveAllergy.value"
            v-bind="nestedInputProps(item.haveAllergy, index)"
          />
          <BaseCheckboxInput
            v-if="item.useEggAllergy.value"
            v-bind="nestedInputProps(item.eggAllergy, index)"
          />
          <BaseCheckboxInput
            v-if="item.useMilkAllergy.value"
            v-bind="nestedInputProps(item.milkAllergy, index)"
          />
          <BaseCheckboxInput
            v-if="item.useWheatAllergy.value"
            v-bind="nestedInputProps(item.wheatAllergy, index)"
          />
          <BaseTextInput
            v-if="item.useAllergyDescription.value"
            v-bind="nestedInputProps(item.allergyDescription, index)"
          />
          <BaseSelectInput
            v-if="item.useDayCareExperience.value"
            v-bind="nestedInputProps(item.dayCareExperience, index)"
          />
        </div>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import HasManyFormNestable from 'mixins/HasManyFormNestable'

export default {
  mixins: [HasManyFormNestable],
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="sass">
</style>
