import Vue from 'vue'
import VueDisableInterpolation from 'vue-disable-interpolation'
import Buefy from 'buefy'
import PortalVue from 'portal-vue'
import Croppa from 'vue-croppa'
import { parse, format } from 'date-fns'
import ActionBox from 'components/ActionBox'
import ActionBoxBody from 'components/ActionBoxBody'
import ActionBoxes from 'components/ActionBoxes'
import TheActionBar from 'components/TheActionBar'
import HeaderH2 from 'components/HeaderH2'
import HeaderH3 from 'components/HeaderH3'
import HeaderH4 from 'components/HeaderH4'
import ErrorNotification from 'components/ErrorNotification'
import SimpleErrorNotification from 'components/SimpleErrorNotification'
import BaseTextInput from 'components/BaseTextInput'
import BaseSelectInput from 'components/BaseSelectInput'
import BaseCheckboxInput from 'components/BaseCheckboxInput'
import BaseCheckboxesInput from 'components/BaseCheckboxesInput'
import BaseCounterButton from 'components/BaseCounterButton'
import BaseDateInput from 'components/BaseDateInput'
import BaseTimeInput from 'components/BaseTimeInput'
import BaseRadioInput from 'components/BaseRadioInput'
import BaseSubmitButton from 'components/BaseSubmitButton'
import BaseLabel from 'components/BaseLabel'
import DateTimePicker from 'components/DateTimePicker'
import DatePicker from 'components/DatePicker'
import Calendar from 'components/Calendar'
import BirthdayPicker from 'components/BirthdayPicker'
import NestedRequestSchedulesForm from 'components/NestedRequestSchedulesForm'
import NestedEventSchedulesForm from 'components/NestedEventSchedulesForm'
import NestedTicketChildProfilesForm from 'components/NestedTicketChildProfilesForm'
import NestedCorporateProfileForm from 'components/NestedCorporateProfileForm'
import NestedEventMailingListFilterForm from 'components/NestedEventMailingListFilterForm'
import NestedCouponForm from 'components/NestedCouponForm'
import TheChat from 'components/TheChat'
import ProfileSectionHierarchy from 'components/ProfileSectionHierarchy'
import CustomValueCheckboxInput from 'components/CustomValueCheckboxInput'
import TheCorporateUser from 'components/TheCorporateUser'
import TheApplication from 'components/TheApplication'
import TheCorpApplication from 'components/TheCorpApplication'
import InlineFrameContents from 'components/InlineFrameContents'
import TheProfileForm from 'components/TheProfileForm'
import TheProfile from 'components/TheProfile'
import TheEventForm from 'components/TheEventForm'
import TheAgeRangeForm from 'components/TheAgeRangeForm'
import FaceIcon from 'components/FaceIcon'
import FaceIconRelations from 'components/FaceIconRelations'
import EditableFaceIcon from 'components/EditableFaceIcon'
import EventTab from 'components/EventTab'
import EventList from 'components/EventList'
import EventListItem from 'components/EventListItem'
import AssignmentMailBodyInput from 'components/AssignmentMailBodyInput'
import TheCommunityForm from 'components/TheCommunityForm'
import Notice from 'components/Notice'
import FriendGauge from 'components/FriendGauge'
import BasePanelBlock from 'components/BasePanelBlock'
import TheCommunity from 'components/TheCommunity'
import BaseTab from 'components/BaseTab'
import TheBankAccountForm from 'components/TheBankAccountForm'
import SharingCandidateSelectInput from 'components/SharingCandidateSelectInput'
import HistoryActionBox from 'components/HistoryActionBox'
import BaseOptionButton from 'components/BaseOptionButton'
import BaseHelpButton from 'components/BaseHelpButton'
import BaseSearchButton from 'components/BaseSearchButton'
import ImageModal from 'components/ImageModal'
import TheNotification from 'components/TheNotification'
import TheBillingForm from 'components/TheBillingForm'
import ChildrenSelectInput from 'components/ChildrenSelectInput'
import SharingCandidateUser from 'components/SharingCandidateUser'
import TheEvent from 'components/TheEvent'
import TheEvents from 'components/TheEvents'
import LabelSection from 'components/LabelSection'
import TheHistory from 'components/TheHistory'
import TheTicketForm from 'components/TheTicketForm'
import TheEditStaffCandidateForm from 'components/TheEditStaffCandidateForm'
import ShareFriendCard from 'components/ShareFriendCard'
import ShareFriendCardModal from 'components/ShareFriendCardModal'
import ShareFriendCardModalLink from 'components/ShareFriendCardModalLink'
import ShareFriendAnimation from 'components/ShareFriendAnimation'
import TheFriendships from 'components/TheFriendships'
import PhotoUploaderBox from 'components/PhotoUploaderBox'
import PhotoUploaderOuter from 'components/PhotoUploaderOuter'
import TheSiteLaw from 'components/TheSiteLaw'
import TheInvitingForm from 'components/TheInvitingForm'
import AddonTextInput from 'components/AddonTextInput'
import PhotoUploader from 'components/PhotoUploader'
import TheChats from 'components/TheChats'
import SharingCard from 'components/SharingCard'
import RecommendNeighbor from 'components/RecommendNeighbor'
import AnnouncementList from 'components/AnnouncementList'
import UnregisteredMessage from 'components/UnregisteredMessage'
import CandidateLink from 'components/CandidateLink'
import EntryDetail from 'components/EntryDetail'
import SearchResultZero from 'components/SearchResultZero'
import CommunityParticipationUser from 'components/CommunityParticipationUser'
import InvitationSignIn from 'components/InvitationSignIn'
import SnsShareButton from 'components/SnsShareButton'
import HorizontalFaceIcons from 'components/HorizontalFaceIcons'
import TheShareableForm from 'components/TheShareableForm'
import LabeledActionBoxes from 'components/LabeledActionBoxes'
import MamaSupporterCard from 'components/MamaSupporterCard'
import SampleMark from 'components/SampleMark'
import TabNoIcon from 'components/TabNoIcon'
import TheUsersMap2 from 'components/TheUsersMap2'
import MessageItem from 'components/MessageItem'
import SharingFriendButton from 'components/SharingFriendButton'
import ShareableCandidacyButton from 'components/ShareableCandidacyButton'
import Balloon from 'components/Balloon'
import ButtonSquareEdge from 'components/ButtonSquareEdge'
import StatGeoFields from 'components/StatGeoFields'
import MamaSupporterAbilities from 'components/MamaSupporterAbilities'
import DeviseNotSignedIn from 'components/DeviseNotSignedIn'
import TheChildProfileForm from 'components/TheChildProfileForm'
import TheEntryForm from 'components/TheEntryForm'
import HorizontalScrollableTable from 'components/HorizontalScrollableTable'
import MapBasedSharingCandidateSelector from 'components/MapBasedSharingCandidateSelector'
import CommunityCard from 'components/CommunityCard'
import TheOrganizationForm from 'components/TheOrganizationForm'
import OrganizationMap from 'components/OrganizationMap'
import TheOrganization from 'components/TheOrganization'
import EmbedActionBox from 'components/EmbedActionBox'
import EmbedActionBoxes from 'components/EmbedActionBoxes'
import TheOnlineEventForm from 'components/TheOnlineEventForm'
import TheOnlineEvent from 'components/TheOnlineEvent'
import NewEventButton from 'components/NewEventButton'
import CorporateUserListItem from 'components/CorporateUserListItem'
import PartnerCorporateUserListItem from 'components/PartnerCorporateUserListItem'
import OrganizationCard from 'components/OrganizationCard'
import AttendOnlineEventButton from 'components/AttendOnlineEventButton'
import ExcludeTargetSelectInput from 'components/ExcludeTargetSelectInput'
import JoinOrganizationButton from 'components/JoinOrganizationButton'
import CommunityPrivacy from 'components/CommunityPrivacy'
import CommunityJoinPolicy from 'components/CommunityJoinPolicy'
import CommunityOrganizationName from 'components/CommunityOrganizationName'
import TopicForm from 'components/TopicForm'
import TopicCard from 'components/TopicCard'
import TheTopic from 'components/TheTopic'
import TopicComment from 'components/TopicComment'
import TopicSearch from 'components/TopicSearch'
import CorporateStaffCard from 'components/CorporateStaffCard'
import MessageModalLink from 'components/MessageModalLink'
import ActivityCard from 'components/ActivityCard'
import RangeSliderInput from 'components/RangeSliderInput'
import NewsReceiverSelector from 'components/NewsReceiverSelector'
import GuestEventEntry from 'components/GuestEventEntry'
import EventTypeToggle from 'components/EventTypeToggle'
import DynamicPhotoUploaderOuter from 'components/DynamicPhotoUploaderOuter'
import SignUpAddressInput from 'components/SignUpAddressInput'
import SignUpStatusNotice from 'components/SignUpStatusNotice'
import OrganizationJoinResponseLink from 'components/OrganizationJoinResponseLink'
import ReadMore from 'components/ReadMore'
import OrganizationJoinPolicyInput from 'components/OrganizationJoinPolicyInput'
import NestedOrganizationAutoJoinAddressesForm from 'components/NestedOrganizationAutoJoinAddressesForm'
import OrganizationList from 'components/OrganizationList'
import OrganizationListItem from 'components/OrganizationListItem'
import BlockUserOneToOneChat from 'components/BlockUserOneToOneChat'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import store from 'store'

export default () => {
  if (window.APP.rails.user) {
    const { user } = window.APP.rails
    store.commit('session/setCurrentUser', user)
  }

  Vue.use(Buefy, {
    defaultDateFormatter: date => format(date, 'YYYY/MM/DD'),
    defaultDateParser: date => parse(date),
    defaultMonthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    defaultDayNames: ['日', '月', '火', '水', '木', '金', '土'],
    defaultIconPack: 'fal'
  })
  Vue.use(VueDisableInterpolation)
  Vue.use(PortalVue)
  Vue.use(Croppa)
  Vue.use(VueAwesomeSwiper, { slidesPerView: 1.3, spaceBetween: 30 })

  Vue.component('ActionBox', ActionBox)
  Vue.component('ActionBoxBody', ActionBoxBody)
  Vue.component('ActionBoxes', ActionBoxes)
  Vue.component('TheActionBar', TheActionBar)
  Vue.component('HeaderH2', HeaderH2)
  Vue.component('HeaderH3', HeaderH3)
  Vue.component('HeaderH4', HeaderH4)
  Vue.component('ErrorNotification', ErrorNotification)
  Vue.component('SimpleErrorNotification', SimpleErrorNotification)
  Vue.component('BaseTextInput', BaseTextInput)
  Vue.component('BaseSelectInput', BaseSelectInput)
  Vue.component('BaseCheckboxInput', BaseCheckboxInput)
  Vue.component('BaseCheckboxesInput', BaseCheckboxesInput)
  Vue.component('BaseCounterButton', BaseCounterButton)
  Vue.component('BaseDateInput', BaseDateInput)
  Vue.component('BaseTimeInput', BaseTimeInput)
  Vue.component('BaseRadioInput', BaseRadioInput)
  Vue.component('BaseSubmitButton', BaseSubmitButton)
  Vue.component('BaseLabel', BaseLabel)
  Vue.component('DateTimePicker', DateTimePicker)
  Vue.component('DatePicker', DatePicker)
  Vue.component('Calendar', Calendar)
  Vue.component('BirthdayPicker', BirthdayPicker)
  Vue.component('NestedRequestSchedulesForm', NestedRequestSchedulesForm)
  Vue.component('NestedEventSchedulesForm', NestedEventSchedulesForm)
  // prettier-ignore
  Vue.component('NestedTicketChildProfilesForm', NestedTicketChildProfilesForm)
  Vue.component('NestedCorporateProfileForm', NestedCorporateProfileForm)
  Vue.component('NestedEventMailingListFilterForm', NestedEventMailingListFilterForm)
  Vue.component('NestedCouponForm', NestedCouponForm)
  Vue.component('TheChat', TheChat)
  Vue.component('ProfileSectionHierarchy', ProfileSectionHierarchy)
  Vue.component('CustomValueCheckboxInput', CustomValueCheckboxInput)
  Vue.component('TheCorporateUser', TheCorporateUser)
  Vue.component('TheApplication', TheApplication)
  Vue.component('TheCorpApplication', TheCorpApplication)
  Vue.component('InlineFrameContents', InlineFrameContents)
  Vue.component('TheProfileForm', TheProfileForm)
  Vue.component('TheProfile', TheProfile)
  Vue.component('TheEventForm', TheEventForm)
  Vue.component('TheAgeRangeForm', TheAgeRangeForm)
  Vue.component('FaceIcon', FaceIcon)
  Vue.component('FaceIconRelations', FaceIconRelations)
  Vue.component('EditableFaceIcon', EditableFaceIcon)
  Vue.component('EventTab', EventTab)
  Vue.component('EventList', EventList)
  Vue.component('EventListItem', EventListItem)
  Vue.component('AssignmentMailBodyInput', AssignmentMailBodyInput)
  Vue.component('TheCommunityForm', TheCommunityForm)
  Vue.component('Notice', Notice)
  Vue.component('FriendGauge', FriendGauge)
  Vue.component('BasePanelBlock', BasePanelBlock)
  Vue.component('TheCommunity', TheCommunity)
  Vue.component('BaseTab', BaseTab)
  Vue.component('TheBankAccountForm', TheBankAccountForm)
  Vue.component('SharingCandidateSelectInput', SharingCandidateSelectInput)
  Vue.component('HistoryActionBox', HistoryActionBox)
  Vue.component('BaseOptionButton', BaseOptionButton)
  Vue.component('BaseHelpButton', BaseHelpButton)
  Vue.component('BaseSearchButton', BaseSearchButton)
  Vue.component('ImageModal', ImageModal)
  Vue.component('TheBillingForm', TheBillingForm)
  Vue.component('ChildrenSelectInput', ChildrenSelectInput)
  Vue.component('SharingCandidateUser', SharingCandidateUser)
  Vue.component('TheEvent', TheEvent)
  Vue.component('TheEvents', TheEvents)
  Vue.component('LabelSection', LabelSection)
  Vue.component('TheHistory', TheHistory)
  Vue.component('TheTicketForm', TheTicketForm)
  Vue.component('TheNotification', TheNotification)
  Vue.component('TheEditStaffCandidateForm', TheEditStaffCandidateForm)
  Vue.component('ShareFriendCard', ShareFriendCard)
  Vue.component('ShareFriendCardModal', ShareFriendCardModal)
  Vue.component('ShareFriendCardModalLink', ShareFriendCardModalLink)
  Vue.component('ShareFriendAnimation', ShareFriendAnimation)
  Vue.component('TheFriendships', TheFriendships)
  Vue.component('PhotoUploaderBox', PhotoUploaderBox)
  Vue.component('PhotoUploaderOuter', PhotoUploaderOuter)
  Vue.component('TheSiteLaw', TheSiteLaw)
  Vue.component('TheInvitingForm', TheInvitingForm)
  Vue.component('AddonTextInput', AddonTextInput)
  Vue.component('PhotoUploader', PhotoUploader)
  Vue.component('TheChats', TheChats)
  Vue.component('SharingCard', SharingCard)
  Vue.component('RecommendNeighbor', RecommendNeighbor)
  Vue.component('AnnouncementList', AnnouncementList)
  Vue.component('UnregisteredMessage', UnregisteredMessage)
  Vue.component('CandidateLink', CandidateLink)
  Vue.component('EntryDetail', EntryDetail)
  Vue.component('SearchResultZero', SearchResultZero)
  Vue.component('CommunityParticipationUser', CommunityParticipationUser)
  Vue.component('InvitationSignIn', InvitationSignIn)
  Vue.component('SnsShareButton', SnsShareButton)
  Vue.component('HorizontalFaceIcons', HorizontalFaceIcons)
  Vue.component('TheShareableForm', TheShareableForm)
  Vue.component('LabeledActionBoxes', LabeledActionBoxes)
  Vue.component('MamaSupporterCard', MamaSupporterCard)
  Vue.component('SampleMark', SampleMark)
  Vue.component('TabNoIcon', TabNoIcon)
  Vue.component('TheUsersMap2', TheUsersMap2)
  Vue.component('MessageItem', MessageItem)
  Vue.component('SharingFriendButton', SharingFriendButton)
  Vue.component('ShareableCandidacyButton', ShareableCandidacyButton)
  Vue.component('Balloon', Balloon)
  Vue.component('ButtonSquareEdge', ButtonSquareEdge)
  Vue.component('StatGeoFields', StatGeoFields)
  Vue.component('MamaSupporterAbilities', MamaSupporterAbilities)
  Vue.component('DeviseNotSignedIn', DeviseNotSignedIn)
  Vue.component('TheChildProfileForm', TheChildProfileForm)
  Vue.component('TheEntryForm', TheEntryForm)
  Vue.component('HorizontalScrollableTable', HorizontalScrollableTable)
  Vue.component('MapBasedSharingCandidateSelector', MapBasedSharingCandidateSelector)
  Vue.component('CommunityCard', CommunityCard)
  Vue.component('TheOrganizationForm', TheOrganizationForm)
  Vue.component('OrganizationMap', OrganizationMap)
  Vue.component('TheOrganization', TheOrganization)
  Vue.component('EmbedActionBox', EmbedActionBox)
  Vue.component('EmbedActionBoxes', EmbedActionBoxes)
  Vue.component('TheOnlineEventForm', TheOnlineEventForm)
  Vue.component('TheOnlineEvent', TheOnlineEvent)
  Vue.component('NewEventButton', NewEventButton)
  Vue.component('CorporateUserListItem', CorporateUserListItem)
  Vue.component('PartnerCorporateUserListItem', PartnerCorporateUserListItem)
  Vue.component('OrganizationCard', OrganizationCard)
  Vue.component('AttendOnlineEventButton', AttendOnlineEventButton)
  Vue.component('ExcludeTargetSelectInput', ExcludeTargetSelectInput)
  Vue.component('JoinOrganizationButton', JoinOrganizationButton)
  Vue.component('CommunityPrivacy', CommunityPrivacy)
  Vue.component('CommunityJoinPolicy', CommunityJoinPolicy)
  Vue.component('CommunityOrganizationName', CommunityOrganizationName)
  Vue.component('TopicForm', TopicForm)
  Vue.component('TopicCard', TopicCard)
  Vue.component('TheTopic', TheTopic)
  Vue.component('TopicComment', TopicComment)
  Vue.component('TopicSearch', TopicSearch)
  Vue.component('CorporateStaffCard', CorporateStaffCard)
  Vue.component('MessageModalLink', MessageModalLink)
  Vue.component('ActivityCard', ActivityCard)
  Vue.component('RangeSliderInput', RangeSliderInput)
  Vue.component('NewsReceiverSelector', NewsReceiverSelector)
  Vue.component('GuestEventEntry', GuestEventEntry)
  Vue.component('EventTypeToggle', EventTypeToggle)
  Vue.component('DynamicPhotoUploaderOuter', DynamicPhotoUploaderOuter)
  Vue.component('SignUpAddressInput', SignUpAddressInput)
  Vue.component('SignUpStatusNotice', SignUpStatusNotice)
  Vue.component('OrganizationJoinResponseLink', OrganizationJoinResponseLink)
  Vue.component('ReadMore', ReadMore)
  Vue.component('OrganizationJoinPolicyInput', OrganizationJoinPolicyInput)
  Vue.component('NestedOrganizationAutoJoinAddressesForm', NestedOrganizationAutoJoinAddressesForm)
  Vue.component('OrganizationList', OrganizationList)
  Vue.component('OrganizationListItem', OrganizationListItem)
  Vue.component('BlockUserOneToOneChat', BlockUserOneToOneChat)

  document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('#vue-app')
    /* eslint-disable no-new */
    new Vue({ el, store })
  })
}
