<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

+app-mobile
  .app-container
    margin: 0
    h2
      text-align: center !important

.panel-block
  align-items: center
  border: none
  border-bottom: 1px solid #eee

  .action-box
    width: auto

  &:first-child
    border-top: 1px solid #eee

  &.chat
    padding: 0

  a
    display: flex
    align-items: center
    padding: 0.5em 0.75em
    width: 100%
    &:hover
      background-color: whitesmoke

  .chat-face-icon
    width: 60px

    .face-icon
      position: relative

    &.unread
      .face-icon
        &::before
          display: inline-block
          position: absolute
          top: 5px
          right: 0
          content: ''
          width: 8px
          height: 8px
          background: #ec6b00
          border: 2px solid #fff
          border-radius: 10px
          box-sizing: content-box

  .chat-content
    display: inline-flex
    flex-direction: column
    width: calc(100% - 60px - 1rem)

  .chat-subtitle,
  .chat-title,
  .chat-content-txt,
    line-height: 1.2

  .chat-content-txt
    font-size: 12px
    color: #666

  .chat-number
    margin: 0 0 0 10px
    padding: 1px 9px
    background: #ec6b00
    border-radius: 20px
    font-size: 11px
    font-weight: bold
    color: #fff

  .chat-subtitle
    font-size: 14px
    color: #333
    display: flex
    justify-content: space-between

  .chat-title
    font-size: 16px
    color: #333

  .chat-title-container
    max-width: calc(100% - 45px)

  .chat-content-header
    display: flex
    justify-content: space-between

  .chat-time
    font-size: 10px
    text-align: right
    +app-mobile
      font-size: 12px
</style>
