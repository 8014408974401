<template>
  <div class="calendar-box">
    <div class="header">
      <div
        class="origtitle"
        v-text="title"
      />
      <i
        class="fas fa-angle-left prev"
        @click="$_prevMonth"
      />
      <i
        class="fas fa-angle-right next"
        @click="$_nextMonth"
      />
    </div>
    <table class="table">
      <thead class="head">
        <tr class="week">
          <th
            v-for="(week, key) in weekName"
            :key="key"
            class="day"
            v-text="week"
          />
        </tr>
      </thead>
      <tbody class="body">
        <tr
          v-for="(week, key) in month"
          :key="key"
          class="week"
        >
          <td
            v-for="(day, _key) in week"
            :key="_key"
            :class="$_dayClassObject(day)"
            class="day"
            @click="$_emitSelectedDate(day)"
            v-text="day.name"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  format,
  eachDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getDay,
  addMonths,
  subMonths,
  isWithinRange,
  isEqual
} from 'date-fns'
import ja from 'date-fns/locale/ja'

export default {
  props: {
    unresolvedDates: {
      type: Array,
      default () {
        return []
      }
    },
    isShowSelectedDate: {
      type: Boolean,
      default: false
    },
    value: {
      type: Date,
      default: null
    }
  },
  data () {
    return {
      targetDate: new Date(),
      selectedDate: this.value
    }
  },
  computed: {
    title () {
      return format(this.targetDate, 'YYYY年M月')
    },
    firstDayOfTheWeek () {
      return 0 // Sunday
    },
    lastDayOfTheWeek () {
      return (this.firstDayOfTheWeek + 6) % 7
    },
    weekName () {
      const weekStartsOn = { weekStartsOn: this.firstDayOfTheWeek }
      return eachDay(
        startOfWeek(this.targetDate, weekStartsOn),
        endOfWeek(this.targetDate, weekStartsOn)
      ).map(value => format(value, 'dd', { locale: ja }))
    },
    month () {
      const start = startOfMonth(this.targetDate)
      const end = endOfMonth(this.targetDate)
      const calendarDays = [
        ...Array(getDay(start) - this.firstDayOfTheWeek).fill({
          name: '',
          date: ''
        }),
        ...eachDay(start, end).map(value => ({
          name: format(value, 'D'),
          date: value
        })),
        ...Array(this.lastDayOfTheWeek - getDay(end)).fill({
          name: '',
          date: ''
        })
      ]
      const month = []
      for (let day = 0, week = 7; day < calendarDays.length; day += week) {
        month.push(calendarDays.slice(day, day + week))
      }
      return month
    }
  },
  mounted () {
    if (this.value !== null) this.targetDate = this.value
  },
  methods: {
    $_nextMonth () {
      this.targetDate = addMonths(this.targetDate, 1)
    },
    $_prevMonth () {
      this.targetDate = subMonths(this.targetDate, 1)
    },
    $_isUnresolved (date) {
      const firstDate = this.unresolvedDates[0]
      const lastDate = this.unresolvedDates[this.unresolvedDates.length - 1]
      if (isWithinRange(date, firstDate, lastDate)) return false
      return this.unresolvedDates.some(unresolvedDate => isEqual(format(date, 'YYYYMMDD'), format(unresolvedDate, 'YYYYMMDD')))
    },
    $_isSelected (date) {
      if (!this.isShowSelectedDate) return false
      return isEqual(
        format(date, 'YYYYMMDD'),
        format(this.selectedDate, 'YYYYMMDD')
      )
    },
    $_dayClassObject (day) {
      return {
        'is-unresolved': this.$_isUnresolved(day.date),
        'is-selected': this.$_isSelected(day.date)
      }
    },
    $_emitSelectedDate (day) {
      this.selectedDate = day.date
      if (day.date !== '') this.$emit('input', day.date)
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

$alert-color: #e24a3f
$main-color: #ccc
$primary-color: #ec6b0e

.calendar-box
  display: inline-block
  color: #333
  //box-shadow: 0 2px 4px $main-color

  > .header
    border: 1px solid $main-color
    border-bottom: none
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 3px 3px 0 0
    background-color: white

    > .origtitle
      text-align: center
      padding: 10px 0
      line-height: 1
      margin: 0 1rem
      order: 1

    > .prev,
    > .next
      padding: 10px 1rem

      &:hover
        cursor: pointer
        background-color: #efefef

    > .prev
      order: 0

    > .next
      order: 2

  > .table
    border-collapse: collapse

    +mobile
      width: 100%

    .week
      > .day
        line-height: 1
        border: 1px solid $main-color
        padding: 8px 10px
        text-align: center

        +mobile
          padding: 12px 10px

    > .head
      > .week
        font-size: 12px
        font-weight: bold

    > .body
      > .week
        > .day
          &:hover:not(:empty)
            cursor: pointer
            background-color: #efefef

          &.is-unresolved
            position: relative
            &::after
              position: absolute
              content: ""
              display: block
              width: 6px
              height: 6px
              border-radius: 50%
              background-color: $alert-color
              right: 3px
              top: 3px

          &.is-selected
            color: #fff
            background-color: $primary-color

            &:hover
              background-color: $primary-color
</style>
