<template>
  <div>
    <div
      v-for="(item, index) in items"
      :id="nestedItemId(index)"
      :key="item._key"
    >
      <div class="card is-mb-3">
        <div class="card-content">
          <input
            v-if="item.id && item.id.value"
            :name="nestedFieldName('id', index)"
            :value="item.id.value"
            type="hidden"
          >
          <BaseSelectInput v-bind="nestedInputProps(item.couponType, index)" />
          <BaseTextInput v-bind="nestedInputProps(item.code, index)" />
          <button
            type="button"
            class="button is-danger is-rounded"
            @click="remove(index)"
            v-text="removeLabel"
          />
        </div>
      </div>
    </div>

    <div
      v-for="(destroyId, index) in destroyIds"
      :key="`destroy-${destroyId}`"
    >
      <input
        :name="nestedFieldName('id', items.length + index)"
        :value="destroyId"
        type="hidden"
      >

      <input
        :name="nestedFieldName('_destroy', items.length + index)"
        type="hidden"
        value="1"
      >
    </div>

    <button
      type="button"
      class="button is-primary is-rounded"
      @click="add"
      v-text="addLabel"
    />
  </div>
</template>

<script>
import HasManyFormNestable from 'mixins/HasManyFormNestable'

export default {
  mixins: [HasManyFormNestable],
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="sass">
</style>
