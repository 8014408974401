function bind (root) {
  const toggleAll = root.querySelector('.js-check-all-toggle')
  if (!toggleAll) { return }

  toggleAll.addEventListener('change', ({ currentTarget: { checked } }) => {
    Array.from(root.querySelectorAll('.js-check-all-checkbox')).forEach(el => {
      el.checked = checked
    })
  })
}

export function initCheckAll () {
  document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll('.js-check-all-root')).forEach(el => {
      bind(el)
    })
  })
}
