<template>
  <div>
    <button
      :class="buttonClass"
      :data-disable-with="disableWith"
      :data-confirm="confirm"
      v-text="label"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    disableWith: {
      type: String,
      default: '送信中です...'
    },
    isLarge: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: String,
      default: null
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      default: false
    },
    isHalfwidth: {
      type: Boolean,
      default: false
    },
    isSquare: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass () {
      return {
        button: true,
        'is-primary': true,
        'is-rounded': true,
        'is-outlined': this.isOutlined,
        'is-large': this.isLarge,
        'is-fullwidth': this.isFullwidth,
        'is-halfwidth': this.isHalfwidth,
        'is-square': this.isSquare
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
.button.is-square
  max-width: 400px
  width: 100%
  border-radius: 0
  font-size: 18px
  font-weight: bold
  line-height: 1
  padding: 16px
  &[disabled]
    background-color: #9b9b9b
</style>
