<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :addons="false"
  >
    <div class="counter-button">
      <button
        :class="{disabled: disableMinus}"
        class="button minus"
        type="button"
        @click="decrement"
      >
        -
      </button>
      <div
        class="value"
        v-text="count"
      />
      <button
        :class="{disabled: disablePlus}"
        class="button plus"
        type="button"
        @click="increment"
      >
        +
      </button>
    </div>
    <input
      :id="id"
      :value="count"
      :name="name"
      type="hidden"
    >
  </BField>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    maxValue: {
      type: [String, Number],
      default: 999999999
    }
  },
  data () {
    return {
      count: this.value || 0,
      disableMinus: true,
      disablePlus: false
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    }
  },
  methods: {
    increment () {
      this.$_changeCount(1)
    },
    decrement () {
      this.$_changeCount(-1)
    },
    $_changeCount (val) {
      this.count += val
      if (this.count <= 0) {
        this.disableMinus = true
        this.count = 0
      } else {
        this.disableMinus = false
      }
      if (this.count >= this.maxValue) {
        this.disablePlus = true
        this.count = parseInt(this.maxValue, 10)
      } else {
        this.disablePlus = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .counter-button
    display: inline-flex
    font-family: 'Avenir'
    > .button
      padding: 0 0 5px 1px
      appearance: none
      -webkit-appearance: none
      cursor: pointer
      background-color: white
      border-radius: 50%
      width: 40px
      height: 40px
      color: #666
      border: 1px solid #666
      font-size: 160%
      line-height: 1
      display: flex
      justify-content: center
      align-items: center
      vertical-align: middle
      outline: none
      cursor: pointer
      &.disabled
        opacity: .3
        cursor: default
        outline: none
        &:active
          color: #666
          background-color: white
      &:active
        background-color: #666
        color: white
        outline: none
      &:focus
        outline: none
      &:blur
        outline: none

  .value
    font-size: 180%
    margin: 0 10px
    width: 1em
    text-align: center
</style>
