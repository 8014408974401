<template>
  <div>
    <div class="eyecatch-image" />
    <div class="main-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="sass">
  @import '~stylesheets/resources'

  .top
    .eyecatch-image
      background-image: url('../images/top/eyecatch.jpg')

  .signup
    .eyecatch-image
      background-image: url('../images/signup/eyecatch.jpg')

  .signin
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .for-community
    .eyecatch-image
      background-image: url('../images/for_community/eyecatch.jpg')

  .forgot-password
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .resend-confirmation
    .eyecatch-image
      background-image: url('../images/signin/eyecatch.jpg')

  .eyecatch-image
    background-size: cover
    background-position: center center
    width: 100%
    height: 300px
  +app-mobile
    .eyecatch-image
      height: 200px
      margin-top: 45px
  .main-content
    padding: 22px 22px 45px
    text-align: center

  .omniauth-button
    width: 300px
    +app-mobile
      width: 100%

  .top
    text-align: left
  .top__title
    text-align: center
    font-size: 25px
    font-weight: bold
    line-height: 30px
    color: #333
    margin-top: 10px
    margin-bottom: 21px
    +app-mobile
      span
        display: block
  .top__content
    font-size: 16px
    line-height: 24px
    color: #333
    p
      margin-top: 50px
      margin-bottom: 60px
      text-align: center
</style>
