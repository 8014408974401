export default {
  props: {
    formInputPropItem: {
      type: Object
    },
    objectName: {
      type: String
    },
    associationName: {
      type: String
    }
  },
  data () {
    return {
      item: this.formInputPropItem
    }
  },
  methods: {
    nestedItemId () {
      return `${this.objectName}-${this.associationName}`
    },
    nestedInputProps (inputProps) {
      const name = this.nestedFieldName(inputProps.attribute)
      return Object.assign({}, inputProps, { name })
    },
    nestedFieldName (attribute) {
      // prettier-ignore
      return `${this.objectName}[${this.associationName}_attributes][${attribute}]`
    }
  }
}
