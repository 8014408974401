import { Toast } from 'buefy/dist/components/toast'
import escape from 'lodash.escape'

const showSuccess = (message) => {
  Toast.open({
    message: escape(message),
    type: 'is-success',
    position: 'is-top',
    duration: 3000,
    queue: false
  })
}

const showError = (message) => {
  Toast.open({
    message: escape(message),
    type: 'is-danger',
    position: 'is-top',
    duration: 5000,
    queue: false
  })
}

export { showSuccess, showError }
