<template>
  <ul class="base-tab">
    <BaseTabItem
      v-for="(item, key) in tabItems"
      :key="key"
      :is-small="isSmall"
      v-bind="item"
    />
  </ul>
</template>

<script>
import BaseTabItem from 'components/BaseTabItem.vue'

export default {
  components: { BaseTabItem },
  props: {
    tabItems: {
      type: Array,
      required: true
    },
    currentPath: {
      type: String,
      default: ''
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.base-tab
  display: flex
  justify-content: space-between
  align-items: center
</style>
