<template>
  <div class="candidate-link">
    <div
      class="is-size-7"
      v-text="title"
    />
    <div class="candidate-link__from">
      <face-icon
        class="is-mr-1"
        :src="fromIconUrl"
      >
        <div class="is-size-7">
          <span v-text="fromFirstName" /><small>さん</small>
        </div>
      </face-icon>
      <div
        class="candidate-link__from-message"
        v-text="fromMessage"
      />
    </div>
    <div class="candidate-link__to">
      <slot />
      <face-icon
        class="is-mr-1"
        :src="toIconUrl"
      >
        <div class="is-size-7">
          <span v-text="toFirstName" /><small>さん</small>
        </div>
      </face-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    fromIconUrl: {
      type: String,
      required: true
    },
    fromFirstName: {
      type: String,
      required: true
    },
    fromMessage: {
      type: String,
      required: true
    },
    toIconUrl: {
      type: String,
      required: true
    },
    toFirstName: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
.candidate-link
  .candidate-link__from
    display: flex
    align-items: center
    margin-top: 1rem
    .face-icon
      min-width: 70px
  .candidate-link__from-message
    background: #eee
    border-radius: 18px
    font-size: 18px
    padding: 15px 20px
    margin-left: 1rem
  .candidate-link__to
    display: flex
    align-items: center
    justify-content: flex-end
    margin-top: 1rem
    .face-icon
      min-width: 70px
</style>
