<template>
  <div>
    <slot
      :isVideoChat="isVideoChat"
      :onOnlineEventTypeChanged="onOnlineEventTypeChanged"
    />
  </div>
</template>

<script>
export default {
  props: {
    defaultOnlineEventType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      onlineEventType: this.defaultOnlineEventType
    }
  },
  computed: {
    isVideoChat () {
      return this.onlineEventType === 'video'
    }
  },
  methods: {
    onOnlineEventTypeChanged (value) {
      this.onlineEventType = value
    }
  }
}
</script>

<style scoped lang="sass">
</style>
