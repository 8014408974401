<template>
  <div class="notifications">
    <a
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
      :href="notification.path"
      :class="{ unopened: notification.openedAt === null }"
    >
      <FaceIcon
        :src="notification.notifier.avatarPath"
        class="avatar is-x-small is-mr-2"
      />
      <span v-text="notification.body" />
    </a>
  </div>
</template>

<script>
import FaceIcon from './FaceIcon'

export default {
  components: {
    FaceIcon
  },
  props: {
    notifications: {
      type: Array,
      required: true
    }
  },
  methods: {}
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.notification
  display: flex
  align-items: center
  border-top: 1px solid #eee
  padding: 5px 20px
  background-color: #fcfcfc
  &.unopened
    background-color: #fffae0
  >.avatar
    min-width: 30px
  >a
    display: block
    text-decoration: none !important
  &:not(:last-child)
    margin-bottom: 0px

+app-mobile
  .notification
    padding: 8px 10px
    line-height: 1.2
</style>
