import axios from 'axios'
import Rails from 'rails-ujs'
import viewportUnitsBuggyfill from 'viewport-units-buggyfill'
import { showSuccess, showError } from 'lib/app-toast'
import initVue from './init_vue'
import { initGoogleMap } from './google_map'
import { initCheckAll } from './check_all'

axios.defaults.headers.common['X-CSRF-Token'] = Rails.csrfToken()

window.MOBILE_APP = {}

export default function () {
  Rails.start()
  initGoogleMap()
  initVue()
  initCheckAll()
  // Safariで100vhがアドレスバーの高さを含んでしまう問題の対策
  // see also https://hacknote.jp/archives/30772/
  viewportUnitsBuggyfill.init()
  window.addEventListener('resize', viewportUnitsBuggyfill.refresh, true)

  document.addEventListener('DOMContentLoaded', () => {
    const { flash } = window.APP.rails
    if (!flash) return
    Object.keys(flash).forEach((name) => {
      const message = flash[name]
      if (typeof message !== 'string') return
      if (name === 'notice') {
        showSuccess(message)
      } else {
        showError(message)
      }
    })

    // TODO: 電話帳連動のためのコード。後日整理したいところ
    const loadContactsLink = document.querySelector('.js-load-contacts')
    if (loadContactsLink) {
      loadContactsLink.onclick = function onclick (event) {
        event.preventDefault()
        if (isWebkitWebView()) {
          window.webkit.messageHandlers.getContacts.postMessage({})
        } else {
          const url = loadContactsLink.getAttribute('href')
          const xhr = new XMLHttpRequest()
          xhr.onreadystatechange = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              const result = xhr.responseText
              return window.MOBILE_APP.postContacts(result)
            }
            if (xhr.status >= 300) {
              return alert('電話帳の読み込みに失敗しました')
            }
            return false
          }
          xhr.open('GET', url, false)
          xhr.send(null)
        }
        return false
      }
    }
  })

  function isWebkitWebView () {
    return window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.getContacts
  }

  window.MOBILE_APP.postContacts = function (data) {
    const inputContacts = document.querySelector('.js-post-contacts-input')
    inputContacts.value = data
    document.postContactsForm.submit()
  }
}
