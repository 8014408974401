<template>
  <div class="corporate-staff-card">
    <div class="box is-mb-3">
      <div class="media">
        <div class="media-left">
          <FaceIcon :src="avatarPath" />
        </div>
        <div class="media-content">
          <div
            class="department"
            v-text="department"
          />
          <div
            class="full-name"
            v-text="fullName"
          />
          <div
            class="introduction-title"
            v-text="introductionTitle"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            class="introduction"
            v-html="introduction"
          />
          <div class="edit-link">
            <slot name="editLink" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    department: {
      type: String,
      required: true
    },
    fullName: {
      type: String,
      required: true
    },
    introductionTitle: {
      type: String,
      required: true
    },
    introduction: {
      type: String,
      required: true
    },
    avatarPath: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.corporate-staff-card
  .box
    padding: 10px
    color: #333
    .full-name
      font-size: 18px
    .department
      font-size: 10px
      color: #666
    .introduction-title
      font-size: 15px
    .introduction
      font-size: 11px
  .media-content
    position: relative
    .edit-link
      position: absolute
      top: 0
      right: 0
</style>
