<template>
  <div>
    <slot
      :onChangePaymentMethod="onChangePaymentMethod"
      :isCreditCard="isCreditCard"
      :isCash="isCash"
    />
  </div>
</template>

<script>
export default {
  props: {
    defaultPaymentMethod: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      paymentMethod: this.defaultPaymentMethod
    }
  },
  computed: {
    isCreditCard () {
      return this.paymentMethod === 'credit_card'
    },
    isCash () {
      return this.paymentMethod === 'cash'
    }
  },
  methods: {
    onChangePaymentMethod (value) {
      this.paymentMethod = value
    }
  }
}
</script>
