<template>
  <div>
    <div
      v-if="label"
      class="label"
      v-text="label"
    />
    <MapBasedSelector
      class="is-mb-3"
      :radius="radiusProps.value"
      :latitude="latitudeProps.value"
      :longitude="longitudeProps.value"
      :location="locationProps.value || ''"
      :location-label="locationProps.label"
      :mark-supporter="true"
      :mark-community="true"
      :mark-event="true"
      :mark-organization="true"
      @change="handleLocationChange"
    >
      <label class="label">
        発信先対象年齢
      </label>
      <div class="has-text-grey is-size-7 is-mb-2">
        ※対象となる子どもの年齢範囲を指定して、必要な相手にお知らせが届くようにしましょう。
      </div>
      <BaseCheckboxInput
        v-bind="withoutGenerationFilteringInputProps"
        :on-change="handleGenerationFilteringUsageChange"
      />
      <div
        v-if="!withoutGenerationFiltering"
        class="range-slider-container"
      >
        <label class="label has-text-weight-normal">
          対象年齢指定
        </label>
        <RangeSliderInput
          v-bind="generationRangeSliderInputProps"
          @change="handleGenerationFilterChange"
        />
      </div>
      <div class="user-count-container">
        <div class="is-mr-3">
          半径
          <span v-text="radius / 1000" />
          km圏内:
          <span
            class="user-count"
            v-text="neighborUserCount"
          />
          人
        </div>
        <div>
          発信対象:
          <span
            class="user-count"
            v-text="targetUserCount"
          />
          人
        </div>
      </div>
    </MapBasedSelector>
    <input
      :name="locationProps.name"
      :value="location"
      type="hidden"
    >
    <input
      :name="latitudeProps.name"
      :value="lat"
      type="hidden"
    >
    <input
      :name="longitudeProps.name"
      :value="lng"
      type="hidden"
    >
    <input
      :name="radiusProps.name"
      :value="radius"
      type="hidden"
    >
  </div>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash.debounce'
import MapBasedSelector from 'components/MapBasedSelector'

export default {
  components: { MapBasedSelector },
  props: {
    radiusProps: {
      type: Object,
      required: true
    },
    latitudeProps: {
      type: Object,
      required: true
    },
    longitudeProps: {
      type: Object,
      required: true
    },
    locationProps: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    withoutGenerationFilteringInputProps: {
      type: Object,
      required: true
    },
    generationRangeSliderInputProps: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      radius: this.radiusProps.value,
      lat: this.latitudeProps.value,
      lng: this.longitudeProps.value,
      location: this.locationProps.value || '',
      generationFromText: this.generationRangeSliderInputProps.minValue,
      generationToText: this.generationRangeSliderInputProps.maxValue,
      neighborUserCount: null,
      targetUserCount: null,
      withoutGenerationFiltering: this.withoutGenerationFilteringInputProps.value
    }
  },
  created () {
    this.$_reloadNeighborUserCount = debounce(this.$_fetchNeighborUserCount, 300)
    this.$_reloadTargetUserCount = debounce(this.$_fetchTargetUserCount, 300)
  },
  methods: {
    handleLocationChange (ev) {
      const {
        radius,
        latitude,
        longitude,
        location
      } = ev
      this.radius = radius
      this.lat = latitude
      this.lng = longitude
      this.location = location
      this.$_reloadNeighborUserCount()
      this.$_reloadTargetUserCount()
    },
    handleGenerationFilteringUsageChange (value) {
      this.withoutGenerationFiltering = value
      this.$_reloadTargetUserCount()
    },
    handleGenerationFilterChange (value) {
      [this.generationFromText, this.generationToText] = value
      this.$_reloadTargetUserCount()
    },
    async $_fetchNeighborUserCount () {
      this.neighborUserCount = await this.$_fetchUserCount({
        latitude: this.lat,
        longitude: this.lng,
        radius: this.radius
      })
    },
    async $_fetchTargetUserCount () {
      this.targetUserCount = await this.$_fetchUserCount({
        latitude: this.lat,
        longitude: this.lng,
        radius: this.radius,
        without_generation_filtering: this.withoutGenerationFiltering,
        generation_from_text: this.generationFromText,
        generation_to_text: this.generationToText
      })
    },
    async $_fetchUserCount (params) {
      const { data: { users: { count } } } = await axios.get('/neighbor_users.json', { params })
      return count
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.range-slider-container
  margin-bottom: 2rem

.user-count-container
  display: flex
  color: $primary
  border-bottom: 1px solid $primary
  .user-count
    font-size: 1.2rem
    font-weight: bold
</style>
