<template>
  <div class="box mama-supporter-card">
    <div class="triangle-badge" />
    <div class="left">
      <a :href="userShowPath">
        <FaceIcon :src="user.avatarPath">
          <div class="has-text-primary">
            ママサポ
          </div>
          <div
            class="name"
            v-text="user.fullName"
          />
        </FaceIcon>
      </a>
      <MamaSupporterAbilities
        class="is-mb-2"
        v-bind="user"
      />
      <div
        v-if="user.hasLicence"
        class="has-licence"
      >
        <span class="tag is-light">
          有資格
        </span>
      </div>
      <div class="is-mb-2 hourly-wage">
        <span v-text="hourlyWageText" />
      </div>
      <ShareFriendCardModalLink
        :user="user"
        :level-by-me="levelByMe"
        :level-by-friend="levelByFriend"
      >
        <FriendGauge
          :friend-level="approvedLevel"
          :is-small="true"
        />
      </ShareFriendCardModalLink>
    </div>
    <div class="right">
      <div
        class="strong-point"
        v-text="user.strongPoint"
      />
      <p
        class="introduction is-mb-3"
        v-html="user.introduction"
      />
      <div>
        <span>子ども<span v-text="user.childcareExperience" /></span>
        <span
          v-for="child in user.children"
          :key="child.id"
          class="child"
        >
          <span v-text="child.firstName" />
          <span v-text="child.age" />
          <span v-text="child.sex" />
        </span>
      </div>
      <table class="table is-fullwidth is-bordered is-my-2">
        <tr>
          <td />
          <td>6時〜9時</td>
          <td>9時〜18時</td>
          <td>18時〜22時</td>
          <td>お泊まり</td>
        </tr>
        <tr>
          <td class="has-background-light">
            平日
          </td>
          <td v-text="user.canAcceptMorningOnWeekdays" />
          <td v-text="user.canAcceptDaytimeOnWeekdays" />
          <td v-text="user.canAcceptNightOnWeekdays" />
          <td v-text="user.canStayOnWeekdays" />
        </tr>
        <tr>
          <td class="has-background-light">
            土日・休日
          </td>
          <td v-text="user.canAcceptMorningOnHolidays" />
          <td v-text="user.canAcceptDaytimeOnHolidays" />
          <td v-text="user.canAcceptNightOnHolidays" />
          <td v-text="user.canStayOnHolidays" />
        </tr>
      </table>
      <div
        v-if="condition"
        class="condition is-mb-3"
        v-text="condition"
      />
      <div class="action-buttons">
        <a
          v-if="user.showChatPath"
          :href="chatPath"
          class="button is-primary is-rounded"
        >
          <i class="fal fa-comment is-mr-1" />
          メッセージ
        </a>
        <a
          v-if="user.showInterviewPath"
          :href="user.interviewPath"
          class="button is-primary is-rounded long"
        >
          <i class="fal fa-comments is-mr-1" />
          面談を申し込む
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import FaceIcon from 'components/FaceIcon.vue'
import FriendGauge from 'components/FriendGauge.vue'
import MamaSupporterAbilities from 'components/MamaSupporterAbilities.vue'
import ShareFriendCardModalLink from 'components/ShareFriendCardModalLink.vue'

export default {
  components: {
    FaceIcon,
    FriendGauge,
    MamaSupporterAbilities,
    ShareFriendCardModalLink
  },
  props: {
    levelByMe: {
      type: Number,
      required: true
    },
    levelByFriend: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    condition () {
      const conditions = [
        this.user.canCook,
        this.user.canAcceptSickChild,
        this.user.canAcceptBasicBillingLimit
      ].filter(e => !!e)
      return conditions.length === 0 ? null : conditions.join(', ')
    },
    approvedLevel () {
      return Math.min(this.levelByMe, this.levelByFriend)
    },
    userShowPath () {
      return `/users/${this.user.id}`
    },
    chatPath () {
      return `/users/${this.user.id}/one_to_one_chat`
    },
    hourlyWageText () {
      return `${this.user.supportFee}円/時`
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
.mama-supporter-card
  display: flex
  align-items: flex-start
  padding: 1rem
  padding-left: 0.5rem
  font-size: 0.9rem
  position: relative
  overflow: hidden
  & > *
    box-sizing: border-box
  +app-mobile
    padding-right: 0.5rem
  table
    font-size: 10px
.triangle-badge
  position: absolute
  top: 0
  left: 0
  border-left: 6rem solid $primary
  border-bottom: 6rem solid transparent
  z-index: 0
  +app-mobile
    border-left: 6rem solid $primary
    border-bottom: 6rem solid transparent
.face-icon
  margin-bottom: 5px
  /deep/ > .icon
    width: 4.5rem
    height: 4.5rem
.name
  font-weight: 600
  text-align: center
  font-size: 100%
  span
    font-size: 9px
    font-weight: normal
    display: inline-block
.left
  width: 25%
  text-align: center
  z-index: 1
  +app-mobile
    min-width: 98px
.right
  width: 75%
  padding-left: 1rem
  +app-mobile
    width: auto
    flex-grow: 1
.strong-point
  font-weight: bold
.introduction
  font-size: 0.8em
.condition
  font-size: 0.8em
.button
  white-space: unset;
  height: unset;
.child
  margin-left: 8px
.has-licence
  span
    display: flex
.hourly-wage
  font-size: 10px
.action-buttons
  display: flex
  +app-mobile
    flex-direction: column
  .long
    flex: 1
  .button
    margin-right: 8px
    +app-mobile
      margin-bottom: 8px
      margin-right: 0
  .button:last-child
    margin-bottom: 0
    margin-right: 0
</style>
