<template>
  <li
    :class="{ 'is-active': isActive }"
    class="base-tab-item"
  >
    <a
      :href="url"
      class="link"
    >
      <i
        :class="[iconClass]"
        class="tabicon fal"
      />
      <span
        :class="{ 'is-small': isSmall }"
        class="tabname"
        v-text="name"
      />
    </a>
    <span class="last-border" />
  </li>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: '#'
    },
    iconType: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass () {
      return `fa-${this.iconType}`
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

.base-tab-item
  flex-grow: 1
  flex-basis: 1px
  position: relative

  .last-border
    display: none

  &:last-child
    .last-border
      content: ''
      width: 1px
      height: calc(100% - 10px)
      background-color: rgb(238, 238, 238)
      display: block
      position: absolute
      right: 0
      top: 10px

  +app-mobile
    &:last-child
      .last-border
        content: none

  &.is-active::after
    content: ''
    display: block
    width: calc(100% - 20px)
    background-color: rgb(216, 216, 216)
    border-radius: 10px
    height: 5px
    position: absolute
    left: 10px
    bottom: -10px

  .link
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    position: relative
    padding: 10px 0 3px

    &::after
      transition: 0.2s
      transform: scale(0)
      opacity: 0
      content: ''
      position: absolute
      left: 5px
      top: 5px
      width: calc(100% - 10px)
      height: calc(90% + 3px)
      border-radius: 4px
      background-color: rgba(rgb(238, 238, 238), 0.5)
      display: block

    &::before
      content: ''
      width: 1px
      height: calc(100% - 10px)
      background-color: rgb(238, 238, 238)
      display: block
      position: absolute
      left: 0
      top: 10px

    &:hover::after
      transform: scale(1)
      opacity: 1

    &:active::after
      background-color: darken(rgb(238, 238, 238), 10%)
      transform: scale(1)
      opacity: 1

    .tabicon, .tabname
      display: block
      line-height: 1
      position: relative
      z-index: 2

    .tabicon
      font-size: 18px
      margin-bottom: 5px

    .tabname
      font-size: 0.8rem
      font-weight: 600
      text-align: center
      padding-left: 3px
      padding-right: 3px
      &.is-small
        +app-mobile
          font-size: 10px

  +app-mobile
    &:not(:first-child)
      .link
        &::before
          content: ''
          width: 1px
          height: calc(100% - 10px)
          background-color: rgb(238, 238, 238)
          display: block
          position: absolute
          left: 0
          top: 10px
</style>
