<template>
  <div
    v-if="hasError"
    class="simple-error-notification"
  >
    <p v-if="visibleMessage">
      <strong v-text="displayMessage" />
    </p>
    <template v-if="visibleFullMessages">
      <ul
        v-for="fullMessage in fullMessages"
        :key="fullMessage"
      >
        <li v-text="fullMessage" />
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    fullMessages: {
      type: Array,
      default: () => []
    },
    hideFullMessages: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    displayMessage () {
      if (this.message.length > 0) {
        return this.message
      }
      return this.title
    },
    hasError () {
      return this.fullMessages.length > 0
    },
    visibleMessage () {
      return this.displayMessage.length > 0
    },
    visibleFullMessages () {
      return !this.hideFullMessages
    }
  }
}
</script>

<style scoped lang="sass">
  .simple-error-notification
    padding: 20px 24px
    background: #fff5f8
    border-left: 3px solid
    border-color: #ff3860
    border-radius: 4px
    margin-bottom: 24px
    color: #cd0930
    > p > strong
      color: #cd0930
      font-size: 16px
      font-size: 1rem
</style>
