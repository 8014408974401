<template>
  <div class="block sharing-candidate-select-input">
    <label
      class="label"
      v-text="label"
    />
    <ul>
      <li
        v-for="(community, idx) in selectedCommunities"
        :key="community.id"
      >
        <div class="notification">
          <button
            type="button"
            class="delete"
            @click.prevent="onRemoveCommunity(idx)"
          />
          <SharingCandidateCommunity v-bind="community" />
          <input
            v-if="nameCommunity"
            :name="nameCommunity"
            :value="community.id"
            type="hidden"
            class="sharing-candidate-selected"
          >
        </div>
      </li>
      <li
        v-for="(user, idx) in selectedUsers"
        :key="user.id"
      >
        <div class="notification">
          <button
            type="button"
            class="delete"
            @click.prevent="onRemove(idx)"
          />
          <SharingCandidateUser v-bind="user" />
          <input
            :name="name"
            :value="user.id"
            type="hidden"
            class="sharing-candidate-selected"
          >
        </div>
      </li>
    </ul>
    <BField
      :message="errors"
      type="is-danger"
    />
    <div>
      <SharingFriendButton
        type="button"
        :icon-images="iconImages"
        @click="isSelectModalActive = true"
      />
      <BModal
        :active.sync="isSelectModalActive"
        has-modal-card
      >
        <SharingCandidateSelectModal
          v-bind="modalProps"
          @submit="onCandidateSelected"
        />
      </BModal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import SharingFriendButton from 'components/SharingFriendButton'
import SharingCandidateSelectModal from 'components/SharingCandidateSelectModal'
import SharingCandidateCommunity from 'components/SharingCandidateCommunity'

export default {
  components: { SharingFriendButton, SharingCandidateSelectModal, SharingCandidateCommunity },
  props: {
    name: {
      type: String,
      required: true
    },
    nameCommunity: {
      type: String,
      default: null // TODO: すべてのシェアで対応が完了したら required: true にする
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    },
    valueCommunity: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    purpose: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedUsers: this.value,
      selectedCommunities: this.valueCommunity,
      isSelectModalActive: false,
      baseLocation: 'home',
      radius: 20000,
      users: [],
      communities: []
    }
  },
  computed: {
    modalProps () {
      return {
        title: this.label,
        purpose: this.purpose
      }
    },
    iconImages () {
      if (this.users.length > 0) {
        return this.users.map(user => user.avatarPath)
      }
      if (this.communities.length > 0) {
        return this.communities.map(community => community.imagePath)
      }
      return []
    }
  },
  created () {
    this.search()
  },
  methods: {
    onCandidateSelected (selectedUsers, selectedCommunities) {
      const ids = new Set(this.selectedUsers.map(_ => _.id))
      const newSelectedUsers = selectedUsers.filter(_ => !ids.has(_.id))
      this.selectedUsers = this.selectedUsers.concat(newSelectedUsers)
      if (this.nameCommunity) { // TODO: すべてのシェアで対応が終わったらこの if は消す
        const comIds = new Set(this.selectedCommunities.map(_ => _.id))
        const newSelectedCommunities = selectedCommunities.filter(_ => !comIds.has(_.id))
        this.selectedCommunities = this.selectedCommunities.concat(newSelectedCommunities)
      }
    },
    onRemove (index) {
      this.selectedUsers.splice(index, 1)
    },
    onRemoveCommunity (index) {
      this.selectedCommunities.splice(index, 1)
    },
    async search () {
      const params = {
        'q[base_location]': this.baseLocation,
        'q[radius]': this.radius,
        'q[purpose]': this.purpose
      }
      const { data: { users, communities } } = await axios.get('/sharing_candidates.json', { params })
      this.users = users
      this.communities = communities
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'
.notification
  background-color: #fff
  border-radius: 0
  padding: 0.5rem 2.5rem 0.5rem 0.5rem
  border-top: 1px solid #f0f0f0
  border-bottom: 1px solid #f0f0f0
+app-mobile
  /deep/
    .modal
      justify-content: flex-start
      padding-top: 60px
    .modal .animation-content
      width: calc(100% - 20px)
    .modal-card
      max-height: calc(100vh - 80px)
</style>
