<template>
  <div class="search-button">
    <BaseSearchButton
      :label="'ラベル検索'"
      :showLabel="true"
      :showLabelSp="true"
    >
      <BaseSelectInput
        label="ラベル"
        :name="labelProps.name"
        :collection="labelProps.collection"
        :value="selectedLabel"
        :onChange="changeSearchLabel"
      />
      <input
        type="button"
        class="button is-primary is-rounded"
        value="探す"
        @click="searchByLabel"
      >
    </BaseSearchButton>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    labelProps: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      searchLabel: null
    }
  },
  computed: {
    selectedLabel () {
      return this.label ? this.label : ''
    }
  },
  methods: {
    changeSearchLabel (label) {
      this.searchLabel = label
    },
    searchByLabel () {
      if (this.searchLabel) {
        window.location.href = `${this.url}?label=${this.searchLabel}`
      }
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.search-button
  display: flex
  justify-content: flex-end
  width: 100%
</style>