<template>
  <BField
    :label="label"
    :message="errors"
    :type="status"
    :label-for="id"
    :addons="false"
    :custom-class="customClass"
  >
    <div class="birthday-picker">
      <div class="birthday-picker__text">
        <div class="birthday-picker__text-inner date-dropdown">
          <select v-model.number="year">
            <option
              v-for="num in selectableYears"
              :key="num"
              :value="num"
              v-text="num"
            />
          </select>
          <div class="birthday-picker__unit">
            年
          </div>
          <select v-model.number="month">
            <option
              v-for="num in 12"
              :key="num"
              :value="num"
              v-text="num"
            />
          </select>
          <div class="unit">
            月
          </div>
          <template v-if="!discardDay">
            <select v-model.number="day">
              <option
                v-for="num in daysInMonth"
                :key="num"
                :value="num"
                v-text="num"
              />
            </select>
            <div class="unit">
              日
            </div>
          </template>
        </div>
        <div
          v-if="isPreviewAge && age"
          class="preview-age tag is-rounded"
          v-text="age"
        />
      </div>
      <input
        :id="id"
        :value="inputValue"
        :name="name"
        type="hidden"
      >
    </div>
    <slot />
  </BField>
</template>

<script>
import { format, getDaysInMonth, parse } from 'date-fns'
import { ageOf } from 'lib/age'
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    isPublished: {
      type: Boolean,
      default: false
    },
    isChild: {
      type: Boolean,
      default: false
    },
    discardDay: {
      type: Boolean,
      default: false
    },
    yearOptions: {
      type: Array,
      default: null
    }
  },
  data () {
    const date = parse(this.value)
    return {
      initialYear: new Date().getFullYear(),
      year: date.getFullYear(),
      month: date.getMonth() + 1, // DateのMonth(0スタート)なので表示用に + 1
      day: this.discardDay ? 1 : date.getDate()
    }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    id () {
      return inputs.idForName(this.name)
    },
    customClass () {
      return inputs.customClass(this.$props)
    },
    inputDate () {
      // DateのMonth(0スタート)なので表示用の値から -1
      return new Date(this.year, this.month - 1, this.day)
    },
    inputValue () {
      return format(this.inputDate, 'YYYY-MM-DD')
    },
    daysInMonth () {
      return getDaysInMonth(this.inputValue)
    },
    selectableYears () {
      if (this.yearOptions) {
        return this.yearOptions
      }
      const years = this.isChild ? 19 : 100
      return Array.from(Array(years).keys()).map(
        (value, key) => this.initialYear - key
      )
    },
    isPreviewAge () {
      return this.isChild
    },
    age () {
      const { years, months } = ageOf(this.inputDate)
      if (years < 0) {
        return ''
      } else if (years < 3) {
        return `${years}歳${months}ヶ月`
      } else {
        return `${years}歳`
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'

/deep/
  @import '~stylesheets/components/published-label'

.birthday-picker__text
  display: inline-flex
  padding: 0

.birthday-picker__text-inner
  display: flex
  justify-content: center
  align-items: flex-end
  color: $black
  line-height: 1

.birthday-picker__unit
  font-size: 60%
  font-weight: normal
  color: #333

.date-dropdown
  > select
    appearance: none
    background-color: transparent
    border: none
    border-bottom: 1px solid $black
    color: $black
    cursor: pointer
    font-family: Avenir, sans-serif
    font-size: 1rem
    font-weight: 500
    line-height: 1
    margin: 0 5px
    padding: 0.375rem 0.5rem
    > option
      direction: rtl

.preview-age
  align-self: flex-end
  margin-left: 1rem
</style>
