<template>
  <div
    class="abilities"
    :class="{ horizontal }"
  >
    <span v-if="tutaeru">
      伝える
    </span>
    <span v-if="tunagu">
      繋がる
    </span>
    <span v-if="azukaru">
      預かる
    </span>
  </div>
</template>

<script>
export default {
  props: {
    tutaeru: {
      type: Boolean,
      required: true
    },
    tunagu: {
      type: Boolean,
      required: true
    },
    azukaru: {
      type: Boolean,
      required: true
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources';

.abilities
  $font-size: 10px
  $space: 2px
  $border-width: 1px
  font-size: $font-size
  display: inline-block
  line-height: 1
  > span
    display: block
    border: $border-width solid #979797
    border-radius: #{($font-size + ($space + $border-width) * 2) / 2}
    padding: $space 10px

  > span ~ span
    margin-top: $space * 1.5

  &.horizontal
    > span
      display: inline-block
</style>
