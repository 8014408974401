<template>
  <div class="outer">
    <div class="inner">
      <div class="logo">
        <a href="/home">
          <div v-if="isMyCommuDomain">
            <img src="../images/header-logo-mycommu.png" alt="マイコミュ" >
          </div>
          <div v-else>
            <img src="../images/header-logo-kosodate-share.svg" alt="子育てシェア" >
          </div>
        </a>
      </div>
      <div class="card-outer">
        <div class="card-photo">
          <img :src="photoPath">
        </div>
        <div class="card-inner">
          <div class="card-contents">
            <slot name="contents" />
          </div>
        </div>
      </div>
      <div class="signin-link">
        <h2 v-if="isMyCommuDomain" class="signin-link-h2 is-mb-3">
          マイコミュ会員以外の方
        </h2>
        <h2 v-else class="signin-link-h2 is-mb-3">
          子育てシェア会員以外の方
        </h2>
        <slot name="signup" />
      </div>
      <div class="signin-link">
        <h2 v-if="isMyCommuDomain" class="signin-link-h2 is-mb-3">
          マイコミュ会員の方
        </h2>
        <h2 v-else class="signin-link-h2 is-mb-3">
          子育てシェア会員の方
        </h2>
        <slot name="signin" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    photoPath: {
      type: String,
      required: true
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="sass" scoped>
@import '~stylesheets/resources'
=app-mobile-520
  @media screen and (max-width: 520px)
    @content

.outer
  min-height: 100vh
  width: 100%
  background-color: #f6f6f6
  display: flex
  justify-content: center
  align-items: center
.inner
  width: 500px
  padding: 26px 0
  text-align: center
  +app-mobile
    padding: 12px 0
  +app-mobile-520
    width: 100%
.logo
  margin-bottom: 26px
  +app-mobile
    margin-bottom: 12px
  a
    display: inline-block
  img
    width: 198px
    height: auto
    +app-mobile
      width: 174px
.card-outer
  border-radius: 10px
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.23)
  min-height: 370px
  background-color: #fff
  +app-mobile
    min-height: 270px
  +app-mobile-520
    border-radius: 0
    box-shadow: none
.card-photo
  height: 274px
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  background-color: #fff
  background-size: cover
  background-position: bottom center
  background-repeat: no-repeat
  background-image: none
  overflow: hidden
  position: relative
  img
    display: inline-block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
  +app-mobile
    height: 174px
  +app-mobile-520
    border-radius: 0
.card-inner
  min-height: 96px
  display: flex
  justify-content: space-between
  align-items: flex-start
  position: relative
.card-contents
  width: 100%
  align-self: center
  padding: 16px 1.6rem
  font-weight: bold
  font-size: 18px
  line-height: 1.4
  color: #333333
  text-align: left
  h1,h2,h3,h4
    font-size: 18px
    font-weight: bold
  h1 + *,h2 + *,h3 + *,h4 + *,p + *
    margin-top: 16px
  p
    font-size: 16px
    font-weight: normal
.signin-link
  margin-top: 30px
  .signin-link-h2
    font-size: 18px
    color: #4a4a4a
    font-weight: bold
  .signin-link-p /* NOTE: 使ってなさそう */
    margin-top: 24px
    margin-bottom: 12px
    font-size: 12px
    color: #4a4a4a
    +app-mobile
      margin-top: 20px
      margin-bottom: 10px
      font-size: 14px
  .guest-link
    line-height: 2.25em
    display: inline-block
    text-decoration: underline
    color: #9b9b9b
  .button
    width: 240px
    display: inline-block
    font-weight: bold
</style>
