<template>
  <div class="action-box">
    <BDropdown
      :mobile-modal="false"
      position="is-bottom-left"
    >
      <button
        slot="trigger"
        class="option-button"
        type="button"
      >
        <BIcon
          pack="far"
          icon="ellipsis-v"
        />
      </button>
      <slot>
        <div v-if="isIndexChat">
          <a href="#" class="dropdown-item" @click.prevent="onClick">チャットをブロックする</a>
        </div>
        <div v-else>
          <a href="#" class="dropdown-item" @click.prevent="redirectToChats()">メッセージリスト</a>
          <a href="#" class="dropdown-item" @click.prevent="checkPartnerProfile()" v-text="labelCheckProfile"></a>
          <a href="#" class="dropdown-item" @click.prevent="onClick">チャットをブロックする</a>
          <a v-if="isMyCommuDomain" href="#" class="dropdown-item"  @click.prevent="redirectToReportChat()">運営に報告する</a>
        </div>
      </slot>
    </BDropdown>
    <Portal to="modal">
      <BModal
        :active.sync="isModalBlockActive"
        :width="515"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <header class="modal-card-head">
            <p class="modal-card-title">このユーザーを本当にブロックしますか？</p>
          </header>
          <section class="modal-card-body">
            <p>ブロックした場合、メッセージの履歴が一覧から確認できなくなります。</p>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-rounded"
              type="button"
              @click="isModalBlockActive = false"
            >
              やめておく
            </button>
            <button
              class="button is-primary is-rounded"
              type="button"
              @click="blockUser()"
            >
              ブロックする
            </button>
          </footer>
        </div>
      </BModal>
    </Portal>

    <Portal to="modal">
      <BModal
        :active.sync="isModalInfoActive"
        :width="515"
      >
        <div
          class="modal-card"
          style="width: auto"
        >
          <header class="modal-card-head">
            <p class="modal-card-title">このユーザーをブロックしました。</p>
          </header>
          <section class="modal-card-body">
            <p>ブロックを解除するには、ユーザーのプロフィール画面から（ブロック解除）ボタンを操作してください </p>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-rounded"
              type="button"
              @click="checkPartnerProfile()"
            >
              プロフィールを確認する
            </button>
            <button
              class="button is-primary is-rounded"
              type="button"
              @click="redirectToChats()"
            >
              メッセージに戻る
            </button>
          </footer>
        </div>
      </BModal>
    </Portal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    partnerId: {
      type: String,
      required: true
    },
    partnerFullName: {
      type: String,
      default: ''
    },
    isIndexChat: {
      type: Boolean,
      default: false
    },
    isMyCommuDomain: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isModalBlockActive: false,
      isModalInfoActive: false,
    }
  },
  computed: {
    labelCheckProfile() {
      return `${this.partnerFullName}さんのプロフィールを見る`
    }
  },
  methods: {
    onClick () {
      this.isModalBlockActive = true
    },
    blockUser() {
      axios.put(`/users/${this.partnerId}/one_to_one_chat/block.json`).then((respone) => {
        this.isModalBlockActive = false
        this.isModalInfoActive = true
      })
    },
    checkPartnerProfile() {
      window.location.href = `/users/${this.partnerId}`
    },
    redirectToChats() {
      window.location.href = '/chats'
    },
    redirectToReportChat() {
      window.location.href = 'https://ws.formzu.net/fgen/S77589438/'
    },
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/resources'

.modal-card-title
  text-align: center
  font-size: 16px
.modal-card-body
  p
    font-size: 14px
    font-weight: 600
    padding: 10px 14px 6px
.modal-card-foot
  padding: 12px
  justify-content: center

.option-button
  background-color: transparent
  border: none
  cursor: pointer
  padding: 0
  .option-text
    font-size: 0.7rem
    margin-top: -5px

/deep/ a.dropdown-item
  padding-right: 1rem
</style>
