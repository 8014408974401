<template>
  <div>
    <input
      v-if="item.id && item.id.value"
      type="hidden"
      :name="nestedFieldName('id')"
      :value="item.id.value"
    >
    <BaseTextInput v-bind="nestedInputProps(item.name)" />
    <PhotoUploaderOuter>
      <PhotoUploader
        v-bind="nestedInputProps(item.coverImage)"
        display-width="100%"
        display-height="100%"
      />
      <PhotoUploader
        v-bind="nestedInputProps(item.image)"
        display-width="100%"
        display-height="100%"
      />
    </PhotoUploaderOuter>
    <BaseTextInput v-bind="nestedInputProps(item.url)" />
    <BaseTextInput v-bind="nestedInputProps(item.introductionTitle)" />
    <BaseTextInput v-bind="nestedInputProps(item.introduction)" />
    <BaseSelectInput v-bind="nestedInputProps(item.defaultAsmamaStaffId)" />
    <BaseTextInput v-bind="nestedInputProps(item.defaultAsmamaIntroductionTitle)" />
    <BaseTextInput v-bind="nestedInputProps(item.defaultAsmamaIntroduction)" />
  </div>
</template>

<script>
import HasOneFormNestable from 'mixins/HasOneFormNestable'

export default {
  mixins: [HasOneFormNestable],
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped lang="sass">
</style>
