<template>
  <div class="is-mb-3">
    <MamaSupporterCard
      v-if="type === 'supporter'"
      v-bind="data"
    />
    <EventListItem
      v-if="type === 'event'"
      v-bind="data"
    />
    <CommunityCard
      v-if="type === 'community'"
      v-bind="data"
    />
    <OrganizationCard
      v-if="type === 'organization'"
      v-bind="data"
    />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
</style>
