<template>
  <div>
    <div class="nested-form-label">
      他の住所を自動参加対象にする
    </div>
    <div class="is-size-7 has-text-grey is-mb-2">
      住まいコミュニティの所在地以外の住所を自動参加対象の住所として設定することができます。<br>
      ここで設定した住所のいずれかにヒットするユーザーを、住まいコミュニティへ自動的に参加させることができます。<br>
      この設定は、この住まいコミュニティの参加条件がオープンの場合にのみ有効です。
    </div>
    <div
      v-for="(item, index) in items"
      :id="nestedItemId(index)"
      :key="item._key"
    >
      <div class="card is-mb-3">
        <div class="card-content">
          <input
            v-if="item.id && item.id.value"
            :name="nestedFieldName('id', index)"
            :value="item.id.value"
            type="hidden"
          >
          <BaseTextInput
            v-bind="nestedInputProps(item.zipCode, index)"
            class="nested-form-half-width-input"
            :on-blur="ev => onChangeZipCode(ev, item, index)"
          />
          <BaseTextInput
            v-bind="nestedInputProps(item.prefecture, index)"
            class="nested-form-half-width-input"
          />
          <BaseTextInput v-bind="nestedInputProps(item.address1, index)" />
          <BaseTextInput v-bind="nestedInputProps(item.address2, index)" />
          <BaseTextInput v-bind="nestedInputProps(item.address3, index)" />
          <button
            type="button"
            class="button is-danger is-rounded"
            @click="remove(index)"
            v-text="removeLabel"
          />
        </div>
      </div>
    </div>

    <div
      v-for="(destroyId, index) in destroyIds"
      :key="`destroy-${destroyId}`"
    >
      <input
        :name="nestedFieldName('id', items.length + index)"
        :value="destroyId"
        type="hidden"
      >

      <input
        :name="nestedFieldName('_destroy', items.length + index)"
        type="hidden"
        value="1"
      >
    </div>
    <div>
      <button
        type="button"
        class="button is-primary is-outlined is-rounded"
        @click="add"
        v-text="addLabel"
      />
    </div>
  </div>
</template>

<script>
import { findAddressFromPostal } from 'lib/auto_input_address'
import HasManyFormNestable from 'mixins/HasManyFormNestable'

export default {
  mixins: [HasManyFormNestable],
  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    async onChangeZipCode (event, item, index) {
      const { currentTarget: { value } } = event
      const { address, error } = await findAddressFromPostal(value)
      if (address) {
        const { region, locality, street } = address
        this.setInputValue(item.prefecture, index, region)
        this.setInputValue(item.address1, index, locality + street)
      }
      return error || null
    },
    setInputValue (field, index, value) {
      const { name } = this.nestedInputProps(field, index)
      const input = document.querySelector(`input[name="${name}"]`)
      input.value = value
    }
  }
}
</script>

<style scoped lang="sass">
.nested-form-label
  font-weight: bold

.nested-form-half-width-input
  width: 50%
</style>
