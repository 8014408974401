<template>
  <div class="block">
    <label
      class="label"
      :class="{'required-label':isRequired}"
      v-text="label"
    />
    <div
      class="checkboxes-container"
      :class="{ 'is-vertical': isVertical }"
    >
      <BCheckbox
        v-for="option in options"
        :key="option.value"
        v-model="selectedValues"
        :name="name"
        :native-value="option.value"
      >
        <span v-text="option.label" />
      </BCheckbox>
    </div>
    <BField
      :message="errors"
      :type="status"
    />
  </div>
</template>

<script>
import * as inputs from './inputs'

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    collection: {
      type: Array,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { selectedValues: this.value }
  },
  computed: {
    status () {
      return inputs.status(this.errors)
    },
    options () {
      return this.collection.map(([label, value]) => ({ label, value }))
    }
  }
}
</script>

<style scoped lang="sass">
/deep/
  @import '~stylesheets/components/required-label'
/deep/
  .b-checkbox.checkbox
    align-items: flex-start

.checkboxes-container.is-vertical
  display: flex
  flex-direction: column
  /deep/
    .b-checkbox.checkbox + .checkbox
      margin-left: 0
      margin-top: 0.5rem
</style>
