<template>
  <label
    class="label"
    :class="{ 'required-label': isRequired }"
  >
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    isRequired: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="sass">
@import '~stylesheets/components/required-label'
</style>
