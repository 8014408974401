import GoogleMapsLoader from 'google-maps'

const REGION_SELECTOR = '.js-geocoding-region input, .js-geocoding-region select'
const ADDRESS1_SELECTOR = '.js-geocoding-address1 input'
const ADDRESS2_SELECTOR = '.js-geocoding-address2 input'
const LAT_SELECTOR = '.js-geocoding-lat'
const LNG_SELECTOR = '.js-geocoding-lng'

const fullAddress2Latlng = fullAddress => new Promise(resolve => GoogleMapsLoader.load((google) => {
  const geocoder = new google.maps.Geocoder()
  geocoder.geocode(
    {
      address: fullAddress,
      region: 'jp'
    },
    (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const latlng = results[0].geometry.location
        return resolve(latlng)
      }
      return resolve()
    }
  )
}))

const inputLatlngFromFullAddress = async (fullAddress) => {
  if (!fullAddress) { return Promise.resolve() }

  const latlng = await fullAddress2Latlng(fullAddress)
  return new Promise((resolve) => {
    if (latlng) {
      document.querySelector(LAT_SELECTOR).value = latlng.lat()
      document.querySelector(LNG_SELECTOR).value = latlng.lng()
    }
    return resolve()
  })
}

const fullAddress = () => {
  const region = document.querySelector(REGION_SELECTOR)
  const address1 = document.querySelector(ADDRESS1_SELECTOR)
  const address2 = document.querySelector(ADDRESS2_SELECTOR)
  if (region && address1) { return region.value + address1.value + (address2 ? address2.value : '') } else { return null }
}

export default () => inputLatlngFromFullAddress(fullAddress())
export { fullAddress2Latlng }
